import {
  AdjustmentsOutlined,
  Button,
  Chip,
  FilterOrRuleFormValue,
  getDefaultFormOrFilter,
  useTranslation,
  useFilterOrRuleArrayValidationSchema,
} from "@lumar/shared";
import {
  Box,
  ClickAwayListener,
  Divider,
  makeStyles,
  Popper,
} from "@material-ui/core";
import React, { useMemo } from "react";
import PopperJs from "popper.js";
import { useURLSearchParams } from "../../../_common/routing/useURLSearchParams";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { useCreditFilters } from "./useCreditFilters";
import { CreditFilter } from "./CreditFilter";
import * as Yup from "yup";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  filterPopper: {
    zIndex: theme.zIndex.drawer,
  },
  filterBackdrop: {
    zIndex: theme.zIndex.drawer - 1,
  },
  buttonBase: {
    backgroundColor: "white",
    color: theme.palette.grey[600],
    border: `1px solid ${theme.palette.grey[300]}`,
    filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05))",
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      backgroundColor: theme.palette.grey[50],
    },
    "&:focus": {
      backgroundColor: theme.palette.grey[100],
    },
    "&.Mui-disabled": {
      color: theme.palette.grey[400],
      boxShadow: "none",
      backgroundColor: theme.palette.grey[100],
    },
  },
  clear: {
    marginRight: 8,
    height: 36,
    color: theme.palette.primary.main,
    boxShadow: "unset",
    "&:hover": {
      boxShadow: "unset",
    },
  },
  filterButton: {
    background: "white",
  },
  chip: {
    marginLeft: theme.spacing(1.25),
    cursor: "pointer",
    "& > span": {
      padding: theme.spacing(0, 1.25),
    },
  },
}));

export function FiltersButton(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("creditUsage");
  const searchParams = useURLSearchParams();
  const URLFilters = searchParams.get("filter");
  const parsedFilters = URLFilters && JSON.parse(unescape(atob(URLFilters)));
  const history = useHistory();

  const getFilterOrRuleArrayValidationSchema =
    useFilterOrRuleArrayValidationSchema();

  const [localFilterAnchor, setLocalFilterAnchor] =
    React.useState<HTMLButtonElement | null>(null);
  const localFilterVisible = Boolean(localFilterAnchor);
  const localFilterPopperId = localFilterVisible
    ? "credit-usage-filter-popper"
    : undefined;
  const localFilterPopperRef = React.useRef<PopperJs | null>(null);

  const getFilterCount = (filters: { _and: unknown[] }[]): number =>
    filters.reduce((sum, filter) => filter._and.length + sum, 0);

  const filterCount = URLFilters ? getFilterCount(parsedFilters.filters) : 0;

  const creditFilterMetricsWithPredicates = useCreditFilters();
  const initialValues: {
    filters: FilterOrRuleFormValue[];
  } = {
    filters: URLFilters
      ? parsedFilters.filters
      : [getDefaultFormOrFilter(creditFilterMetricsWithPredicates)],
  };

  const validationSchema = useMemo(
    () =>
      creditFilterMetricsWithPredicates
        ? Yup.object({
            filters: getFilterOrRuleArrayValidationSchema(
              creditFilterMetricsWithPredicates,
            ),
          })
        : undefined,
    [creditFilterMetricsWithPredicates, getFilterOrRuleArrayValidationSchema],
  );

  const resetFilters = (): void => {
    searchParams.delete("filter");
    searchParams.delete("pagination");
    history.push({ search: searchParams.toString() });
  };

  return (
    <>
      {filterCount > 0 ? (
        <Button
          data-testid="clear-filter"
          onClick={() => resetFilters()}
          className={clsx(classes.clear, classes.buttonBase)}
        >
          {t("filters.clearFilter")}
        </Button>
      ) : null}
      <Button
        className={classes.buttonBase}
        startIcon={
          <Box component="span" display="flex" flexDirection="row">
            <AdjustmentsOutlined style={{ marginTop: 7, fontSize: 19 }} />
            <Divider
              orientation="vertical"
              component="span"
              style={{ color: "red", height: 34, marginLeft: 9 }}
            />
          </Box>
        }
        size="large"
        style={{ backgroundColor: "white", height: 36 }}
        data-testid="filter-toggle"
        onClick={(event) =>
          setLocalFilterAnchor(localFilterAnchor ? null : event.currentTarget)
        }
      >
        {t("filtersBtn")}
        {filterCount > 0 ? (
          <Chip
            color="blue"
            rounded
            size="small"
            label={filterCount}
            className={classes.chip}
          />
        ) : null}
      </Button>
      <Popper
        id={localFilterPopperId}
        open={localFilterVisible}
        anchorEl={localFilterAnchor}
        placement="bottom-start"
        modifiers={{ flip: { enabled: false } }}
        popperRef={localFilterPopperRef}
        className={classes.filterPopper}
      >
        <ClickAwayListener onClickAway={() => setLocalFilterAnchor(null)}>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={true}
              enableReinitialize
              onSubmit={async (values) => {
                const validatedValues =
                  await validationSchema?.validate(values);
                setLocalFilterAnchor(null);
                searchParams.delete("pagination");
                history.push({ search: searchParams.toString() });
                searchParams.delete("filter");
                searchParams.set(
                  "filter",
                  btoa(escape(JSON.stringify(validatedValues ?? values))),
                );
                history.push({ search: searchParams.toString() });
              }}
            >
              {({
                values,
                setFieldValue,
                setFieldTouched,
                resetForm,
                isValid,
                dirty,
                handleSubmit,
              }) => (
                <CreditFilter
                  isValid={isValid}
                  dirty={dirty}
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  handleSubmit={handleSubmit}
                  resetForm={resetForm}
                  resetFilters={resetFilters}
                  setLocalFilterAnchor={setLocalFilterAnchor}
                />
              )}
            </Formik>
          </div>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
