import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, memo } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { FilterRuleFieldArray } from "../filter-rule-field-array/FilterRuleFieldArray";
import arePropsEqual from "react-fast-compare";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "inline-block",
        borderColor: theme.palette.grey[50],
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "4px",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
        padding: theme.spacing(2),
        width: "100%",
    },
}));
function OrStatementBlockInner(props) {
    const classes = useStyles();
    const { onLastRemainingRuleDeletedInOrBlock, index } = props;
    const onLastRemainingRuleDeleted = useCallback(() => onLastRemainingRuleDeletedInOrBlock(index), [onLastRemainingRuleDeletedInOrBlock, index]);
    return (_jsx(Box, { className: classes.root, "data-testid": "or-statement-block", children: _jsx(FilterRuleFieldArray, { name: `${props.name}.${props.index}._and`, metrics: props.metrics, value: props.value._and, setFieldValue: props.setFieldValue, setFieldTouched: props.setFieldTouched, allowDeletingLastEmptyRule: props.allowDeletingLastEmptyRule, onLastRemainingRuleDeleted: onLastRemainingRuleDeleted, pendoPrefix: props.pendoPrefix }) }));
}
export const OrStatementBlock = memo(OrStatementBlockInner, arePropsEqual);
