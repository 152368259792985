import { getRawAccountId, useSession } from "@lumar/shared";
import { Redirect } from "react-router-dom";
import { Routes } from "./routes";

export function ApiAccessRedirect(): JSX.Element {
  const session = useSession();
  const accountId = getRawAccountId(session.account.id);

  return <Redirect to={Routes.ApiAccess.getUrl({ accountId })} />;
}
