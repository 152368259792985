import { Button, useBrand, useSession, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { RoleCode } from "../../graphql";
import { useSubscriptionEditContext } from "../subscription-edit/SubscriptionEditContext";

const useStyles = makeStyles(() => ({
  action: {
    background: "rgba(255, 255, 255, 0.7)",
    position: "fixed",
    width: "100%",
    height: "60px",
    bottom: 0,
    left: 0,
    textAlign: "right",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    paddingRight: "32px",
  },
}));

export function ActionPanel(): JSX.Element | null {
  const classes = useStyles();
  const brand = useBrand();
  const { t } = useTranslation("subscription");
  const { mode, setMode, dirty, submit, isSubmitting } =
    useSubscriptionEditContext();
  const { hasSufficientRole } = useSession();

  if (
    !hasSufficientRole(RoleCode.Admin) ||
    !brand.featureAvailability.editSubscription
  ) {
    return null;
  }

  if (mode === "view") {
    return (
      <div className={classes.action}>
        <div style={{ marginLeft: "auto" }}>
          <Button
            data-testid="edit-subscription"
            loading={isSubmitting}
            variant="contained"
            color="primary"
            size="large"
            disabled={dirty}
            onClick={() => setMode("edit")}
          >
            {dirty ? t("requestSubmittedShort") : t("editSubscription")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.action}>
      <div style={{ marginLeft: "auto" }}>
        <Button
          data-testid="submit-request"
          loading={isSubmitting}
          variant="contained"
          color="primary"
          size="large"
          disabled={!dirty}
          onClick={() => submit()}
        >
          {t("sendRequest")}
        </Button>
      </div>
    </div>
  );
}
