import React from "react";
import { Typography } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

const useStyles = makeStyles((theme) => ({
  allocation: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    color: theme.palette.grey[700],
  },
}));

export function CreditAllocationTypeCell(
  params: GridRenderCellParams,
): JSX.Element {
  const classes = useStyles();
  const value = params.value;
  return (
    <div>
      <Typography variant="body2" className={classes.allocation}>
        {value}
      </Typography>
    </div>
  );
}
