import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  section: {
    background: theme.palette.common.white,
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[300]}`,
    overflow: "hidden",
  },
  head: {
    padding: "24px 21px 24px 17px",
    color: theme.palette.grey[900],
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  content: {
    background: theme.palette.grey[100],
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
}));

export function SubscriptionSection(props: {
  headContent: React.ReactNode;
  mainContent?: React.ReactNode;
}): JSX.Element {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.head}>{props.headContent}</div>
      {props.mainContent ? (
        <div className={classes.content}>{props.mainContent}</div>
      ) : null}
    </div>
  );
}
