import { Typography, useSession, useTranslation } from "@lumar/shared";
import { Divider, makeStyles, Tooltip } from "@material-ui/core";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  div: {
    marginLeft: theme.spacing(1),
  },
  accountName: {
    color: theme.palette.grey[800],
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  accountId: {
    color: theme.palette.grey[600],
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(19.5),
    marginTop: theme.spacing(0.9),
    display: "block",
  },
  email: {
    color: theme.palette.grey[600],
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(19.5),
    marginBottom: theme.spacing(0.4),
    width: "250px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
  role: {
    display: "inline-block",
    color: theme.palette.grey[600],
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(15),
    background: theme.palette.grey[200],
    borderRadius: "3px",
    padding: theme.spacing(0.1, 0.5, 0.1, 0.5),
    marginBottom: theme.spacing(1.6),
  },
  divider: {
    color: theme.palette.grey[300],
    height: "0.5px",
  },
}));

export function AccountInfo(): JSX.Element {
  const classes = useStyles();
  const session = useSession();
  const { t } = useTranslation("sidebar");
  const { accountId } = useParams<{ accountId: string }>();
  const email = session.isUsingSharedLink ? t("guest") : session.email;

  return (
    <>
      <div className={classes.div}>
        <Typography className={classes.accountName}>
          {session.account.name}
        </Typography>
        <Typography className={classes.accountId}>
          {t("accountId", {
            accountId: accountId,
          })}
        </Typography>
        {email ? (
          <Tooltip title={email || ""}>
            <Typography className={classes.email}>{email}</Typography>
          </Tooltip>
        ) : null}
        <Typography className={classes.role}>
          {session.isDeepCrawlAdmin
            ? t("deepCrawlAdmin")
            : session.role.name?.toLocaleUpperCase()}
        </Typography>
      </div>
      <Divider className={classes.divider} />
    </>
  );
}
