import { PlusSolid, Trans, useTranslation } from "@lumar/shared";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { AddNewUserDialog } from "./AddNewUserDialog";
import { AccountUsers } from "../useAccountUsers";
import { Link, useParams } from "react-router-dom";
import { Routes } from "../../_common/routing/routes";

const useStyles = makeStyles((theme) => ({
  addUser: {
    marginRight: theme.spacing(1),
    border: "solid 1px #D1D5DB !important",
  },
}));

export function AddUser({
  accountUsers,
  disabled,
}: {
  accountUsers?: AccountUsers;
  disabled?: boolean;
}): JSX.Element {
  const { t } = useTranslation("teamPage");

  const classes = useStyles();
  const { accountId } = useParams<{ accountId: string }>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  if (!accountUsers) return <></>;
  const { addNewUser, roles } = accountUsers;

  const hasAccountReachedUserLimit =
    accountUsers.totalCount >= accountUsers.maxUsers;

  return (
    <div>
      <Tooltip
        title={
          hasAccountReachedUserLimit && !disabled ? (
            <Trans
              ns="teamPage"
              i18nKey="addUserTooltip"
              values={{ userCount: accountUsers.maxUsers }}
              components={{
                subscriptionLink: (
                  <Link to={Routes.Subscription.getUrl({ accountId })} />
                ),
              }}
            />
          ) : (
            ""
          )
        }
        placement="top"
        arrow
        interactive
      >
        <div>
          <Button
            className={classes.addUser}
            variant="contained"
            color="primary"
            startIcon={<PlusSolid />}
            onClick={() => setIsDialogOpen(true)}
            disabled={hasAccountReachedUserLimit || disabled}
            data-testid="add-user-btn"
          >
            {t("addUserBtn")}
          </Button>
        </div>
      </Tooltip>
      <AddNewUserDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        addNewUser={addNewUser}
        roles={roles}
      />
    </div>
  );
}
