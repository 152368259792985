import { jsx as _jsx } from "react/jsx-runtime";
import { FastField } from "formik";
import { TextField } from "formik-material-ui";
import { DatePicker } from "formik-material-ui-pickers";
import { MetricType } from "../../../graphql";
import { makeStyles } from "@material-ui/core";
import { ComboBox } from "../../combo-box/ComboBox";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => {
    var _a;
    return ({
        input: {
            width: 180,
            minWidth: 180,
            // FIXME: Inputs should be consistent in the first place.
            "& > [class*='MuiInputBase-focused'] fieldset": {
                borderColor: (_a = theme.palette.indigo) === null || _a === void 0 ? void 0 : _a[500],
            },
        },
    });
});
export function FilterPredicateInput(props) {
    const classes = useStyles();
    const { t } = useTranslation("connectionFiltering");
    if (props.type === MetricType.Boolean || props.type === MetricType.Bit) {
        const option = [
            {
                value: "true",
                label: t("true"),
            },
            {
                value: "false",
                label: t("false"),
            },
        ];
        return (_jsx(FastField, { name: props.name, children: ({ field: { value, name }, form: { setFieldValue, isSubmitting }, }) => (_jsx(ComboBox, { value: option.find((x) => x.value === value), onChange: (_, value) => {
                    setFieldValue(name, value.value);
                }, options: option, getOptionLabel: (option) => option.label, getOptionSelected: (option, selected) => option.value === (selected === null || selected === void 0 ? void 0 : selected.value), renderOption: (option) => (_jsx("div", { "data-testid": `boolean-value-${option.value}`, children: option.label })), disableClearable: true, disabled: isSubmitting, className: classes.input, inputProps: {
                    inputProps: {
                        "data-testid": "filter-rule-predicate-value-input",
                        "aria-label": "select value",
                    },
                }, "data-pendo": props["data-pendo"] })) }));
    }
    if ([MetricType.Integer, MetricType.Decimal].find((p) => p === props.type)) {
        return (_jsx(FastField, { autoFocus: true, name: props.name, className: classes.input, component: TextField, variant: "outlined", "aria-label": "numerical value", inputProps: {
                "data-testid": "filter-rule-predicate-value-input",
                "data-pendo": props["data-pendo"],
                "aria-label": "numerical value",
            }, hiddenLabel: true }));
    }
    if (props.type === MetricType.String) {
        return (_jsx(FastField, { autoFocus: true, name: props.name, className: classes.input, component: TextField, variant: "outlined", "aria-label": "text value", inputProps: {
                type: "text",
                "data-testid": "filter-rule-predicate-value-input",
                "data-pendo": props["data-pendo"],
            }, hiddenLabel: true }));
    }
    if (props.type === MetricType.Date) {
        return (_jsx(FastField, { autoFocus: true, className: classes.input, name: props.name, component: DatePicker, disableToolbar: true, format: "dd MMMM yyyy", variant: "inline", inputVariant: "outlined", "aria-label": "select date", inputProps: {
                "data-testid": "filter-rule-predicate-value-input",
                "data-pendo": props["data-pendo"],
                "aria-label": "date value",
            }, hiddenLabel: true }));
    }
    return null;
}
