import { Typography, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { AccountUsers } from "./useAccountUsers";

const useStyles = makeStyles((theme) => ({
  rowsCounter: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[900],
  },
  div: {
    display: "flex",
    alignItems: "center",
  },
}));

export function UserCounter({
  accountUsers,
  disabled,
}: {
  accountUsers?: AccountUsers;
  disabled?: boolean;
}): JSX.Element {
  const { t } = useTranslation("teamPage");
  const classes = useStyles();

  if (!accountUsers || disabled) return <></>;
  const { maxUsers, totalCount } = accountUsers;

  return (
    <div className={classes.div}>
      <Typography className={classes.rowsCounter} variant="subtitle2Medium">
        {t("userCount", { user: totalCount, maxUser: maxUsers })}
      </Typography>
    </div>
  );
}
