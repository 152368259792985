import {
  ConnectionFilter,
  UrlServerPagination,
  ApolloError,
  getApiAccountId,
  useSession,
} from "@lumar/shared";
import { useParams } from "react-router-dom";
import {
  GetCreditUsageDataQuery,
  RoleCode,
  useGetCreditUsageDataQuery,
} from "../graphql";

export interface CreditReportData {
  data: GetCreditUsageDataQuery | undefined;
  loading: boolean;
  error: ApolloError | undefined;
}

export function useCreditUsageData(
  filter: ConnectionFilter,
  from: Date,
  to: Date,
  pageInfo: UrlServerPagination["pageInfo"],
): CreditReportData {
  const { hasSufficientRole } = useSession();
  const hasMinimunEditorPermission = hasSufficientRole(RoleCode.Editor);
  const { accountId } = useParams<{ accountId: string }>();
  const apiAccountId = getApiAccountId(accountId);
  const { data, loading, error } = useGetCreditUsageDataQuery({
    variables: {
      accountId: apiAccountId,
      filters: {
        createdAt: { ge: from.toISOString(), le: to.toISOString() },
        ...filter,
      },
      ...pageInfo,
    },
    skip: !hasMinimunEditorPermission,
  });

  return { data, loading, error };
}
