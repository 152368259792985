import { BlueDataGridProps, Chip } from "@lumar/shared";
import { AccountsActionsCell } from "./AccountsActionsCell";
import { TFunction } from "i18next";

export const accountsDataGridColumns = (args: {
  t: TFunction<"adminPanel">;
  onDeactivateAccount: (id: string) => void;
  onUpdateProjects: (id: string) => void;
}): BlueDataGridProps["columns"] => [
  {
    field: "id",
    headerName: args.t("accountsColumn.id"),
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "ssoId",
    headerName: args.t("accountsColumn.ssoId"),
    width: 120,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "name",
    headerName: args.t("accountsColumn.name"),
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "projects",
    headerName: args.t("accountsColumn.projects"),
    width: 120,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "status",
    headerName: args.t("accountsColumn.status"),
    width: 100,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (props) => (
      <Chip
        color={props.row.active ? "green" : "red"}
        label={
          props.row.active
            ? args.t("accountsColumn.active")
            : args.t("accountsColumn.inactive")
        }
      />
    ),
  },
  {
    field: "manage",
    headerName: args.t("accountsColumn.manage"),
    sortable: false,
    width: 100,
    disableColumnMenu: true,
    renderCell: (props) => (
      <AccountsActionsCell
        {...props}
        onDeactivateAccount={
          props.row.active ? args.onDeactivateAccount : undefined
        }
        onUpdateProjects={props.row.active ? args.onUpdateProjects : undefined}
      />
    ),
  },
];
