import { Chip, ChipColor, useTranslation } from "@lumar/shared";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { TFunction } from "i18next";

export function StatusCell(props: GridRenderCellParams): JSX.Element {
  const { t } = useTranslation("teamPage");
  const status = props.value as string | null;
  return (
    <div>
      <Chip color={getChipColor(status)} label={getStatus(status, t)} />
    </div>
  );
}

function getChipColor(status: string | null): ChipColor {
  switch (status) {
    case null:
      return "red";
    default:
      return "green";
  }
}

function getStatus(status: string | null, t: TFunction<"teamPage">): string {
  switch (status) {
    case null:
      return t("emailUnconfirmed");
    default:
      return t("active");
  }
}
