var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { getDefaultPredicateValue, getMetricPredicates } from "../helpers";
import { makeStyles } from "@material-ui/core/styles";
import { FastField } from "formik";
import { Tooltip } from "@material-ui/core";
import { ComboBox } from "../../combo-box/ComboBox";
import { MetricType } from "../../../graphql";
import { InfoOutlined } from "../../../icons";
import { clsx } from "clsx";
import { useTranslation } from "../../../i18n";
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 260,
    },
    option: {
        display: "flex",
        width: "100%",
    },
    menuItem: {
        "&:hover svg": {
            opacity: 1,
        },
    },
    infoIcon: {
        fontSize: theme.typography.pxToRem(18),
        color: theme.palette.grey[500],
        marginLeft: "auto",
        alignSelf: "center",
        opacity: 0,
    },
    unit: {
        alignSelf: "center",
        color: theme.palette.grey[400],
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(13),
        paddingRight: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        minWidth: 22,
        textAlign: "end",
        flexShrink: 0,
    },
}));
function valueUpdateNeeded(oldvalue, newvalue) {
    var _a, _b;
    const oldType = (_a = oldvalue === null || oldvalue === void 0 ? void 0 : oldvalue.connectionPredicates) === null || _a === void 0 ? void 0 : _a[0].type;
    const newType = (_b = newvalue.connectionPredicates) === null || _b === void 0 ? void 0 : _b[0].type;
    const changedType = oldType !== newType;
    const isPreviousTypeBooleanOrBit = oldType === MetricType.Boolean || oldType === MetricType.Bit;
    const isNextTypeBooleanOrBit = newType === MetricType.Boolean || newType === MetricType.Bit;
    const changedTypeBetweenBooleanOrBit = changedType && isPreviousTypeBooleanOrBit && isNextTypeBooleanOrBit;
    return changedType && !changedTypeBetweenBooleanOrBit;
}
export const FilterMetricSelect = React.memo(function FilterMetricSelect(props) {
    const classes = useStyles();
    const { name, predicateValue, predicateName, metrics, filter: { metricCode, predicateKey }, setFieldValue, } = props;
    useEnsurePredicateBelongsToMetric(metrics, metricCode, predicateKey, predicateName, setFieldValue);
    return (_jsx(FastField, { name: name, children: ({ field: { value, name }, form: { setFieldValue, isSubmitting }, }) => (_jsx(ComboBox, { value: metrics.find((x) => x.code === value), onChange: (event, newValue) => {
                event.preventDefault();
                setFieldValue(name, newValue.code, false);
                if (valueUpdateNeeded(metrics.find((x) => x.code === value), newValue))
                    setFieldValue(predicateValue, getDefaultPredicateValue(newValue.connectionPredicates[0].type), false);
            }, options: metrics, getOptionLabel: (option) => option.name, getOptionSelected: (option, selected) => option.code === (selected === null || selected === void 0 ? void 0 : selected.code), renderOption: (option) => (_jsxs("div", { className: classes.option, "data-testid": getFilterMetricOptionTestId(option.code), children: [option.name, option.description && (_jsx(Tooltip, { title: option.description, arrow: true, placement: "right", children: _jsx(InfoOutlined, { className: classes.infoIcon, fontSize: "small", "data-testid": "metric-description-icon" }) }))] })), disableClearable: true, disabled: isSubmitting, className: clsx(classes.root, props.className), classes: { option: classes.menuItem }, inputProps: {
                inputProps: {
                    "data-testid": "filter-rule-metric-input",
                    "aria-label": "select metric",
                },
                InputProps: {
                    inputComponent: MetricInput,
                },
            }, "data-pendo": props["data-pendo"], "data-testid": "filter-rule-metric-combobox" })) }));
});
function MetricInput(_a) {
    var _b;
    var { inputRef, metric } = _a, props = __rest(_a, ["inputRef", "metric"]);
    const classes = useStyles();
    const { t } = useTranslation("units");
    const unit = getUnit((_b = metric === null || metric === void 0 ? void 0 : metric.metadata) === null || _b === void 0 ? void 0 : _b.unit, t);
    return (_jsxs(_Fragment, { children: [_jsx("input", Object.assign({}, props, { ref: inputRef })), Boolean(unit) && _jsx("span", { className: classes.unit, children: unit })] }));
}
export function getFilterMetricOptionTestId(metricCode) {
    return `filter-metric-option-${metricCode}`;
}
function getUnit(unit, t) {
    switch (unit) {
        case "word":
            return t("word_plural");
        case "second":
            return t("second_plural");
        case "pixel":
            return t("pixelUnit");
        case "character":
            return t("characterUnit_plural");
        case "percent":
            return t("percentUnit");
        case "byte":
            return t("byte_plural");
    }
}
function useEnsurePredicateBelongsToMetric(metrics, metricCode, predicateKey, predicateName, setFieldValue) {
    useEffect(() => {
        var _a;
        const applicablePredicates = getMetricPredicates(metrics, metricCode);
        const isPredicateKeyApplicableToMetric = Boolean(applicablePredicates.find((predicate) => predicate.code === predicateKey));
        // FIXME: For some reason, when changing between reports of different data source,
        // for a brief moment, `metricCode` is set to "url" (crawl urls data source) and the array might be empty.
        // This confirms that lifecycle of this component needs a rework. - Michal
        const defaultPredicateForMetric = (_a = applicablePredicates[0]) === null || _a === void 0 ? void 0 : _a.code;
        if (!isPredicateKeyApplicableToMetric && defaultPredicateForMetric) {
            setFieldValue(predicateName, defaultPredicateForMetric, false);
        }
    }, [metricCode, metrics, predicateKey, setFieldValue, predicateName]);
}
