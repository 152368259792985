/* eslint-disable react/display-name */
import React, { useMemo } from "react";
import { useTranslation } from "@lumar/shared";
import { GridColumns } from "@mui/x-data-grid-pro";
import { NameCell } from "./cells/NameCell";
import { EmailCell } from "./cells/EmailCell";
import { StatusCell } from "./cells/StatusCell";
import { ActionsCell } from "./cells/ActionsCell";
import { RoleCell } from "./cells/RoleCell";
import { AccountUsers } from "./useAccountUsers";
import { AuthCell } from "./cells/AuthCell";

export function useTeamPageColumns(accountUsers: AccountUsers): GridColumns {
  const { t } = useTranslation("teamPage");

  const columns = useMemo(() => {
    const columns: GridColumns = [
      {
        field: "email",
        headerName: t("email"),
        flex: 3,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableExport: false,
        valueGetter: ({ row: { email } }) => email,
        renderCell: (props) => <EmailCell {...props} />,
      },
      {
        field: "name",
        headerName: t("name"),
        flex: 3,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableExport: false,
        valueGetter: ({ row: { name } }) => name,
        renderCell: (props) => <NameCell {...props} />,
      },
      {
        field: "status",
        headerName: t("status"),
        flex: 2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableExport: true,
        resizable: true,
        valueGetter: ({ row: { status } }) => status,
        renderCell: (props) => <StatusCell {...props} />,
      },
      {
        field: "authentication",
        headerName: t("authentication"),
        flex: 1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableExport: true,
        resizable: true,
        valueGetter: ({ row: { ssoEnabled } }) => ssoEnabled,
        renderCell: (props) => <AuthCell {...props} />,
      },
      {
        field: "role",
        headerName: t("role"),
        flex: 1,
        minWidth: 115,
        sortable: false,
        filterable: true,
        disableColumnMenu: true,
        resizable: false,
        disableExport: true,
        valueGetter: ({ row: { role } }) => role,
        renderCell: (props) => (
          <RoleCell accountUsers={accountUsers} {...props} />
        ),
      },
      {
        field: "actions",
        headerName: t("actions"),
        headerAlign: "center",
        align: "center",
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableExport: true,
        resizable: false,
        renderCell: (props) => (
          <ActionsCell accountUsers={accountUsers} {...props} />
        ),
      },
    ];
    return columns;
  }, [accountUsers, t]);

  return columns;
}
