import * as Yup from "yup";
import { validate } from "@deepcrawl/lucene-regex-validator";
import { MetricType, ConnectionPredicate } from "../../../graphql";
import { getPredicateType } from "../helpers";
// Note: Explicit return type looks like an overkill in this scenario. - Michal
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function getFilterRuleArrayValidationSchema(metrics, t) {
    function isValueTypeValid(types) {
        return (predicateKey, metricCode) => {
            const predicateType = getPredicateType(metrics, metricCode, predicateKey);
            if (predicateType) {
                return types.includes(predicateType);
            }
            return false;
        };
    }
    function isNonNullablePredicate(predicateKey) {
        return [
            ConnectionPredicate.BeginsWith,
            ConnectionPredicate.EndsWith,
            ConnectionPredicate.Contains,
            ConnectionPredicate.NotContains,
            ConnectionPredicate.MatchesRegex,
            ConnectionPredicate.NotMatchesRegex,
        ].includes(predicateKey);
    }
    return Yup.array()
        .of(Yup.object()
        .shape({
        id: Yup.string().required(),
        metricCode: Yup.string().required(),
        predicateKey: Yup.string().required(),
        predicateValue: Yup.mixed()
            .when(["predicateKey", "metricCode"], {
            is: isValueTypeValid([MetricType.Decimal]),
            then: () => Yup.number()
                .typeError(t("validation.number"))
                .required(t("validation.required")),
        })
            .when(["predicateKey", "metricCode"], {
            is: isValueTypeValid([MetricType.Integer]),
            then: () => Yup.number()
                .typeError(t("validation.integer"))
                .integer("Please enter an integer.")
                .required(t("validation.required"))
                .min(Number.MIN_SAFE_INTEGER, t("validation.numberMin"))
                .max(Number.MAX_SAFE_INTEGER, t("validation.numberMax")),
        })
            .when(["predicateKey", "metricCode"], {
            is: isValueTypeValid([MetricType.Bit, MetricType.Boolean]),
            then: () => Yup.mixed()
                .required(t("validation.required"))
                .transform((value) => String(value) === "true"),
        })
            .when(["predicateKey", "metricCode"], {
            is: isValueTypeValid([MetricType.String]),
            then: () => Yup.string().when("predicateKey", {
                is: (predicate) => isNonNullablePredicate(predicate),
                then: (schema) => schema.required(t("validation.required")),
            }),
        })
            .when(["predicateKey", "metricCode"], {
            is: isValueTypeValid([MetricType.Date]),
            then: () => Yup.date().required(t("validation.required")),
        })
            .when("predicateKey", {
            is: (predicateVal) => predicateVal === ConnectionPredicate.MatchesRegex ||
                predicateVal === ConnectionPredicate.NotMatchesRegex,
            then: () => Yup.string().test("Regex test", t("validation.regex"), (testValue) => typeof testValue === "string" && validate(testValue).valid),
        })
            .required(t("validation.required")),
    })
        .required())
        .required();
}
