import {
  Check,
  Checkbox,
  Info,
  PencilSolid,
  ToggleIconButton,
  useNumberFormatter,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { AddonIntegration, Limit, Service } from "../subscription-data/types";
import { useSubscriptionEditContext } from "../subscription-edit/SubscriptionEditContext";
import React from "react";
import { omit } from "lodash";

const useStyles = makeStyles((theme) => ({
  featureListTitle: {
    padding: theme.spacing(3, 3.5, 1.5, 3.5),
    fontWeight: 700,
    fontSize: 15,
  },
  featureList: {},
  feature: {
    "&:nth-child(2n-1)": {
      background: theme.palette.common.white,
    },
    "&:first-child": {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    "&:last-child": {
      borderBottom: "unset",
    },
    minHeight: "42px",
    alignItems: "center",
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(2.8),
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  featureName: {
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.grey[600],
  },
  featureValue: {
    marginLeft: "auto",
    fontWeight: 500,
    fontSize: 14,
    color: theme.palette.grey[600],
  },
  input: {
    fontWeight: 500,
    fontSize: "14px",
    fontStyle: "normal",
    fontFamily: theme.typography.fontFamily,
    boxSizing: "border-box",
    background: "#FFFFFF",
    border: "1px solid #D1D5DB",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",
    height: "26px",
    textAlign: "right",
    verticalAlign: "middle",
  },
  inputError: {
    borderColor: theme.palette.error.light,
    borderWidth: "1px",
    borderStyle: "solid",
  },
}));

export function LimitsList(props: { limits: Limit[] }): JSX.Element {
  const { t } = useTranslation("subscription");
  const classes = useStyles();
  return (
    <>
      <div className={classes.featureListTitle}>{t("limits.sectionName")}</div>
      <div className={classes.featureList}>
        {props.limits.map((limit) => {
          return <LimitFeatureRow key={limit.code} limit={limit} />;
        })}
      </div>
    </>
  );
}

function LimitFeatureRow({ limit }: { limit: Limit }): JSX.Element {
  const classes = useStyles();
  const { isValueEdited, mode } = useSubscriptionEditContext();

  const hasBeenEdited = isValueEdited(limit.submitCode);

  return (
    <div className={classes.feature}>
      <div className={classes.featureName}>
        <span
          style={{
            color: hasBeenEdited && mode === "edit" ? "#14B8A6" : "inherit",
          }}
          data-testid={`${limit.code}-name`}
        >
          {limit.name}
        </span>{" "}
        <Tooltip
          title={limit.description}
          placement="right"
          style={{ verticalAlign: "middle" }}
        >
          <InfoIcon />
        </Tooltip>
      </div>
      <div className={classes.featureValue}>
        <FeatureValue limit={limit} />
      </div>
    </div>
  );
}

export function FeatureValue(props: { limit: Limit }): JSX.Element {
  const classes = useStyles();
  const formatNumber = useNumberFormatter();

  const { mode, setEditValue, getEditValue, removeEditValue, isValueEdited } =
    useSubscriptionEditContext();

  const [isBeingModified, setIsBeingModified] = useState(false);
  const hasBeenEdited = isValueEdited(props.limit.submitCode);

  const value =
    (getEditValue(props.limit.submitCode) as number) ?? props.limit.value;
  const [modifyTo, setModifyTo] = useState(value);
  const validModification =
    props.limit.code === "majestic"
      ? modifyTo >= 0 && modifyTo <= 50000
      : modifyTo >= 0;

  function setChangedEditValue(submitCode: string, value: number): void {
    if (hasBeenEdited && props.limit.value === value) {
      removeEditValue(submitCode);
    }
    if (props.limit.value === value) {
      return;
    }
    setEditValue(submitCode, value);
  }

  if (mode === "view") {
    return (
      <span data-testid={`${props.limit.code}-value`}>
        {formatNumber(props.limit.value)} {props.limit.unit ?? null}{" "}
      </span>
    );
  }

  if (isBeingModified) {
    return (
      <>
        <input
          data-testid={`${props.limit.code}-input`}
          autoFocus
          className={clsx({
            [classes.input]: true,
            [classes.inputError]: !validModification,
          })}
          type="number"
          defaultValue={value}
          onChange={(e) => setModifyTo(e.target.valueAsNumber)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && validModification) {
              setIsBeingModified(false);
              setChangedEditValue(props.limit.submitCode, modifyTo);
            }
          }}
          aria-label="change value"
        />{" "}
        <ToggleIconButton
          data-testid={`${props.limit.code}-save`}
          size="small"
          color="secondary"
          onClick={() => {
            if (!validModification) {
              return;
            }
            setIsBeingModified(false);
            setChangedEditValue(props.limit.submitCode, modifyTo);
          }}
          variant="outlined"
          aria-label="save value"
        >
          <Check />
        </ToggleIconButton>
      </>
    );
  }

  return (
    <>
      <span
        data-testid={`${props.limit.code}-value`}
        style={{
          color: hasBeenEdited ? "#14B8A6" : "inherit",
          verticalAlign: "middle",
        }}
      >
        {formatNumber(value)} {props.limit.unit ?? null}{" "}
      </span>
      <ToggleIconButton
        data-testid={`${props.limit.code}-edit`}
        size="small"
        onClick={() => setIsBeingModified(true)}
        variant="outlined"
        aria-label="edit feature"
      >
        <PencilSolid />
      </ToggleIconButton>
    </>
  );
}

export function AddonIntegrationList(props: {
  addonIntegrations: AddonIntegration[];
  type: "addon" | "integration" | "app";
}): JSX.Element {
  const { t } = useTranslation("subscription");
  const classes = useStyles();
  return (
    <>
      <div className={classes.featureListTitle}>
        {props.type === "addon" ? t("addons.sectionName") : null}
        {props.type === "integration" ? t("integrations.sectionName") : null}
        {props.type === "app" ? t("apps.sectionName") : null}
      </div>
      <div className={classes.featureList}>
        {props.addonIntegrations.map((integration) => {
          return (
            <AddonIntegrationRow
              key={integration.code}
              addonIntegration={integration}
            />
          );
        })}
      </div>
    </>
  );
}

function AddonIntegrationRow({
  addonIntegration: integration,
}: {
  addonIntegration: AddonIntegration;
}): JSX.Element {
  const classes = useStyles();
  const { getEditValue, mode } = useSubscriptionEditContext();
  const requested = getEditValue(integration.submitCode) as boolean;

  return (
    <div className={classes.feature}>
      <div className={classes.featureName}>
        <span
          style={{
            color: requested && mode === "edit" ? "#14B8A6" : "inherit",
          }}
          data-testid={`${integration.code}-name`}
        >
          {integration.name}
        </span>{" "}
        {integration.description ? (
          <Tooltip
            title={integration.description}
            placement="right"
            style={{ verticalAlign: "middle" }}
          >
            <InfoIcon />
          </Tooltip>
        ) : null}
      </div>
      <div className={classes.featureValue}>
        <AddonIntegrationValue integration={integration} />
      </div>
    </div>
  );
}

function AddonIntegrationValue(props: {
  integration: AddonIntegration;
}): JSX.Element {
  const { t } = useTranslation("subscription");
  const { mode, setEditValue, getEditValue, removeEditValue } =
    useSubscriptionEditContext();
  const included = props.integration.included;
  const requested = getEditValue(props.integration.submitCode) as boolean;

  function setChangedEditValue(submitCode: string, include: boolean): void {
    if (requested && props.integration.included === include) {
      removeEditValue(submitCode);
    }
    if (props.integration.included === include) {
      return;
    }

    setEditValue(submitCode, include);
  }

  if (mode === "view") {
    return included ? (
      <span data-testid={`${props.integration.code}-value`}>
        {t("included")}
      </span>
    ) : (
      <span data-testid={`${props.integration.code}-value`}>
        {t("notIncluded")}
      </span>
    );
  }

  if (requested) {
    return (
      <>
        <span
          data-testid={`${props.integration.code}-value`}
          style={{ color: requested ? "#14B8A6" : "inherit" }}
        >
          {t("requesting")}
        </span>{" "}
        <span style={{ marginRight: 6 }}>
          <Checkbox
            size="medium"
            checked={requested}
            onChange={(e) =>
              setChangedEditValue(
                props.integration.submitCode,
                e.target.checked,
              )
            }
          />
        </span>
      </>
    );
  }

  if (included) {
    return (
      <span data-testid={`${props.integration.code}-value`}>
        {t("included")}{" "}
        <span
          style={{
            verticalAlign: "middle",
            marginRight: 4,
            color: "#14B8A6",
          }}
        >
          <Check fontSize="small" />
        </span>
      </span>
    );
  }

  return (
    <span data-testid={`${props.integration.code}-value`}>
      {t("notIncluded")}{" "}
      <Checkbox
        data-testid={`${props.integration.code}-checkbox`}
        size="medium"
        defaultChecked={requested}
        onChange={(e) =>
          setChangedEditValue(props.integration.submitCode, e.target.checked)
        }
        style={{ marginRight: 6, marginBottom: 2 }}
      />
    </span>
  );
}

const ServiceValue = (service: Service): JSX.Element => {
  const classes = useStyles();

  const value = service.value;
  const isSupportEmail = value?.includes("@");
  const isSupportLink = value?.startsWith("http");

  if (isSupportEmail || isSupportLink) {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={isSupportEmail ? `mailto:${value}` : value}
        className={classes.featureValue}
        data-testid={`${service.code}-value`}
      >
        {service.value}
      </a>
    );
  }

  return (
    <div className={classes.featureValue} data-testid={`${service.code}-value`}>
      {service.value ? service.value : "-"}
    </div>
  );
};

export function ServicesList(props: { services: Service[] }): JSX.Element {
  const { t } = useTranslation("subscription");
  const classes = useStyles();

  return (
    <>
      <div className={classes.featureListTitle}>
        {t("services.sectionName")}
      </div>
      <div className={classes.featureList}>
        {props.services.map((service) => {
          return (
            <div key={service.code} className={classes.feature}>
              <div
                className={classes.featureName}
                data-testid={`${service.code}-name`}
              >
                {service.name}
              </div>
              <ServiceValue {...service} />
            </div>
          );
        })}
      </div>
    </>
  );
}

const InfoIcon = React.forwardRef<SVGSVGElement, Parameters<typeof Info>[0]>(
  function InfoIcon(props, ref): JSX.Element {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const title = (props as any).title;
    const titleAccess = typeof title === "string" ? title : undefined;

    return (
      <Info
        ref={ref}
        fontSize="small"
        tabIndex={0}
        titleAccess={titleAccess}
        {...omit(props, "title")}
      />
    );
  },
);
