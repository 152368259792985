import {
  Button,
  CheckCircle,
  Dialog,
  ExclamationCircleSolid,
  TextField,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { Box, InputAdornment, makeStyles } from "@material-ui/core";

import { useHistory } from "react-router";
import { Routes } from "../_common/routing/routes";
import { useConfirmEmailForm } from "./useConfirmEmailForm";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    maxWidth: 500,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },
  jobTitleLabel: {
    marginBottom: theme.spacing(1),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  errorsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  errorMessage: {
    marginBottom: theme.spacing(1),
  },
  content: {
    display: "flex",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    width: 472,
    padding: theme.spacing(1, 1, 3, 1),
  },
  description: {
    marginTop: theme.spacing(1),
  },
  icon: {
    fontSize: "48px",
    marginTop: theme.spacing(1),
    color: theme.palette.green[500],
  },
}));

export const ConfirmEmailForm = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("confirmEmail");
  const history = useHistory();

  const {
    formik: {
      handleSubmit,
      handleChange,
      values,
      errors,
      isSubmitting,
      isValid,
    },
    success,
  } = useConfirmEmailForm();

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <TextField
        label={t("form.labels.password")}
        name="password"
        type="password"
        placeholder={t("form.placeholders.password")}
        onChange={handleChange}
        className={classes.input}
        value={values.password}
        error={!!errors.password}
        InputProps={{
          endAdornment: !!errors.password && (
            <InputAdornment position="end">
              <ExclamationCircleSolid fontSize="small" color="error" />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label={t("form.labels.confirmPassword")}
        name="confirmPassword"
        type="password"
        placeholder={t("form.placeholders.confirmPassword")}
        onChange={handleChange}
        className={classes.input}
        value={values.confirmPassword}
        error={!!errors.confirmPassword}
        InputProps={{
          endAdornment: !!errors.confirmPassword && (
            <InputAdornment position="end">
              <ExclamationCircleSolid fontSize="small" color="error" />
            </InputAdornment>
          ),
        }}
      />

      <Box className={classes.errorsContainer}>
        {Object.keys(errors).map((field) => {
          // Due to limitation on Yup schema type infering, is not possible
          // to use field here as a index signature as in this case it's considered
          // a generic string but Typescript spect it to be within limited options
          // due to the keys in the validation schema - Alex S.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const error = errors?.[field] as string;
          return error ? (
            <Typography variant="caption" color="error">
              *{error}
            </Typography>
          ) : null;
        })}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
        >
          {t("form.submit")}
        </Button>
      </Box>
      <Dialog
        open={success}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        buttons={[
          {
            variant: "contained",
            color: "primary",
            title: t("dialog.action"),
            onClick: () => {
              history.push(Routes.Login.ROUTE);
            },
          },
        ]}
      >
        <Box className={classes.content}>
          <CheckCircle className={classes.icon} />
          <Box className={classes.textContainer}>
            <Typography variant="h6SemiBold">{t("dialog.title")}</Typography>
            <Typography className={classes.description}>
              {t("dialog.description")}
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </form>
  );
};
