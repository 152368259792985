import { insertIf, useBrand, useTranslation } from "@lumar/shared";
import { Limit } from "./types";

export function useDefaultLimits(): Limit[] {
  const { t } = useTranslation("subscription");
  const brand = useBrand();
  return [
    {
      code: "projects",
      name: t("limits.projects.name"),
      description: t("limits.projects.description"),
      unit: "",
      value: 0,
      submitCode: "project_limit",
    },
    ...insertIf(brand.featureAvailability.monitorApp, {
      code: "views",
      name: t("limits.views.name"),
      unit: "",
      description: t("limits.views.description"),
      value: 0,
      submitCode: "views_limit",
    }),
    ...insertIf(brand.featureAvailability.monitorApp, {
      code: "maxProjectTests",
      name: t("limits.maxProjectTests.name"),
      unit: "",
      description: t("limits.maxProjectTests.description"),
      value: 0,
      submitCode: "monitor_alert_rules_limit",
    }),
    {
      code: "maxCrawlRate",
      name: t("limits.maxCrawlRate.name"),
      unit: t("limits.maxCrawlRate.unit"),
      description: t("limits.maxCrawlRate.description"),
      value: 0,
      submitCode: "max_crawl_rate_change_requested",
    },
    {
      code: "limitPagesMax",
      name: t("limits.limitPagesMax.name"),
      unit: "",
      description: t("limits.limitPagesMax.description"),
      value: 0,
      submitCode: "max_crawl_pages_change_requested",
    },
    {
      code: "limitLevelsMax",
      name: t("limits.limitLevelsMax.name"),
      unit: "",
      description: t("limits.limitLevelsMax.description"),
      value: 0,
      submitCode: "max_crawl_depth_level_change_requested",
    },
    {
      code: "majestic",
      name: t("limits.majestic.name"),
      unit: t("limits.majestic.unit"),
      description: t("limits.majestic.description"),
      value: 0,
      submitCode: "majestic_urls_limit",
    },
  ];
}
