import React from "react";
import { Chip, Typography, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  outerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  nameAndDomain: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 264,
  },
  name: {
    color: theme.palette.grey[900],
  },
  domain: {
    color: theme.palette.grey[700],
  },
  truncate: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: "normal",
  },
  badge: {
    marginLeft: "auto",
  },
}));

export function ProjectCell(params: GridRenderCellParams): JSX.Element {
  const isCrawlDeleted = params.row.isDeleted;
  const classes = useStyles();
  const { t } = useTranslation("creditUsage");

  const isProjectEmpty =
    params.row.projectName === "" && params.row.domainName === "";

  return (
    <div className={classes.outerDiv}>
      <div className={clsx(classes.nameAndDomain, classes.truncate)}>
        <Typography
          variant="subtitle3Medium"
          className={clsx(classes.name, classes.truncate)}
        >
          {isProjectEmpty ? "-" : params.row.projectName}
        </Typography>
        <Typography
          variant="caption"
          className={clsx(classes.domain, classes.truncate)}
        >
          {params.row.domainName}
        </Typography>
      </div>
      <div className={classes.badge}>
        {isCrawlDeleted && (
          <Chip color="red" rounded size="small" label={t("deleted")} />
        )}
      </div>
    </div>
  );
}
