import { ApolloError, useTranslation } from "@lumar/shared";
import { useParams } from "react-router-dom";
import {
  AccountSubscriptionQuery,
  useAccountSubscriptionQuery,
} from "../graphql";

export interface SubscriptionQueryResult {
  data?: AccountSubscriptionQuery;
  loading: boolean;
  errorMessage?: string;
}

export function useSubscriptionQuery(): SubscriptionQueryResult {
  const { accountId } = useParams<{ accountId: string }>();
  const { data, error, loading } = useAccountSubscriptionQuery({
    variables: {
      accountId,
    },
  });

  return {
    data,
    loading,
    errorMessage: useErrorMessage(error, data, loading),
  };
}

function useErrorMessage(
  error?: ApolloError,
  data?: AccountSubscriptionQuery,
  loading?: boolean,
): string | undefined {
  const { t } = useTranslation("subscription");

  if (loading) {
    return;
  }

  if (error) {
    if (error.message) {
      return error.message;
    }
    return t("somethingWentWrong");
  }

  if (!data) {
    return t("noData");
  }

  if (!data.getAccount) {
    return t("noAccount");
  }

  if (!data.getAccount.subscription) {
    return t("noSubscription");
  }

  return;
}
