import { Dialog, Snackbar, Typography, useTranslation } from "@lumar/shared";
import { Box, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { GetUserKeysDocument, useDeleteUserKeyMutation } from "../graphql";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 500,
    padding: theme.spacing(1, 1, 3, 1),
  },
  backdrop: {
    zIndex: 1200,
  },
}));

type Props = {
  userKeyId?: string;
  onClose: () => void;
};

export const DeleteApiKeyConfirmDialog = ({
  userKeyId,
  onClose,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("apiAccess");
  const { enqueueSnackbar } = useSnackbar();

  const [deleteUserKey] = useDeleteUserKeyMutation({
    refetchQueries: [GetUserKeysDocument],
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
  });

  return (
    <Dialog
      open={!!userKeyId}
      title={t("deleteApiKeyDialog.title")}
      onClose={onClose}
      buttons={[
        {
          variant: "contained",
          color: "secondary",
          title: t("deleteApiKeyDialog.cancel"),
          onClick: () => onClose(),
        },
        {
          "data-testid": "confirm-delete-key",
          variant: "contained",
          color: "primary",
          title: t("deleteApiKeyDialog.confirm"),
          onClick: async () => {
            await deleteUserKey({ variables: { userKeyId } });
            onClose();
          },
        },
      ]}
      classes={{ backdrop: classes.backdrop }}
    >
      <Box className={classes.content}>
        <Typography>{t("deleteApiKeyDialog.description")}</Typography>
      </Box>
    </Dialog>
  );
};
