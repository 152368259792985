import { Dialog, Snackbar, useTranslation } from "@lumar/shared";
import { makeStyles, Typography } from "@material-ui/core";
import { enqueueSnackbar } from "notistack";
import { useDeactivateSsoAccountMutation } from "../../../graphql";

type Props = {
  accountId?: string;
  open: boolean;
  onClose: () => void;
};

export const DeactivateAccountDialog = (props: Props): JSX.Element => {
  if (props.open && !props.accountId) {
    throw new Error("Account id is required");
  }

  const classes = useStyles();
  const { t } = useTranslation("adminPanel");

  const onCloseDialog = (): void => {
    props.onClose();
  };

  const [deactivateAccount, { loading }] = useDeactivateSsoAccountMutation({
    onCompleted: () => {
      enqueueSnackbar(
        <Snackbar variant="success" title={t("deactivateAccount.success")} />,
      );
      onCloseDialog();
    },
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
    refetchQueries: ["GetAccounts"],
  });

  return (
    <Dialog
      open={props.open}
      onCloseViaIcon={onCloseDialog}
      title={t("deactivateAccount.title")}
      buttons={[
        {
          variant: "outlined",
          color: "default",
          title: t("deactivateAccount.cancel"),
          onClick: onCloseDialog,
        },
        {
          variant: "contained",
          color: "secondary",
          title: t("deactivateAccount.confirm"),
          disabled: loading,
          onClick: async () => {
            await deactivateAccount({
              variables: { accountId: props.accountId },
            });
          },
        },
      ]}
    >
      <div className={classes.container}>
        <Typography>{t("deactivateAccount.text")}.</Typography>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
}));
