import {
  DeleteAccountUserMutation,
  GetAccountUsersDocument,
  GetAccountUsersQuery,
  Maybe,
  RoleCode,
  UpdateUserRoleMutation,
  useAddAccountUserMutation,
  useDeleteAccountUserMutation,
  useGetAccountUsersQuery,
  useResendInviteLinkMutation,
  useUpdateUserRoleMutation,
} from "../graphql";
import {
  useTranslation,
  Snackbar,
  ApolloCache,
  ApolloError,
  getApiAccountId,
  getRawUserId,
} from "@lumar/shared";
import { TFunction } from "i18next";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { GraphQLError } from "graphql";
import { useEffect, useRef } from "react";

export interface User {
  id: string;
  name: string;
  email: string;
  status: string | null;
  role: RoleCode;
  ssoEnabled: boolean;
}

export interface Roles {
  label: string;
  value: RoleCode;
}

export interface AccountUsers {
  users: User[];
  addNewUser: (email: string, role: RoleCode) => Promise<boolean>;
  updateUserRole: (id: string, role: RoleCode) => Promise<void>;
  deleteUser: (id: string) => Promise<void>;
  resendInvite: (userId: string) => Promise<void>;
  roles: Roles[];
  totalCount: number;
  maxUsers: number;
  error: ApolloError | undefined;
  loading: boolean;
}

export function useAccountUsers(): AccountUsers {
  const { t } = useTranslation("teamPage");
  const { accountId } = useParams<{ accountId: string }>();
  const { enqueueSnackbar } = useSnackbar();

  const {
    data,
    error,
    loading: queryLoading,
    fetchMore,
  } = useGetAccountUsersQuery({
    variables: { accountId },
    fetchPolicy: "cache-first",
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
  });
  const [addUser] = useAddAccountUserMutation();
  const [deleteAccountUser] = useDeleteAccountUserMutation();
  const [updateRole] = useUpdateUserRoleMutation();
  const [resendUserInvite] = useResendInviteLinkMutation();

  const pageInfo = data?.getAccount?.accountUsers?.pageInfo;
  const loading = Boolean(queryLoading || pageInfo?.hasNextPage);

  useEffect(() => {
    if (pageInfo?.hasNextPage) {
      fetchMore({ variables: { accountId, cursor: pageInfo.endCursor } });
    }
  }, [fetchMore, pageInfo, accountId]);

  const dataRef = useRef<GetAccountUsersQuery | undefined>(data);
  if (!pageInfo?.hasNextPage) {
    dataRef.current = data;
  }

  async function addNewUser(
    userEmail: string,
    roleCode: RoleCode,
  ): Promise<boolean> {
    try {
      await addUser({
        variables: {
          accountId,
          roleCode,
          userEmail,
        },
        refetchQueries: ["GetAccountUsers"],
        awaitRefetchQueries: true,
      });
      enqueueSnackbar(
        <Snackbar variant="success" title={t("alertMessages.userAdded")} />,
      );
      return true;
    } catch (error) {
      enqueueSnackbar(
        <Snackbar variant="error" title={(error as GraphQLError).message} />,
      );
      return false;
    }
  }

  async function updateUserRole(
    userId: string,
    roleCode: RoleCode,
  ): Promise<void> {
    try {
      await updateRole({
        variables: {
          accountId,
          roleCode,
          userId,
        },
        optimisticResponse: {
          updateAccountUser: {
            account: {
              id: accountId,
            },
          },
        },
        update: (cache) => addRoleToCache(cache, accountId, userId, roleCode),
      });
      enqueueSnackbar(
        <Snackbar variant="success" title={t("alertMessages.roleUpdated")} />,
      );
    } catch (error) {
      enqueueSnackbar(
        <Snackbar variant="error" title={(error as GraphQLError).message} />,
      );
    }
  }

  async function deleteUser(userId: string): Promise<void> {
    try {
      await deleteAccountUser({
        variables: {
          accountId: getApiAccountId(accountId),
          userId,
        },
        optimisticResponse: {
          deleteAccountUser: {
            account: {
              id: accountId,
            },
          },
        },
        update: (cache) => deleteUserFromCache(cache, accountId, userId),
      });
      enqueueSnackbar(
        <Snackbar variant="success" title={t("alertMessages.userDeleted")} />,
      );
    } catch (error) {
      enqueueSnackbar(
        <Snackbar variant="error" title={(error as GraphQLError).message} />,
      );
    }
  }

  async function resendInvite(userId: string): Promise<void> {
    try {
      await resendUserInvite({
        variables: {
          accountId,
          userId,
        },
      });
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t("alertMessages.invitationSent")}
        />,
      );
    } catch (error) {
      enqueueSnackbar(
        <Snackbar variant="error" title={(error as GraphQLError).message} />,
      );
    }
  }

  function getRoles(t: TFunction<"teamPage">): Roles[] {
    return [
      { label: t("roles.viewer"), value: RoleCode.Viewer },
      { label: t("roles.editor"), value: RoleCode.Editor },
      { label: t("roles.admin"), value: RoleCode.Admin },
    ];
  }

  return {
    users: getAccountUsers(dataRef.current),
    addNewUser: addNewUser,
    updateUserRole: updateUserRole,
    deleteUser: deleteUser,
    resendInvite: resendInvite,
    roles: getRoles(t),
    totalCount: data?.getAccount?.accountUsers?.totalCount ?? 0,
    maxUsers:
      data?.getAccount?.accountSettings?.find((s) => s.name === "Users")
        ?.limit ?? 0,
    error: error,
    loading: loading,
  };
}

function getAccountUsers(data: GetAccountUsersQuery | undefined): User[] {
  return (
    // eslint-disable-next-line fp/no-mutating-methods
    data?.getAccount?.accountUsers?.edges
      .map((u) => {
        return {
          id: u.node.userId ?? "",
          name: getUsername(u.node.userLastName, u.node.userFirstName),
          email: u.node.userEmail ?? "",
          status: u.node.userEmailValidatedAt ?? null,
          role: u.node.roleCode,
          ssoEnabled: Boolean(u.node.userSsoClientId),
        };
      })
      .sort((a, b) => a.email.localeCompare(b.email)) ?? []
  );
}

function getUsername(
  lastName: Maybe<string> | undefined,
  firtsName: Maybe<string> | undefined,
): string {
  if (!lastName && !firtsName) {
    return "-";
  } else {
    return `${firtsName} ${lastName}`;
  }
}

function addRoleToCache(
  cache: ApolloCache<UpdateUserRoleMutation>,
  accountId: string,
  userId: string,
  roleCode: RoleCode,
): void {
  const cachedData: GetAccountUsersQuery | null = cache.readQuery({
    query: GetAccountUsersDocument,
    variables: { accountId },
  });
  if (!cachedData?.getAccount?.accountUsers) return;
  cache.writeQuery({
    query: GetAccountUsersDocument,
    variables: { accountId },
    data: {
      ...cachedData,
      getAccount: {
        ...cachedData.getAccount,
        accountUsers: {
          ...cachedData.getAccount.accountUsers,
          edges: cachedData.getAccount.accountUsers.edges.map((edge) =>
            edge.node.userId === userId
              ? {
                  ...edge,
                  node: {
                    ...edge.node,
                    roleCode: roleCode,
                  },
                }
              : edge,
          ),
        },
      },
    },
  });
}

function deleteUserFromCache(
  cache: ApolloCache<DeleteAccountUserMutation>,
  accountId: string,
  userId: string,
): void {
  const cachedData: GetAccountUsersQuery | null = cache.readQuery({
    query: GetAccountUsersDocument,
    variables: { accountId },
  });
  if (!cachedData?.getAccount?.accountUsers) return;
  const edges = cachedData.getAccount.accountUsers.edges.filter(
    (edge) => getRawUserId(edge.node.userId) !== userId,
  );
  cache.writeQuery({
    query: GetAccountUsersDocument,
    variables: { accountId },
    data: {
      ...cachedData,
      getAccount: {
        ...cachedData.getAccount,
        accountUsers: {
          ...cachedData.getAccount.accountUsers,
          edges: edges,
          totalCount: edges.length,
        },
      },
    },
  });
}
