import { GetAccountQuery } from "../../graphql";
import { AccountFormValues } from "./types";

export function getFormValues(
  data: GetAccountQuery | undefined,
): AccountFormValues {
  return {
    proxy: data?.getAccount?.customProxy ?? "",
    country: data?.getAccount?.country ?? undefined,
    logo: data?.getAccount?.customLogo?.replace("/medium_", "/") ?? undefined,
    aiFeaturesEnabled: data?.getAccount?.aiFeaturesEnabled ?? false,
  };
}
