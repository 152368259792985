import {
  TextField,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import { GetCallbackQuery, UpdateAccountMutationFn } from "../graphql";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  code: {
    font: "Courier New",
    fontSize: 12,
    lineHeight: "14.4px",
    fontWeight: 400,
  },
  callbackTextField: {
    marginTop: theme.spacing(2),
  },
  textBlock: {
    whiteSpace: "pre-line",
    marginBottom: theme.spacing(3),
  },
  callbackErrorLabel: {
    marginTop: theme.spacing(1),
  },
}));

type Props = {
  data: GetCallbackQuery | undefined;
  updateAccount: UpdateAccountMutationFn;
  canEdit: boolean;
};

type FormValues = {
  url: string;
};

export function ApiCallbackUrlForm({
  data,
  updateAccount,
  canEdit,
}: Props): JSX.Element {
  const classes = useStyles();
  const context = useSession();
  const { t } = useTranslation("apiAccess");

  const callbackUrl = data?.getAccount?.apiCallbackUrl;

  const validationSchema = Yup.object({
    url: Yup.string().url(t("apiCallback.callbackValidationError")),
  });

  const { values, errors, touched, handleChange, handleSubmit } =
    useFormik<FormValues>({
      initialValues: { url: callbackUrl ?? "" },
      enableReinitialize: true,
      validationSchema,
      onSubmit: async (values) => {
        updateAccount({
          variables: {
            id: context.account.id,
            apiCallbackUrl: values.url.length > 0 ? values.url : null,
          },
        });
      },
    });

  return (
    <>
      <Typography variant="body2" component="div" className={classes.textBlock}>
        {t("apiCallback.descriptionPreCodeBlock")}
        <pre className={classes.code}>
          {`  {
    message: 'crawl_status',
    resource: 'crawl',
    crawl_id: 124234,
    project_id: 1123123,
    account_id: 123123,
    user_id: 123123,
    status: "finished",
    href: "/accounts/123123/projects/1123123/crawls/124234"
  }`}
        </pre>
        {t("apiCallback.descriptionPostCodeBlock")}
      </Typography>
      <Typography variant="body2">{t("apiCallback.step1")}</Typography>
      <TextField
        name="url"
        inputProps={{ "aria-label": t("apiCallback.step1") }}
        className={classes.callbackTextField}
        placeholder="e.g. http://www.example.com?id=deepcrawl"
        value={values.url}
        onChange={handleChange}
        disabled={!canEdit}
        error={touched.url && !!errors.url}
        onBlur={() => {
          handleSubmit();
        }}
      />
      {touched.url && errors.url ? (
        <Typography
          variant="caption"
          color="error"
          className={classes.callbackErrorLabel}
        >
          {errors.url}
        </Typography>
      ) : null}
    </>
  );
}
