import { useTranslation } from "@lumar/shared";
import { AddonIntegration } from "./types";

export function useDefaultIntegrations(): AddonIntegration[] {
  const { t } = useTranslation("subscription");
  return [
    {
      code: "adobe-analytics",
      name: t("integrations.adobeAnalytics.name"),
      description: t("integrations.adobeAnalytics.description"),
      included: false,
      submitCode: "adobe_analytics_requested",
    },
    {
      code: "google-analytics",
      name: t("integrations.googleAnalytics.name"),
      description: t("integrations.googleAnalytics.description"),
      included: false,
      submitCode: "google_analytics_requested",
    },
    {
      code: "google-datastudio",
      name: t("integrations.googleDataStudio.name"),
      description: t("integrations.googleDataStudio.description"),
      included: false,
      submitCode: "google_data_studio_requested",
    },
    {
      code: "google-search-console",
      name: t("integrations.googleSearchConsole.name"),
      description: t("integrations.googleSearchConsole.description"),
      included: false,
      submitCode: "google_search_console_requested",
    },
    {
      code: "logzio",
      name: t("integrations.logzio.name"),
      description: t("integrations.logzio.description"),
      included: false,
      submitCode: "logz_io_requested",
    },
    {
      code: "splunk",
      name: t("integrations.splunk.name"),
      description: t("integrations.splunk.description"),
      included: false,
      submitCode: "splunk_api_requested",
    },
  ];
}
