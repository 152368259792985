import { Typography } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";

const useStyles = makeStyles((theme) => ({
  cell: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  value: {
    color: theme.palette.grey[700],
  },
}));

export function CrawlFinishedCell(params: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const isCrawlFinishedEmpty = params.value === "";

  return (
    <div className={classes.cell}>
      <Typography variant="body2" className={classes.value}>
        {isCrawlFinishedEmpty ? "-" : params.value}
      </Typography>
    </div>
  );
}
