import { useAuth, useBrand, useSession } from "@lumar/shared";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { Routes } from "../_common/routing/routes";
import { PermissionCode } from "../graphql";

/**
 * We need to have access to the admin panel for our Lumar SSO accounts
 * during development and staging to be able to test the admin panel.
 *
 * Since we don't have Similarweb Admin accounts, we need to fake it
 * using our own Lumar SSO accounts and setting up some Similarweb-like
 * configurations, for that reason we need to be able to enter the admin
 * panel during development and staging to behave like a Similarweb Admin
 * but this behaviour should be disabled in production for Lumar accounts
 * so they are enforced to use Duncan instead.
 */
const ACCOUNTS_APP_DEV_CLIENT_ID = "4v6vzo7R0XMLcJ7LRoRaYjjU52xz30jq";
const LUMAR_SSO_CLIENT_ID = "lumar-onelogin";

export const useAdminGuard = (): void => {
  const auth = useAuth();
  const session = useSession();
  const history = useHistory();
  const brand = useBrand();

  useEffect(() => {
    if (
      !session.hasPermission(PermissionCode.SsoClient) ||
      !brand.featureAvailability.adminPanel
    ) {
      if (
        session.ssoClientId === LUMAR_SSO_CLIENT_ID &&
        auth.clientId === ACCOUNTS_APP_DEV_CLIENT_ID
      ) {
        return;
      }

      history.replace(Routes.Home.ROUTE);
    }
  }, [session, brand.featureAvailability.adminPanel, history, auth.clientId]);
};
