import { Dialog, Snackbar, TextField, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { useCreateSsoAccountMutation } from "../../../graphql";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CreateAccountDialog = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("adminPanel");

  const validationSchema = Yup.object({
    name: Yup.string().trim().required(t("createAccount.errors.mandatory")),
    ssoClientAccountId: Yup.number().required(
      t("createAccount.errors.mandatory"),
    ),
  });

  const onCloseDialog = (): void => {
    formik.resetForm();
    props.onClose();
  };

  const [createAccount] = useCreateSsoAccountMutation({
    onCompleted: () => {
      enqueueSnackbar(
        <Snackbar variant="success" title={t("createAccount.success")} />,
      );
      onCloseDialog();
    },
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
    refetchQueries: ["GetAccounts"],
  });

  const formik = useFormik({
    initialValues: { name: "", ssoClientAccountId: 0 },
    onSubmit: async (values: Yup.InferType<typeof validationSchema>) => {
      await createAccount({
        variables: {
          name: values.name,
          ssoClientAccountId: values.ssoClientAccountId,
        },
      });
    },
    validateOnChange: true,
    validationSchema,
  });

  return (
    <Dialog
      open={props.open}
      onCloseViaIcon={onCloseDialog}
      title={t("createAccount.title")}
      buttons={[
        {
          variant: "outlined",
          color: "default",
          title: t("createAccount.cancel"),
          onClick: onCloseDialog,
        },
        {
          disabled: !formik.isValid || formik.isSubmitting,
          variant: "contained",
          color: "primary",
          title: t("createAccount.create"),
          onClick: () => formik.handleSubmit(),
        },
      ]}
    >
      <div className={classes.container}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            className={classes.input}
            value={formik.values.name}
            label={t("createAccount.name")}
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            type="number"
            className={classes.input}
            value={formik.values.ssoClientAccountId}
            label={t("createAccount.ssoClientAccountId")}
            name="ssoClientAccountId"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </form>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 12,
  },
  input: {
    width: "auto",
  },
  form: {
    minWidth: 240,
    display: "grid",
    gridGap: 16,
  },
}));
