import {
  GetAccountQuery,
  UpdateAccountSettingsMutationVariables,
} from "../../graphql";
import { AccountFormValues } from "./types";
import { isString } from "lodash";

export function getMutationData(
  data: GetAccountQuery | undefined,
  accountId: string,
  values: AccountFormValues,
): UpdateAccountSettingsMutationVariables {
  function getCustomLogo(): File | null | undefined {
    if (isString(values.logo)) return undefined;

    const hasLogo = Boolean(data?.getAccount?.customLogo);
    if (values.logo === undefined && hasLogo) return null;

    return values.logo;
  }

  return {
    input: {
      accountId,
      customProxy: getNullableString(values.proxy),
      country: values.country,
      customLogo: getCustomLogo(),
      aiFeaturesEnabled: values.aiFeaturesEnabled,
    },
  };
}

function getNullableString(value: string): string | null {
  return value.trim().length > 0 ? value : null;
}
