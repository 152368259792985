import { Typography } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";

const useStyles = makeStyles((theme) => ({
  cell: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  id: {
    color: theme.palette.grey[700],
  },
}));

export function CrawlIdCell(params: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const isCrawlIdEmpty = params.value === "";

  return (
    <div className={classes.cell}>
      <Typography
        variant="subtitle2SemiBold"
        className={classes.id}
        data-testid="crawl-id-cell"
      >
        {isCrawlIdEmpty ? "-" : params.value}
      </Typography>
    </div>
  );
}
