import {
  Snackbar,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { Divider, makeStyles } from "@material-ui/core";

import { useSnackbar } from "notistack";
import {
  GetCallbackDocument,
  RoleCode,
  useGetCallbackQuery,
  useUpdateAccountMutation,
} from "../graphql";
import { ApiCallbackHeadersForm } from "./ApiCallbackHeadersForm";
import { ApiCallbackUrlForm } from "./ApiCallbackUrlForm";

const useStyles = makeStyles((theme) => ({
  divider: { marginTop: theme.spacing(1), marginBottom: theme.spacing(3) },
}));

export const ApiCallback = (): JSX.Element => {
  const { hasSufficientRole } = useSession();
  const hasEditingPermission = hasSufficientRole(RoleCode.Admin);

  const { t } = useTranslation("apiAccess");
  const classes = useStyles();
  const context = useSession();
  const { enqueueSnackbar } = useSnackbar();

  const { data } = useGetCallbackQuery({
    variables: { accountId: context.account.id },
  });

  const [updateAccount] = useUpdateAccountMutation({
    refetchQueries: [GetCallbackDocument],
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
  });

  return (
    <>
      <Typography variant="h6SemiBold">{t("apiCallback.title")}</Typography>
      <Divider orientation="horizontal" className={classes.divider} />
      <ApiCallbackUrlForm
        updateAccount={updateAccount}
        data={data}
        canEdit={hasEditingPermission}
      />
      <ApiCallbackHeadersForm
        updateAccount={updateAccount}
        data={data}
        canEdit={hasEditingPermission}
      />
    </>
  );
};
