import React from "react";
import {
  useTranslation,
  useSession,
  Alert,
  Button,
  DestructiveMenuActionConfirmation,
  PencilSolid,
  PlusSolid,
  ToggleIconButton,
  TrashSolid,
  Typography,
} from "@lumar/shared";
import { makeStyles, SvgIcon } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { isString } from "lodash";

import { Routes } from "../_common/routing/routes";

const useStyles = makeStyles((theme) => ({
  connectedApp: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.grey[200],
    borderRadius: theme.spacing(1),
    background: theme.palette.background.paper,
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06)",
    marginBottom: theme.spacing(3),
  },
  header: {
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(3.25, 3.625, 1.375, 3.625),
  },
  logo: {
    background: theme.palette.background.paper,
    width: 60,
    height: 60,
    borderRadius: "50%",
    boxShadow:
      "0px 2px 3px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.18)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(1.125),
  },
  content: {
    padding: theme.spacing(0, 3.625, 3, 3.625),
  },
  divider: {
    background: theme.palette.grey[200],
    height: 1,
    marginTop: theme.spacing(1.5),
  },
  noAccountMessage: {
    marginTop: theme.spacing(0.75),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    marginTop: theme.spacing(2.25),
  },
  row: {
    verticalAlign: "top",
    lineHeight: theme.typography.pxToRem(32),
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.grey[200],
    "& td": {
      padding: theme.spacing(1.25, 1, 1, 0),
      overflow: "hidden",
    },
    "& td:last-child": {
      paddingRight: theme.spacing(0),
    },
  },
  statusValid: {
    color: theme.palette.green[700],
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 700,
  },
  statusInvalid: {
    color: theme.palette.rose[700],
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 700,
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

type ConnectedAppsContainerProps<T> = {
  logo: string;
  logoAlt: string;
  title: string;
  noAccountMessage?: string;
  addon?: string | true;
  onRequest?: () => void;
  requestIcon?: typeof SvgIcon;
  addonMessage?: string;
  addonLabel?: string;
  buttonLabel?: string;
  onClick?: () => void;
  accounts?: T[];
  renderRow?: (accounts: T) => React.ReactNode;
};

export function ConnectedAppsContainer<T extends { id: string }>({
  logo,
  logoAlt,
  title,
  noAccountMessage,
  addon,
  onRequest,
  requestIcon: RequestIcon,
  addonMessage,
  addonLabel,
  buttonLabel,
  onClick,
  accounts,
  renderRow,
  ...props
}: ConnectedAppsContainerProps<T>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");
  const history = useHistory();

  const { accountId } = useParams<{ accountId: string }>();

  const { hasAddon } = useSession();

  const requestAddon =
    addon !== undefined && (addon === true || !hasAddon(addon));

  return (
    <div className={classes.connectedApp} {...props}>
      <div className={classes.header}>
        <div className={classes.logo}>
          <img src={logo} alt={logoAlt} />
        </div>
        <Typography variant="h2SemiBold">{title}</Typography>
      </div>
      <div className={classes.content}>
        {requestAddon ? (
          <>
            <Typography variant="captionMedium">{addonMessage}</Typography>
            <div className={classes.divider} />
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  onRequest
                    ? onRequest()
                    : isString(addon) &&
                      history.push(
                        Routes.Subscription.getUrl({
                          accountId,
                          requestAddon: addon,
                        }),
                      )
                }
                startIcon={RequestIcon && <RequestIcon />}
                data-testid="connected-apps-request"
              >
                {addonLabel ?? t("requestAddon")}
              </Button>
            </div>
          </>
        ) : (
          <>
            {accounts?.length === 0 ? (
              <Alert
                severity="info"
                className={classes.noAccountMessage}
                data-testid="no-account-message"
              >
                {noAccountMessage}
              </Alert>
            ) : (
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  tableLayout: "fixed",
                  marginTop: 8,
                }}
              >
                <tbody>
                  {accounts?.map((account) => (
                    <React.Fragment key={account.id}>
                      <tr
                        className={classes.row}
                        data-testid="connected-apps-account"
                      >
                        {renderRow?.(account)}
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            )}
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={onClick}
                startIcon={<PlusSolid />}
                data-testid="connected-apps-add-account"
              >
                {buttonLabel}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export function Cell({
  children,
  width,
  colSpan,
}: {
  children: React.ReactNode;
  width?: string;
  colSpan?: number;
}): JSX.Element {
  return (
    <td
      colSpan={colSpan}
      style={{ width: width ?? "auto", textOverflow: "ellipsis" }}
    >
      {children}
    </td>
  );
}

export function AccountStatus({ isValid }: { isValid: boolean }): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");

  return (
    <Typography
      className={isValid ? classes.statusValid : classes.statusInvalid}
    >
      {isValid ? t("valid") : t("invalid")}
    </Typography>
  );
}

export function EditButton({
  onEdit = () => {
    return;
  },
  disabled,
}: {
  onEdit?: () => void;
  disabled?: boolean;
}): JSX.Element {
  return (
    <ToggleIconButton
      onClick={onEdit}
      disabled={disabled}
      size="medium"
      variant="outlined"
      aria-label="edit account"
      data-testid="edit-account-button"
    >
      <PencilSolid />
    </ToggleIconButton>
  );
}

export function RemoveButton({
  title,
  description,
  onRemove,
}: {
  title: string;
  description: string;
  onRemove: () => void;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");

  const ref = React.useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <ToggleIconButton
        onClick={() => setOpen(true)}
        size="medium"
        variant="outlined"
        colorVariant="red"
        className={classes.button}
        ref={ref}
        data-testid="remove-account-button"
        aria-label="Remove account"
      >
        <TrashSolid />
      </ToggleIconButton>
      {open && (
        <DestructiveMenuActionConfirmation
          title={title}
          description={description}
          cancelText={t("cancelButton")}
          confirmText={t("confirmButton")}
          onConfirm={() => {
            onRemove();
            setOpen(false);
          }}
          onCancel={() => setOpen(false)}
          anchorEl={ref.current}
        />
      )}
    </>
  );
}
