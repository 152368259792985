import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Form, FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { FilterOrRuleFieldArray, FilterOrRuleFormValue } from "@lumar/shared";
import { useCreditFilters } from "./useCreditFilters";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: 10,
    marginLeft: -9,
  },
  actions: {
    display: "flex",
    justifyContent: "flex-start",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    padding: theme.spacing(2, 2, 0, 2),
    marginLeft: -theme.spacing(2),
    marginRight: -theme.spacing(2),
  },
  clearBtn: {
    color: theme.palette.primary.main,
  },
  submitButton: {
    minWidth: 71,
    marginLeft: theme.spacing(2),
  },
  cancelBtn: {
    minWidth: 79,
    marginLeft: "auto",
  },
}));

export interface FilterFormValues {
  filters: FilterOrRuleFormValue[];
}

type FormProps = FormikProps<FilterFormValues>;
interface Props {
  values: FilterFormValues;
  dirty: FormProps["dirty"];
  isValid: FormProps["isValid"];
  setFieldValue: FormProps["setFieldValue"];
  setFieldTouched: FormProps["setFieldTouched"];
  handleSubmit: FormProps["handleSubmit"];
  resetForm: FormProps["resetForm"];
  resetFilters: () => void;
  setLocalFilterAnchor: (
    value: React.SetStateAction<HTMLButtonElement | null>,
  ) => void;
}

export function CreditFilter(props: Props): JSX.Element {
  const {
    values,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    resetForm,
    resetFilters,
    setLocalFilterAnchor,
  } = props;

  const creditFilters = useCreditFilters();
  const classes = useStyles();
  const { t } = useTranslation("creditUsage");
  const hasEmptyPredicateValue = Boolean(
    values.filters.find((f) => f._and.find((v) => !v.predicateValue)),
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Paper className={classes.paper}>
        <Box p={2}>
          <Box pb={2}>
            <FilterOrRuleFieldArray
              name="filters"
              metrics={creditFilters}
              value={values.filters}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              allowDeletingLastEmptyRule={true}
            />
          </Box>
          <Box className={classes.actions}>
            <Button
              variant="text"
              onClick={() => {
                resetForm();
                resetFilters();
              }}
              className={classes.clearBtn}
            >
              {t("filters.clear")}
            </Button>
            <Button
              onClick={() => {
                resetForm();
                setLocalFilterAnchor(null);
              }}
              type="button"
              variant="outlined"
              color="default"
              data-testid="credit-usage-filter-cancel-button"
              classes={{ root: classes.cancelBtn }}
            >
              {t("filters.cancel")}
            </Button>
            <Button
              disabled={
                !props.dirty || !props.isValid || hasEmptyPredicateValue
              }
              type="submit"
              variant="contained"
              color="primary"
              data-testid="credit-usage-filter-apply-button"
              classes={{ root: classes.submitButton }}
            >
              {t("filters.applyFilter")}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Form>
  );
}
