import { assert } from "../helpers";
import { MetricType } from "../../graphql";
import { formatDate } from "./temporary-dependencies/locale/format-date/formatDate";
import { v4 as uuid } from "uuid";
export function getPredicateType(metrics, metricCode, predicateCode) {
    var _a, _b;
    return (_b = (_a = metrics
        .find((m) => m.code === metricCode)) === null || _a === void 0 ? void 0 : _a.connectionPredicates.find((predicate) => predicate.code === predicateCode)) === null || _b === void 0 ? void 0 : _b.type;
}
export function getMetricPredicates(metrics, metricCode) {
    var _a;
    return ((_a = metrics.find((m) => m.code === metricCode)) === null || _a === void 0 ? void 0 : _a.connectionPredicates) || [];
}
export function getDefaultPredicateValue(predicateType) {
    switch (predicateType) {
        case MetricType.Boolean:
        case MetricType.Bit:
            return "true";
        case MetricType.Date:
            return new Date();
        default:
            return "";
    }
}
export function getDefaultFormFilter(metrics) {
    const defaultMetric = metrics.find((metric) => metric.code === "url") || metrics[0];
    const defaultPredicate = (defaultMetric === null || defaultMetric === void 0 ? void 0 : defaultMetric.connectionPredicates.find((predicate) => predicate.code === "contains")) || (defaultMetric === null || defaultMetric === void 0 ? void 0 : defaultMetric.connectionPredicates[0]);
    return {
        id: uuid(),
        metricCode: defaultMetric === null || defaultMetric === void 0 ? void 0 : defaultMetric.code,
        predicateKey: defaultPredicate === null || defaultPredicate === void 0 ? void 0 : defaultPredicate.code,
        predicateValue: getDefaultPredicateValue(defaultPredicate.type),
    };
}
export function getDefaultFormOrFilter(metrics) {
    return {
        id: uuid(),
        _and: [getDefaultFormFilter(metrics)],
    };
}
export function mapFilterRuleFormValuesToNames(metrics, metricCode, predicateCode, predicateValue) {
    const metric = metrics.find((metric) => metric.code === metricCode);
    assert(metric);
    const metricName = metric.name;
    const predicate = metric.connectionPredicates.find((predicate) => predicate.code === predicateCode);
    assert(predicate);
    const predicateName = predicate.name;
    const predicateType = getPredicateType(metrics, metricCode, predicateCode);
    assert(predicateType);
    const formattedPredicateValue = predicateType === MetricType.Date
        ? formatDate(predicateValue instanceof Date
            ? predicateValue
            : new Date(String(predicateValue)))
        : String(predicateValue);
    return {
        metricName,
        predicateName,
        predicateValue: formattedPredicateValue,
    };
}
