import { isNonEmpty } from "@lumar/shared";
import { GetUserSettingsDataQuery } from "../graphql";
import { SettingsFormValues } from "./types";

export function getFormValues(
  data: GetUserSettingsDataQuery | undefined,
  isDeepCrawlAdminEnabled: boolean | undefined,
): SettingsFormValues {
  const user = isNonEmpty(data?.node) ? data?.node : undefined;
  return {
    username: user?.username ?? "",
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    email: user?.email ?? "",
    jobTitle: user?.jobTitle ?? "",
    adminMode: isDeepCrawlAdminEnabled || false,
  };
}
