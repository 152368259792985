import { Typography, useBrand, useTranslation } from "@lumar/shared";
import { AccountSubscriptionQuery } from "../graphql";
import { ActionPanel } from "./components/ActionPanel";
import {
  LimitsList,
  AddonIntegrationList,
  ServicesList,
} from "./components/FeatureLists";
import { SubscriptionViewContent } from "./components/SubscriptionViewContent";
import { useSubscriptionData } from "./subscription-data/useSubscriptionData";
import { SubscriptionEditProvider } from "./subscription-edit/SubscriptionEditContext";
import { CreditTypeSubscriptionSection } from "./components/CreditTypeSubscriptionSection";
import { SubscriptionSection } from "./components/SubscriptionSection";

interface Props {
  account: NonNullable<NonNullable<AccountSubscriptionQuery["getAccount"]>>;
}

export function AccountSubscription({ account }: Props): JSX.Element {
  const { t } = useTranslation("subscription");
  const brand = useBrand();
  const { name, credits, addons, integrations, limits, services, apps } =
    useSubscriptionData(account);

  return (
    <SubscriptionEditProvider account={account}>
      <>
        <SubscriptionViewContent
          title={t("subscriptionTitle")}
          description={
            brand.featureAvailability.editSubscription ? t("description") : ""
          }
        >
          {account.contractTerminationDate && account.contractStartDate ? (
            <div style={{ marginBottom: 18 }}>
              <SubscriptionSection
                headContent={
                  <>
                    <div>
                      <Typography variant="subtitle2Bold">
                        {t("contract")}
                      </Typography>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                      <Typography variant="subtitle2Bold">
                        {t("contractFrom")}{" "}
                        {account.contractStartDate.split("T")[0]}{" "}
                        {t("contractTo")}{" "}
                        {account.contractTerminationDate.split("T")[0]}
                      </Typography>
                    </div>
                  </>
                }
              />
            </div>
          ) : null}
          {credits.map((credit) => {
            return (
              <div style={{ marginBottom: 18 }} key={credit.code}>
                <CreditTypeSubscriptionSection {...credit} />
              </div>
            );
          })}
          <SubscriptionSection
            headContent={
              <>
                <div>
                  <Typography variant="subtitle2Bold">
                    {t("subscriptionDetails")}
                  </Typography>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  {brand.featureAvailability.showSubscriptionPackageName ? (
                    <Typography variant="subtitle2Bold">{name}</Typography>
                  ) : null}
                </div>
              </>
            }
            mainContent={
              <>
                <LimitsList limits={limits} />
                <AddonIntegrationList addonIntegrations={apps} type="app" />
                <AddonIntegrationList
                  addonIntegrations={integrations}
                  type="integration"
                />
                <AddonIntegrationList addonIntegrations={addons} type="addon" />
                {brand.featureAvailability.subscriptionSupportSection ? (
                  <ServicesList services={services} />
                ) : null}
              </>
            }
          />
        </SubscriptionViewContent>
        <ActionPanel />
      </>
    </SubscriptionEditProvider>
  );
}
