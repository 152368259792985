import {
  DotsHorizontalOutlined,
  ToggleIconButton,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { Menu, MenuItem } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { CreditAllocationDefaultValues } from "../update-credit-allocation/UpdateCreditAllocationDialog";

export function ActionsCell(
  props: GridRenderCellParams & {
    onUpdateCreditAllocation: (
      creditAllocationDefaultValues: CreditAllocationDefaultValues,
    ) => void;
  },
): JSX.Element {
  const { t } = useTranslation("adminPanel");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <ToggleIconButton
        size="large"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        aria-label="open credit allocation actions menu"
      >
        <DotsHorizontalOutlined />
      </ToggleIconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            props.onUpdateCreditAllocation({
              id: props.row.id,
              startDate: props.row.startDate,
              endDate: props.row.endDate,
              creditsAllocated: props.row.creditsAllocated,
              creditsUsed: props.row.creditsUsed,
            });
          }}
        >
          <Typography variant="body2">
            {t("updateCreditAllocation.title")}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
