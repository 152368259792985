import { Snackbar, ApolloCache } from "@lumar/shared";
import { TFunction } from "i18next";
import { SnackbarProvider } from "notistack";
import {
  AddLogzioConnectionMutation,
  GetConnectedAppsDocument,
  GetConnectedAppsQuery,
  RemoveLogzioConnectionMutation,
  useAddLogzioConnectionMutation,
  useRemoveLogzioConnectionMutation,
  useUpdateLogzioConnectionMutation,
} from "../../graphql";
import { ConnectedApps, LogzioAccount } from "./types";

type LogzioAccounts = Pick<
  ConnectedApps,
  | "logzioAccounts"
  | "addLogzioAccount"
  | "updateLogzioAccount"
  | "removeLogzioAccount"
>;

export function useLogzioAccounts(
  data: GetConnectedAppsQuery | undefined,
  enqueueSnackbar: SnackbarProvider["enqueueSnackbar"],
  t: TFunction<"connectedApps">,
): LogzioAccounts {
  const [addLogzioConnection] = useAddLogzioConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("logzio.errorAdd")} />,
      ),
  });

  const [updateLogzioConnection] = useUpdateLogzioConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("logzio.errorUpdate")} />,
      ),
  });

  const [removeLogzioConnection] = useRemoveLogzioConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("logzio.errorRemove")} />,
      ),
  });

  async function addLogzioAccount(
    label: string,
    token: string,
  ): Promise<boolean> {
    const response = await addLogzioConnection({
      variables: {
        label,
        token,
      },
      update: (cache, { data }) => addLogzioConnectionToCache(cache, data),
    });

    return Boolean(response.data?.createLogzioConnection.logzioConnection.id);
  }

  async function updateLogzioAccount(
    logzioConnectionId: string,
    label: string,
    token: string,
  ): Promise<boolean> {
    const response = await updateLogzioConnection({
      variables: {
        logzioConnectionId,
        label,
        token,
      },
    });

    return Boolean(response.data?.updateLogzioConnection.logzioConnection.id);
  }

  async function removeLogzioAccount(
    logzioConnectionId: string,
  ): Promise<boolean> {
    const response = await removeLogzioConnection({
      variables: { logzioConnectionId },
      optimisticResponse: {
        deleteLogzioConnection: {
          logzioConnection: { id: logzioConnectionId },
        },
      },
      update: (cache, { data }) => removeLogzioConnectionFromCache(cache, data),
    });

    return Boolean(response.data?.deleteLogzioConnection.logzioConnection.id);
  }

  return {
    logzioAccounts: getLogzioConnections(data),
    addLogzioAccount,
    updateLogzioAccount,
    removeLogzioAccount,
  };
}

export function getLogzioConnections(
  data: GetConnectedAppsQuery | undefined,
): LogzioAccount[] {
  return (
    data?.me?.logzioConnections?.nodes.map((x) => ({
      id: x.id,
      label: x.label ?? "",
      token: x.token,
      isValid: x.isWorking,
    })) ?? []
  );
}

function addLogzioConnectionToCache(
  cache: ApolloCache<AddLogzioConnectionMutation>,
  data: AddLogzioConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        logzioConnections: {
          ...cachedData.me.logzioConnections,
          nodes: [
            ...cachedData.me.logzioConnections.nodes,
            data?.createLogzioConnection.logzioConnection,
          ],
        },
      },
    },
  });
}

function removeLogzioConnectionFromCache(
  cache: ApolloCache<AddLogzioConnectionMutation>,
  data: RemoveLogzioConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  const removedId = data?.deleteLogzioConnection.logzioConnection.id;
  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        logzioConnections: {
          ...cachedData.me.logzioConnections,
          nodes: cachedData.me.logzioConnections.nodes.filter(
            (x) => x.id !== removedId,
          ),
        },
      },
    },
  });
}
