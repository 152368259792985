import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { getDefaultFormFilter, getMetricPredicates, getPredicateType, } from "../helpers";
import clsx from "clsx";
import { FilterMetricSelect } from "./FilterMetricSelect";
import { Field } from "formik";
import { FilterPredicateInput } from "./FilterPredicateInput";
import arePropsEqual from "react-fast-compare";
import { ComboBox } from "../../combo-box/ComboBox";
import { Button } from "../../buttons/Button";
import { PlusCircleSolid } from "../../../icons/ui-friendly/solid/PlusCircleSolid";
import { TrashSolid } from "../../../icons/ui-friendly/solid/TrashSolid";
import { useTranslation } from "../../../i18n";
const useStyles = makeStyles((theme) => ({
    row: {
        display: "flex",
        marginBottom: theme.spacing(2),
    },
    lastRow: {
        marginBottom: 0,
    },
    select: {
        width: 230,
        minWidth: 230,
    },
    addButton: {
        textTransform: "uppercase",
        paddingTop: theme.spacing(1.1875),
        paddingBottom: theme.spacing(1.1875),
    },
    deleteIconButton: {
        minWidth: "auto",
        padding: theme.spacing(1.1875),
    },
    deleteIcon: {
        fontSize: theme.typography.pxToRem(16),
    },
}));
function FilterRuleFieldInner(props) {
    const styles = useStyles();
    const { t } = useTranslation("connectionFiltering");
    const hasEmptyPredicateValue = !props.filter.predicateValue;
    const canDeleteRule = !props.doesOnlyOneFilterExist ||
        !hasEmptyPredicateValue ||
        props.allowDeletingLastEmptyRule;
    const predicateName = `${props.name}.predicateKey`;
    const predicateValue = `${props.name}.predicateValue`;
    const predicates = getMetricPredicates(props.metrics, props.filter.metricCode);
    return (_jsxs(Box, { className: clsx(styles.row, props.isLast ? styles.lastRow : undefined), "data-testid": "filter-rule-field", children: [_jsx(Box, { mr: 2, children: _jsx(FilterMetricSelect, { name: `${props.name}.metricCode`, predicateValue: predicateValue, predicateName: predicateName, filter: props.filter, metrics: props.metrics, setFieldValue: props.setFieldValue, "data-pendo": props.pendoPrefix
                        ? `${props.pendoPrefix}-filter-metric-select`
                        : undefined }) }), _jsx(Box, { mr: 2, children: _jsx(Field, { name: predicateName, children: ({ field: { value, name }, form: { setFieldValue, isSubmitting }, }) => (_jsx(ComboBox, { value: predicates.find((x) => x.code === value), onChange: (_, value) => {
                            setFieldValue(name, value.code);
                        }, options: predicates, getOptionLabel: (option) => option.name, getOptionSelected: (option, selected) => option.code === (selected === null || selected === void 0 ? void 0 : selected.code), renderOption: (option) => (_jsx("div", { "data-testid": getFilterPredicateOptionTestId(option.code), children: option.name })), disableClearable: true, disabled: isSubmitting, className: styles.select, inputProps: {
                            inputProps: {
                                "data-testid": "filter-rule-predicate-type-select",
                                "aria-label": "select predicate",
                            },
                        }, "data-testid": "filter-rule-predicate-combobox", "data-pendo": props.pendoPrefix
                            ? `${props.pendoPrefix}-filter-predicate-select`
                            : undefined })) }) }), _jsx(Box, { mr: 2, children: _jsx(FilterPredicateInput, { name: predicateValue, setFieldValue: props.setFieldValue, type: getPredicateType(props.metrics, props.filter.metricCode, props.filter.predicateKey), "data-pendo": props.pendoPrefix
                        ? `${props.pendoPrefix}-filter-value-input`
                        : undefined }) }), _jsx(Box, { mr: 2, children: _jsx(Button
                // eslint-disable-next-line fp/no-mutating-methods
                , { 
                    // eslint-disable-next-line fp/no-mutating-methods
                    onClick: () => props.push(getDefaultFormFilter(props.metrics)), variant: "outlined", size: "small", startIcon: _jsx(PlusCircleSolid, {}), classes: { root: styles.addButton }, "data-testid": "add-filter", "data-pendo": props.pendoPrefix ? `${props.pendoPrefix}-filter-and` : undefined, children: t("andStatement") }) }), _jsx(Box, { children: _jsx(Button, { onClick: () => {
                        if (props.doesOnlyOneFilterExist) {
                            props.replace(0, getDefaultFormFilter(props.metrics));
                            props.setFieldTouched(predicateValue, false);
                            if (props.onLastRemainingRuleDeleted) {
                                props.onLastRemainingRuleDeleted();
                            }
                        }
                        else {
                            props.remove(props.index);
                        }
                    }, disabled: !canDeleteRule, variant: "outlined", size: "small", classes: { root: styles.deleteIconButton }, "aria-label": "Delete", "data-testid": "remove-filter", "data-pendo": props.pendoPrefix ? `${props.pendoPrefix}-filter-remove` : undefined, children: _jsx(TrashSolid, { className: styles.deleteIcon }) }) })] }));
}
export function getFilterPredicateOptionTestId(predicateCode) {
    return `filter-predicate-option-${predicateCode}`;
}
export const FilterRuleField = memo(FilterRuleFieldInner, arePropsEqual);
