import { useDateFormatter } from "@lumar/shared";

type Props = {
  value: string;
};

export const DateCell = (props: Props): JSX.Element => {
  const dateFormatter = useDateFormatter();

  return (
    <div>
      {dateFormatter(new Date(props.value), {
        dateStyle: "long",
      })}
    </div>
  );
};
