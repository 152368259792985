import React from "react";
import { useTranslation, useSession, useBrand } from "@lumar/shared";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { CircularProgress, makeStyles } from "@material-ui/core";

import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { useConnectedApps } from "./data/useConnectedApps";
import { ConnectedApps as ConnectedAppsType } from "./data/types";
import { GoogleAccounts } from "./GoogleAccounts";
import { LogzioAccounts } from "./LogzioAccounts";
import { SplunkAccounts } from "./SplunkAccounts";
import { AdobeAccounts } from "./AdobeAccounts";
import { Zapier } from "./Zapier";
import { ZapierTemplates } from "./ZapierTemplates";
import { useURLSearchParams } from "../_common/routing/useURLSearchParams";
import { RoleCode } from "../graphql";
import { Routes } from "../_common/routing/routes";

export const ConnectedAppsContext = React.createContext<ConnectedAppsType>({
  loading: false,
  googleAccounts: [],
  addGoogleAccount: () => Promise.resolve(false),
  removeGoogleAccount: () => Promise.resolve(false),
  logzioAccounts: [],
  addLogzioAccount: () => Promise.resolve(false),
  updateLogzioAccount: () => Promise.resolve(false),
  removeLogzioAccount: () => Promise.resolve(false),
  splunkAccounts: [],
  addSplunkAccount: () => Promise.resolve(false),
  updateSplunkAccount: () => Promise.resolve(false),
  removeSplunkAccount: () => Promise.resolve(false),
  adobeAccounts: [],
  addAdobeAccount: () => Promise.resolve(false),
  updateAdobeAccount: () => Promise.resolve(false),
  removeAdobeAccount: () => Promise.resolve(false), // FIXME: Remove after deprecated adobe connections are removed from the api.
  deleteAdobeAccount: () => Promise.resolve(false),
});

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 460,
    margin: theme.spacing(0, 18.5),
    [theme.breakpoints.down(1440)]: {
      margin: 0,
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: 720,
    },
  },
  fisrtColumn: {
    marginRight: theme.spacing(3),
    width: "50%",
  },
  secondColumn: {
    width: "50%",
  },
}));

export function ConnectedApps(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");
  const brand = useBrand();

  const params = useURLSearchParams();
  const history = useHistory();

  const showZapierTemplates = params.get("view") === "zapierTemplates";

  const connectedApps = useConnectedApps();

  const session = useSession();
  const { accountId } = useParams<{ accountId: string }>();

  if (!session.hasSufficientRole(RoleCode.Editor)) {
    return <Redirect exact to={Routes.UserSettings.getUrl({ accountId })} />;
  }

  function getTitleProps(): { title: string; onBackButtonClick?: () => void } {
    if (showZapierTemplates)
      return {
        title: t("zapier.pageTitle"),
        onBackButtonClick: () => {
          params.delete("view");
          history.push({ search: params.toString() });
        },
      };

    return {
      title: t("title"),
    };
  }

  function getContent(): React.ReactNode {
    if (showZapierTemplates) return <ZapierTemplates />;

    if (connectedApps.loading)
      return <CircularProgress aria-label="Loading connected apps" />;

    return (
      <div className={classes.root}>
        <GoogleAccounts />
        <LogzioAccounts />
        <SplunkAccounts />
        <AdobeAccounts />
        {brand.featureAvailability.zapier ? <Zapier /> : null}
      </div>
    );
  }

  return (
    <ConnectedAppsContext.Provider value={connectedApps}>
      <TopNavigation {...getTitleProps()} />
      {getContent()}
    </ConnectedAppsContext.Provider>
  );
}
