import { DatePicker, useTranslation } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { endOfDay, startOfDay } from "date-fns";
import { useState } from "react";
import { useHistory } from "react-router";
import { useURLSearchParams } from "../../_common/routing/useURLSearchParams";

const useStyles = makeStyles((theme) => ({
  pickerFrom: {
    maxWidth: 200,
  },
  pickerTo: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    maxWidth: 185,
  },
  pickerToolbar: {
    order: 1,
  },
  pickerPopoverRoot: {
    marginTop: 8,
  },
  clearBtn: {
    margin: theme.spacing(2, 0.7, 2, 3),
    color: theme.palette.grey[700],
  },
}));

export function DateFilters({
  from,
  to,
  setFrom,
  setTo,
}: {
  disabled?: boolean | undefined;
  from?: string;
  to?: string;
  setFrom?: (value: Date | undefined) => string;
  setTo?: (value: Date | undefined) => string;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("creditUsage");
  const [fromOpen, setFromOpen] = useState(false);
  const [toOpen, setToOpen] = useState(false);
  const searchParams = useURLSearchParams();
  const history = useHistory();

  const resetPagination = (): void => {
    searchParams.delete("pagination");
    history.push({ search: searchParams.toString() });
  };

  return (
    <>
      <DatePicker
        autoOk
        open={fromOpen}
        onOpen={() => setFromOpen(true)}
        onClose={() => setFromOpen(false)}
        disableToolbar={false}
        className={classes.pickerFrom}
        format="dd MM yyyy"
        label={t("filters.from")}
        inputProps={{ "aria-label": "select credit usage from date" }}
        onChange={(e) => {
          const date = startOfDay(e as Date);
          resetPagination();
          setFrom?.(date);
        }}
        value={from}
        PopoverProps={{
          PaperProps: {
            classes: { root: classes.pickerPopoverRoot },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            "data-testid": "from-picker-popover",
          },
        }}
        ToolbarComponent={() => {
          return <div className={classes.pickerToolbar}></div>;
        }}
        data-testid="from-date-picker"
      />
      <DatePicker
        autoOk
        open={toOpen}
        onOpen={() => setToOpen(true)}
        onClose={() => setToOpen(false)}
        disableToolbar={false}
        inputProps={{ "aria-label": "select credit usage to date" }}
        className={classes.pickerTo}
        format="dd MM yyyy"
        label={t("filters.to")}
        onChange={(e) => {
          const date = endOfDay(e as Date);
          setTo?.(date);
          resetPagination();
        }}
        value={to}
        PopoverProps={{
          PaperProps: {
            classes: { root: classes.pickerPopoverRoot },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            "data-testid": "to-picker-popover",
          },
        }}
        ToolbarComponent={() => {
          return <div className={classes.pickerToolbar}></div>;
        }}
        data-testid="to-date-picker"
      />
    </>
  );
}
