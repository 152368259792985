import {
  DotsHorizontalOutlined,
  ToggleIconButton,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { Menu, MenuItem } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useState } from "react";
import { useHistory } from "react-router";
import { Routes } from "../../_common/routing/routes";

export function AccountsActionsCell({
  row,
  onDeactivateAccount,
  onUpdateProjects,
}: GridRenderCellParams & {
  onDeactivateAccount?: (id: string) => void;
  onUpdateProjects?: (id: string) => void;
}): JSX.Element {
  const history = useHistory();
  const { t } = useTranslation("adminPanel");
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <ToggleIconButton
        size="large"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        aria-label="open account actions menu"
      >
        <DotsHorizontalOutlined />
      </ToggleIconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <MenuItem
          onClick={() => {
            history.push(
              Routes.AdminPanelCreditAllocations.getUrl({
                accountId: row.id,
              }),
            );
          }}
        >
          <Typography variant="body2">
            {t("accountActions.manageCredits")}
          </Typography>
        </MenuItem>
        {onUpdateProjects ? (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              onUpdateProjects(row.id);
            }}
          >
            <Typography variant="body2">
              {t("accountActions.updateProjects")}
            </Typography>
          </MenuItem>
        ) : null}
        {onDeactivateAccount ? (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              onDeactivateAccount(row.id);
            }}
          >
            <Typography variant="body2">
              {t("accountActions.deactivateAccount")}
            </Typography>
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
}
