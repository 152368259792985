import { Typography, useTranslation } from "@lumar/shared";
import { Box, makeStyles } from "@material-ui/core";
import { OnboardingContainer } from "../_common/onboarding-container/OnboardingContainer";
import { ConfirmEmailForm } from "./ConfirmEmailForm";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(23),
    fontWeight: 600,
    letterSpacing: "-0.025rem",
    marginBottom: theme.spacing(4),
  },
}));

export const ConfirmEmailPage = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("confirmEmail");

  return (
    <OnboardingContainer>
      <Box display="flex" flexDirection="column">
        <Typography variant="h1" className={classes.title}>
          {t("title")}
        </Typography>
        <ConfirmEmailForm />
      </Box>
    </OnboardingContainer>
  );
};
