import {
  AddonAvailability,
  SessionContext,
  getRawAccountId,
  getRawUserId,
  useAuth,
  useBrand,
  useTranslation,
} from "@lumar/shared";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { WindowWithPendo } from "./types";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mixpanel: any;
    dataLayer: unknown[];
  }
}

export function TagsController(): JSX.Element {
  useSimilarwebTags();

  const session = React.useContext(SessionContext);
  const location = useLocation();

  const pendo = (window as WindowWithPendo).pendo;

  const context = React.useMemo(
    () => ({
      userId: getRawUserId(session?.id ?? ""),
      email: session?.email,
      firstName: session?.firstName,
      lastName: session?.lastName,
      permissions: (session?.permissions || []).map((c) => c.code),
      account: session?.account,
      role: session?.role,
      jobTitle: session?.jobTitle,
      getAddonAvailability: session?.getAddonAvailability,
    }),
    [
      session?.id,
      session?.email,
      session?.firstName,
      session?.lastName,
      session?.permissions,
      session?.account,
      session?.role,
      session?.jobTitle,
      session?.getAddonAvailability,
    ],
  );

  const { i18n } = useTranslation();
  const language = i18n.language;

  const canInitializePendo = Boolean(session && !session.isUsingSharedLink);

  React.useEffect(() => {
    const getAddonAvailability = context.getAddonAvailability;
    const account = context.account;

    const data = {
      app: "accounts",
      visitor: {
        id: context.userId,
        email: context.email ?? "",
        firstName: context.firstName ?? "",
        lastName: context.lastName ?? "",
        permissions: context.permissions,
        jobFunction: context.jobTitle ?? "",
        userLanguage: language,
      },
      account: {
        id: getRawAccountId(context.account?.id ?? ""),
        accountName: context.account?.name ?? "",
        role: context.role?.name ?? "",
        planLevel: context.account?.subscription.plan.name ?? "",
        planStatus: context.account?.subscription.plan.status ?? "",
        minCommitmentPeriod:
          context.account?.subscription.plan.minCommitmentPeriod ?? 0,
        period: context.account?.subscription.plan.period ?? 0,
        paidAddons:
          getAddonAvailability && account
            ? account.subscription.addons.flatMap((addon) =>
                addon.settings
                  .filter(
                    (setting) =>
                      getAddonAvailability(setting.code) ===
                      AddonAvailability.Paid,
                  )
                  .map((setting) => setting.name),
              )
            : [],
        freemiumAddons:
          getAddonAvailability && account
            ? account.subscription.addons.flatMap((addon) =>
                addon.settings
                  .filter(
                    (setting) =>
                      getAddonAvailability(setting.code) ===
                      AddonAvailability.Free,
                  )
                  .map((setting) => setting.name),
              )
            : [],
        subscriptionStatus: context.account?.subscription.status ?? "",
        accountManagers: context.account?.accountManagers ?? [],
        availableCredits: context.account?.availableCredits ?? 0,
      },
    };

    if (canInitializePendo) {
      pendo?.initialize(data);
    }
  }, [context, language, canInitializePendo, pendo, location]);

  return <></>;
}

const useSimilarwebTags = (): void => {
  const { auth0User } = useAuth();
  const location = useLocation();

  const session = React.useContext(SessionContext);
  const { brandId } = useBrand();

  useEffect(() => {
    if (auth0User?.sub) {
      const [, ssoProvider, userId] = auth0User.sub.split("|");
      if (window.dataLayer && ssoProvider === "similarweb") {
        // eslint-disable-next-line fp/no-mutating-methods
        window.dataLayer.push({
          event: "routeChange",
          user_id: userId,
        });
      }
    }
  }, [auth0User?.sub, location]);

  const userId = auth0User?.sub?.split("|")[2];
  const accountId = session && getRawAccountId(session.account.id);

  React.useEffect(() => {
    if (!window.dataLayer || brandId !== "similarweb") return;

    // eslint-disable-next-line fp/no-mutating-methods
    window.dataLayer.push({
      event: "updateUserData",
      user_id: userId,
      account_id: accountId,
    });
  }, [brandId, userId, accountId]);
};
