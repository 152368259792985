import {
  Button,
  fieldToSelect,
  fieldToTextField,
  Select,
  TextField,
  useTranslation,
} from "@lumar/shared";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useState } from "react";
import { RoleCode } from "../../graphql";
import { Roles } from "../useAccountUsers";
import * as Yup from "yup";
import { isBlacklisted } from "@deepcrawl/business-email-validator";
import isEmail from "validator/lib/isEmail";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    lineHeight: "17px",
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(1),
    transform: "none",
  },
  formContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "auto",
    columnGap: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  circularBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "36px",
    width: "45px",
    border: "solid",
    borderWidth: "1px",
    borderRadius: "6px",
    borderColor: "#C7CDB2",
    backgroundColor: "white",
  },
}));

export interface AddNewUserDialogProps {
  isDialogOpen: boolean;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  addNewUser: (email: string, role: RoleCode) => Promise<boolean>;
  roles: Roles[];
}

export function AddNewUserDialog(props: AddNewUserDialogProps): JSX.Element {
  const { t } = useTranslation("teamPage");
  const classes = useStyles();
  const { isDialogOpen, setIsDialogOpen, roles, addNewUser } = props;
  const [isSaving, setIsSaving] = useState(false);

  const initialValues = {
    businessEmail: "",
    role: RoleCode.Viewer,
  };

  const validationSchema = Yup.object().shape({
    businessEmail: Yup.string()
      .test("email", t("invalidEmail"), (email) => !email || isEmail(email))
      .test(
        "isBusinessEmail",
        t("invalidBusinessEmail"),
        (value) => value !== undefined && !isBlacklisted(value),
      ),
  });

  async function onSubmit({
    businessEmail,
    role,
  }: {
    businessEmail: string;
    role: RoleCode;
  }): Promise<void> {
    setIsSaving(true);
    const result = await addNewUser(businessEmail, role);
    if (result) {
      setIsSaving(false);
      setIsDialogOpen(false);
    }
    setIsSaving(false);
  }

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      aria-labelledby="add-new-user-dialog"
      fullWidth
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form>
          <DialogTitle id="add-new-user-dialog-title">
            {t("addNewUserDialog.addNewUser")}
          </DialogTitle>
          <DialogContent>
            <div className={classes.formContainer}>
              <div>
                <Field name="businessEmail">
                  {(props: FieldProps<string>) => (
                    <TextField
                      {...fieldToTextField(props)}
                      data-testid="business-email-field"
                      id="business-email-field"
                      label={t("addNewUserDialog.businessEmail")}
                    />
                  )}
                </Field>
              </div>
              <div>
                <Field name="role">
                  {(props: FieldProps<string>) => (
                    <Select
                      {...fieldToSelect(props)}
                      fullWidth
                      data-testid="role-field"
                      id="role-field"
                      label={t("addNewUserDialog.role")}
                    >
                      {roles.map((role, i) => (
                        <MenuItem key={i} value={role.value}>
                          {role.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setIsDialogOpen(false)}>
              {t("addNewUserDialog.cancel")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              loading={isSaving}
              data-testid="add-btn"
            >
              {t("addNewUserDialog.add")}
            </Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}
