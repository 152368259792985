import { Snackbar, useSession, useTranslation } from "@lumar/shared";
import { omit } from "lodash";
import { useSnackbar } from "notistack";
import { ReactNode, useState, useContext, createContext } from "react";
import { Prompt } from "react-router-dom";
import { AccountSubscriptionQuery } from "../../graphql";
import { useSubscriptionData } from "../subscription-data/useSubscriptionData";
import { useSubmitSubscriptionEditForm } from "./useSubmitSubscriptionEditForm";

export interface SubscriptionEditContextValue {
  setMode: (mode: "view" | "edit") => void;
  mode: "view" | "edit";
  isValueEdited: (submitCode: string) => boolean;
  getEditValue: (submitCode: string) => boolean | number | undefined;
  setEditValue: (code: string, value: number | boolean) => void;
  removeEditValue: (code: string) => void;
  dirty: boolean;
  clear: () => void;
  editValues: { [submitCode: string]: number | boolean };
  isSubmitting: boolean;
  submit: () => Promise<void>;
}

const SubscriptionEditContext = createContext<SubscriptionEditContextValue>({
  setMode: () => {
    return;
  },
  getEditValue: () => {
    return undefined;
  },
  isValueEdited: () => {
    return false;
  },
  setEditValue: () => {
    return;
  },
  removeEditValue: () => {
    return;
  },
  clear: () => {
    return;
  },
  editValues: {},
  mode: "view",
  dirty: false,
  isSubmitting: false,
  submit: () => {
    return Promise.resolve();
  },
});

export function SubscriptionEditProvider(props: {
  children: ReactNode;
  account: NonNullable<NonNullable<AccountSubscriptionQuery["getAccount"]>>;
}): JSX.Element {
  const { limits, addons, apps, integrations, credits } = useSubscriptionData(
    props.account,
  );
  const { t } = useTranslation("subscription");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [mode, setMode] = useState<"view" | "edit">("view");
  const { enqueueSnackbar } = useSnackbar();
  const { email, firstName, lastName, jobTitle, account } = useSession();
  const [editValues, setEditValues] = useState<{
    [submitCode: string]: number | boolean;
  }>({});
  const submitForm = useSubmitSubscriptionEditForm();

  const dirty = Object.keys(editValues).length > 0;

  function removeEditValue(submitCode: string): void {
    setEditValues((values) => {
      return omit(values, [submitCode]);
    });
  }

  function setEditValue(submitCode: string, value: number | boolean): void {
    setEditValues((values) => {
      return { ...values, [submitCode]: value };
    });
  }

  function getEditValue(submitCode: string): boolean | number | undefined {
    return editValues[submitCode];
  }

  function isValueEdited(submitCode: string): boolean {
    return editValues[submitCode] !== undefined;
  }

  function clear(): void {
    setEditValues({});
  }

  async function submit(): Promise<void> {
    try {
      setIsSubmitting(true);
      await submitForm({
        email,
        firstName,
        lastName,
        company: account.name,
        jobtitle: jobTitle,
        ...limits.reduce(
          (acc, curr) => {
            // eslint-disable-next-line fp/no-mutation
            acc[curr.submitCode] = "";
            return acc;
          },
          {} as Record<string, string>,
        ),
        ...addons.reduce(
          (acc, curr) => {
            // eslint-disable-next-line fp/no-mutation
            acc[curr.submitCode] = "";
            return acc;
          },
          {} as Record<string, string>,
        ),
        ...apps.reduce(
          (acc, curr) => {
            // eslint-disable-next-line fp/no-mutation
            acc[curr.submitCode] = "";
            return acc;
          },
          {} as Record<string, string>,
        ),
        ...integrations.reduce(
          (acc, curr) => {
            // eslint-disable-next-line fp/no-mutation
            acc[curr.submitCode] = "";
            return acc;
          },
          {} as Record<string, string>,
        ),
        ...credits.reduce(
          (acc, curr) => {
            // eslint-disable-next-line fp/no-mutation
            acc[curr.submitCode] = "";
            return acc;
          },
          {} as Record<string, string>,
        ),
        ...editValues,
      });
      setMode("view");
      enqueueSnackbar(
        <Snackbar variant="success" title={t("requestSubmitted")} />,
      );
    } catch (error) {
      enqueueSnackbar(
        <Snackbar variant="error" title={JSON.stringify(error)} />,
      );
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <SubscriptionEditContext.Provider
      value={{
        dirty,
        clear,
        mode,
        editValues,
        getEditValue,
        setMode,
        isValueEdited,
        setEditValue,
        removeEditValue,
        submit,
        isSubmitting,
      }}
    >
      <>
        <Prompt
          when={mode === "edit" && dirty}
          message={() => {
            return t("sureToLeave");
          }}
        />
        {props.children}
      </>
    </SubscriptionEditContext.Provider>
  );
}

export function useSubscriptionEditContext(): SubscriptionEditContextValue {
  return useContext(SubscriptionEditContext);
}
