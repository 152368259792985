import { useTranslation } from "@lumar/shared";
import { AddonIntegration } from "./types";

export function useDefaultAddons(): AddonIntegration[] {
  const { t } = useTranslation("subscription");
  return [
    {
      code: "data-explorer",
      description: t("addons.dataExplorer.description"),
      name: t("addons.dataExplorer.name"),
      included: false,
      submitCode: "data_explorer_requested",
    },
    {
      code: "jsrendering",
      description: t("addons.jsrendering.description"),
      name: t("addons.jsrendering.name"),
      included: false,
      submitCode: "javascript_rendering_requested",
    },
    {
      code: "segmentation",
      description: t("addons.segmentation.description"),
      name: t("addons.segmentation.name"),
      included: false,
      submitCode: "segmentation_requested",
    },
  ];
}
