import * as Yup from "yup";
import isUrl from "validator/lib/isURL";
import { useTranslation } from "@lumar/shared";

export function useAccountSchema(): unknown {
  const { t } = useTranslation("account");

  return Yup.object({
    proxy: Yup.string().test(
      "isUrl",
      t("proxyValidationError"),
      (x) =>
        !x ||
        isUrl(x, {
          protocols: ["socks", "socks4", "http", "https"],
          require_protocol: true,
        }),
    ),
  });
}
