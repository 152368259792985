/* eslint-disable fp/no-mutation */
import { Trans, Typography } from "@lumar/shared";
import { Link, makeStyles, Theme, useTheme } from "@material-ui/core";
import sanitizeHtml from "sanitize-html";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 15),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
    },
  },
  descriptionContainer: {
    background: theme.palette.grey[100],
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.grey[300],
    borderRadius: theme.spacing(0.75),
    padding: theme.spacing(2.375, 2, 2.25, 2),
    marginBottom: 21,
  },
  description: {
    color: theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(17),
    display: "block",
    maxWidth: 496,
  },
  templates: {
    position: "relative",
  },
  loading: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
  link: {
    color: theme.palette.blue[600],
  },
}));

function getTemplateStyle(theme: Theme): string {
  return `
    .scratch {
      color: ${theme.palette.grey[700]} !important;
    }

    .scratch a {
      color: ${theme.palette.blue[600]} !important;
    }
  `;
}

function getTemplateRowsStyle(theme: Theme): string {
  return `
    .row {
      background: ${theme.palette.background.paper} !important;
      border: 1px solid ${theme.palette.grey[300]} !important;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
      padding: ${theme.spacing(1, 2)} !important;
      margin-top: ${theme.spacing(4.875)}px !important;
      margin-bottom: 0px !important;
    }
    
    .icon {
      width: 68px !important;
      border-style: none !important;
      padding: 0px !important;
    }
    
    .icon:last-child {
      width: 32px !important;
    }
    
    .icon::after {
      content: "";
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' ><path fill-rule='evenodd' clip-rule='evenodd' d='M14.7515 6.35147C15.2201 5.88284 15.9799 5.88284 16.4485 6.35147L21.2485 11.1515C21.7172 11.6201 21.7172 12.3799 21.2485 12.8485L16.4485 17.6485C15.9799 18.1172 15.2201 18.1172 14.7515 17.6485C14.2828 17.1799 14.2828 16.4201 14.7515 15.9515L17.5029 13.2H3.6C2.93725 13.2 2.39999 12.6627 2.39999 12C2.39999 11.3373 2.93725 10.8 3.6 10.8H17.5029L14.7515 8.04853C14.2828 7.5799 14.2828 6.8201 14.7515 6.35147Z' fill='%23111827'></path></svg>");
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      margin-left: ${theme.spacing(1)}px;
    }

    .icon:last-child::after  {
      display: none;
    }
    
    .icon img {
      width: 20px !important;
      height: 20px !important;
      padding: ${theme.spacing(0.625)}px !important;
      border: 1px solid ${theme.palette.grey[300]} !important;
      border-radius: ${theme.spacing(0.375)}px !important;
      vertical-align: middle !important;
      display: inline-block !important;
    }

    .text-container h3 {
      color: ${theme.palette.grey[700]} !important;
      font-size: ${theme.typography.pxToRem(13)} !important;
      line-height: ${theme.typography.pxToRem(16)} !important;
      font-weight: 700 !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      pointer-events: none;
    }

    .text-container p {
      display: none;
    }
    
    .button { 
      background: ${theme.palette.primary.main} !important;
      font-size: ${theme.typography.pxToRem(14)} !important;
      line-height: ${theme.typography.pxToRem(17)} !important;
      font-weight: 500 !important;
      padding: ${theme.spacing(0.9375, 1.5)} !important;
      border-width: 0px !important;
      border-radius: ${theme.spacing(0.75)}px !important;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
    }

    .button::after {
      content: "";
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' ><path d='M13.2 3.6C12.5373 3.6 12 4.13726 12 4.8C12 5.46274 12.5373 6 13.2 6H16.3029L8.75147 13.5515C8.28284 14.0201 8.28284 14.7799 8.75147 15.2485C9.2201 15.7172 9.9799 15.7172 10.4485 15.2485L18 7.69706V10.8C18 11.4627 18.5373 12 19.2 12C19.8627 12 20.4 11.4627 20.4 10.8V4.8C20.4 4.13726 19.8627 3.6 19.2 3.6H13.2Z' fill='%23FFFFFF'></path><path d='M6 6C4.67452 6 3.6 7.07452 3.6 8.4V18C3.6 19.3255 4.67451 20.4 6 20.4H15.6C16.9255 20.4 18 19.3255 18 18V14.4C18 13.7373 17.4627 13.2 16.8 13.2C16.1373 13.2 15.6 13.7373 15.6 14.4V18H6V8.4L9.6 8.4C10.2627 8.4 10.8 7.86274 10.8 7.2C10.8 6.53726 10.2627 6 9.6 6H6Z' fill='%23FFFFFF'></path></svg>");
      display: inline-block;
      vertical-align: text-bottom;
      width: 16px;
      height: 16px;
      margin-left: ${theme.spacing(1)}px;
    }
  `;
}

export function ZapierTemplates(): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();

  function addScript(zapierRoot: HTMLDivElement): void {
    if (zapierRoot.querySelector("#zapierScript")) return;

    const script = document.createElement("script");
    script.src =
      "https://zapier.com/apps/embed/widget.js?services=deepcrawl&limit=10&html_id=zapier";
    script.id = "zapierScript";
    script.async = true;
    zapierRoot.appendChild(script);

    injectStyles(
      zapierRoot,
      getTemplateStyle(theme),
      getTemplateRowsStyle(theme),
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.descriptionContainer}>
        <Typography className={classes.description}>
          <Trans
            ns="connectedApps"
            i18nKey="zapier.templates"
            components={{
              zapierLink: (
                <Link
                  href="https://zapier.com/apps/deepcrawl/integrations"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                />
              ),
            }}
          />
        </Typography>
      </div>
      <div className={classes.templates}>
        <div
          id="zapier"
          ref={(e) => e && addScript(e)}
          data-testid="zapier-templates"
        />
      </div>
    </div>
  );
}

function injectStyles(
  root: HTMLElement,
  templateStyle: string,
  rowsStyle: string,
): void {
  onTemplatesLoaded(root, (element) => {
    if (!element.shadowRoot) return;

    // Add style element for the container elements
    if (!element.shadowRoot.querySelector("#customStyle")) {
      const style = document.createElement("style");
      style.id = "customStyle";
      style.innerHTML = sanitizeHtml(templateStyle);
      element.shadowRoot.appendChild(style);
    }

    // Add style element to every template row element
    const div = element.shadowRoot.querySelector(".scroll") as HTMLElement;
    onRowsLoaded(div as HTMLElement, (element) => {
      if (
        !element.shadowRoot ||
        element.shadowRoot.querySelector("#customStyle")
      )
        return;

      const style = document.createElement("style");
      style.id = "customStyle";
      style.innerHTML = sanitizeHtml(rowsStyle);
      element.shadowRoot.appendChild(style);
    });
  });
}

function onTemplatesLoaded(
  element: HTMLElement,
  callback: (element: HTMLElement) => void,
): void {
  if (!element) return;
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        const element = node as HTMLElement;
        if (element?.tagName !== "ZAPIER-ZAP-TEMPLATES") return;

        observer.disconnect();
        onShadowRootAtached(element, (element) => callback(element));
      });
    });
  });

  observer.observe(element, {
    childList: true,
    subtree: true,
  });
}

function onRowsLoaded(
  element: HTMLElement,
  callback: (element: HTMLElement) => void,
): void {
  if (!element) return;
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        const child = node as HTMLElement;

        observer.disconnect();
        onShadowRootAtached(child, (element) => callback(element));
      });
    });
  });
  observer.observe(element, {
    childList: true,
    subtree: true,
  });
}

function onShadowRootAtached(
  element: HTMLElement,
  callback: (element: HTMLElement) => void,
): void {
  if (!element) return;

  if (element.shadowRoot) {
    callback(element);
    return;
  }

  const observer = new MutationObserver((mutations) => {
    mutations.forEach(() => {
      if (!element?.shadowRoot) return;

      observer.disconnect();
      callback(element);
    });
  });

  observer.observe(element, {
    attributes: true,
  });
}
