import { ArrowLeftOutlined, LumarLogo, useTranslation } from "@lumar/shared";
import { Button, makeStyles, useTheme } from "@material-ui/core";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Routes } from "../_common/routing/routes";

export const NavigationBar = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation("adminPanel");
  const theme = useTheme();

  const LogoComponent = theme.props?.Logo?.LogoComponent || LumarLogo;

  return (
    <div className={classes.navigation}>
      <Link to={Routes.AdminPanel.ROUTE} className={classes.logo}>
        <LogoComponent />
      </Link>
      <Button
        variant="contained"
        color="secondary"
        startIcon={<ArrowLeftOutlined />}
        onClick={() => {
          history.push(Routes.Home.ROUTE);
        }}
      >
        {t("exit")}
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  navigation: {
    backgroundColor: theme.palette.common.white,
    padding: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: { maxWidth: 200, width: "100%", display: "flex", alignItems: "center" },
}));
