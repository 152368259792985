import { Snackbar, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import {
  useGetAccountQuery,
  useUpdateAccountSettingsMutation,
} from "../../graphql";
import { getAccountData } from "./getAccountData";
import { getFormValues } from "./getFormValues";
import { getMutationData } from "./getMutationData";
import { Account, AccountFormValues } from "./types";

export interface AccountFormValuesResult {
  formValues: AccountFormValues;
  saveFormValues: (formValues: AccountFormValues) => Promise<boolean>;
  account: Account;
  loading: boolean;
}

export function useAccountFormValues(): AccountFormValuesResult {
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation("account");

  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useGetAccountQuery({
    variables: { accountId },
    fetchPolicy: "cache-first",
    onError: () =>
      enqueueSnackbar(<Snackbar variant="error" title={t("loadError")} />),
  });

  const [updateAccount] = useUpdateAccountSettingsMutation();

  return {
    formValues: getFormValues(data),
    account: getAccountData(data),
    saveFormValues: async (values) => {
      try {
        await updateAccount({
          variables: getMutationData(data, accountId, values),
        });

        enqueueSnackbar(
          <Snackbar variant="success" title={t("saveSuccess")} />,
        );
        return true;
      } catch {
        enqueueSnackbar(<Snackbar variant="error" title={t("saveError")} />);
        return false;
      }
    },
    loading,
  };
}
