import {
  AddonAvailability,
  SessionContextType,
  useBrand,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { AccountSubscriptionQuery } from "../../graphql";
import {
  AddonIntegration,
  CreditType,
  Limit,
  Service,
  SubscriptionData,
} from "./types";
import { useDefaultAddons } from "./useDefaultAddons";
import { useDefaultApps } from "./useDefaultApps";
import { useDefaultIntegrations } from "./useDefaultIntegrations";
import { useDefaultLimits } from "./useDefaultLimits";
import { useDefaultServices } from "./useDefaultServices";
import { BrandConfiguration } from "@lumar/shared/dist/brand/types";
import { TFunction } from "i18next";
import { useDefaultCredits } from "./useDefaultCredits";

type AccountData = NonNullable<
  NonNullable<AccountSubscriptionQuery["getAccount"]>
>;

export function useSubscriptionData(account: AccountData): SubscriptionData {
  const defaultLimits = useDefaultLimits();
  const defaultIntegrations = useDefaultIntegrations();
  const defaultAddons = useDefaultAddons();
  const defaultApps = useDefaultApps();
  const defaultServices = useDefaultServices();
  const defaultCredits = useDefaultCredits();
  const { getAddonAvailability } = useSession();

  const { t } = useTranslation("subscription");
  const brand = useBrand();

  const subscription = account.subscription;

  return {
    name: subscription?.plan.name || t("unnamed"),
    credits: mapDefaultToRealCredits(
      defaultCredits,
      account,
      getAddonAvailability,
    ),
    limits: mapDefaultToRealLimits(defaultLimits, account),
    integrations: mapDefaultToRealAddonIntegrations(
      defaultIntegrations,
      account,
    ),
    addons: mapDefaultToRealAddonIntegrations(defaultAddons, account),
    apps: mapDefaultToRealApps(defaultApps, account, brand),
    services: mapDefaultToRealServices(defaultServices, account, t, brand),
  };
}

function mapDefaultToRealLimits(
  defaultLimits: Limit[],
  account: AccountData,
): Limit[] {
  return defaultLimits.map((defaultLimit) => {
    if (defaultLimit.code === "maxCrawlRate") {
      return {
        ...defaultLimit,
        value: account.maxCrawlRate,
      };
    }

    if (defaultLimit.code === "limitPagesMax") {
      return {
        ...defaultLimit,
        value: account.limitPagesMax,
      };
    }

    if (defaultLimit.code === "limitLevelsMax") {
      return {
        ...defaultLimit,
        value: account.limitLevelsMax,
      };
    }

    if (defaultLimit.code === "views") {
      return {
        ...defaultLimit,
        value: account.maxDashboardCollectionViews,
      };
    }
    if (defaultLimit.code === "maxProjectTests") {
      return {
        ...defaultLimit,
        value: account.maxProjectTests,
      };
    }

    return {
      ...defaultLimit,
      value:
        account.accountSettings.find(
          (setting) => setting.code === defaultLimit.code,
        )?.limit || 0,
    };
  });
}

function mapDefaultToRealAddonIntegrations(
  defaultEntries: AddonIntegration[],
  account: AccountData,
): AddonIntegration[] {
  return defaultEntries.map((defaultEntry) => {
    return {
      ...defaultEntry,
      included: Boolean(
        account.accountSettings.find((x) => x.code === defaultEntry.code),
      ),
    };
  });
}

function mapDefaultToRealApps(
  defaultApps: AddonIntegration[],
  account: AccountData,
  brand: BrandConfiguration,
): AddonIntegration[] {
  return defaultApps
    .map((app) => {
      if (app.code === "monitor-app") {
        return {
          ...app,
          included: !!account.subscription?.monitorAvailable,
        };
      }

      if (app.code === "analyze-app") {
        return {
          ...app,
          included: !!account.subscription?.analyzeAvailable,
        };
      }

      if (app.code === "protect-app") {
        return {
          ...app,
          included: !!account.subscription?.automateAvailable,
        };
      }

      return app;
    })
    .filter((app) => {
      if (!brand.featureAvailability.analyzeApp && app.code === "analyze-app") {
        return false;
      } else if (
        !brand.featureAvailability.monitorApp &&
        app.code === "monitor-app"
      ) {
        return false;
      } else if (
        !brand.featureAvailability.protectApp &&
        app.code === "protect-app"
      ) {
        return false;
      } else {
        return true;
      }
    });
}

function mapDefaultToRealServices(
  defaultServices: Service[],
  account: AccountData,
  t: TFunction<"subscription">,
  brand: BrandConfiguration,
): Service[] {
  return defaultServices.map((defaultService) => {
    const code = defaultService.code;

    if (code === "account-manager") {
      return {
        ...defaultService,
        value: account.accountManagers.join(", "),
      };
    }

    if (account.accountSettings.find((setting) => setting.code === code)) {
      if (code === "email_support") {
        return {
          ...defaultService,
          value: brand.supportLink,
        };
      }

      if (code === "phone_support") {
        return {
          ...defaultService,
          value: t("phoneSupportEnabled"),
        };
      }
    }

    return defaultService;
  });
}

function mapDefaultToRealCredits(
  defaultCredits: CreditType[],
  account: AccountData,
  getAddonAvailability: SessionContextType["getAddonAvailability"],
): CreditType[] {
  return defaultCredits.map((credit) => {
    if (credit.code === "seo-credits") {
      return {
        ...credit,
        ...getUsedAndMaxCredits(
          account.primaryAccountPackage?.maxSeoCredits,
          account.seoCredits,
        ),
        // TODO: Remove ternary logic as soon as the org cleans up the account and starts giving the addon for free.
        availabilityType:
          getAddonAvailability("seo") !== AddonAvailability.None
            ? AddonAvailability.Paid
            : AddonAvailability.None,
      };
    }

    if (credit.code === "a11y-credits") {
      return {
        ...credit,
        ...getUsedAndMaxCredits(
          account.primaryAccountPackage?.maxAccessibilityCredits,
          account.accessibilityCredits,
        ),
        // TODO: Remove ternary logic as soon as the org cleans up the account and starts giving the addon for free.
        availabilityType:
          getAddonAvailability("accessibility") !== AddonAvailability.None
            ? AddonAvailability.Paid
            : AddonAvailability.None,
      };
    }

    if (credit.code === "site-speed-credits") {
      return {
        ...credit,
        ...getUsedAndMaxCredits(
          account.primaryAccountPackage?.maxSiteSpeedCredits,
          account.siteSpeedCredits,
        ),
        availabilityType: getAddonAvailability("site-speed"),
      };
    }

    return credit;
  });
}

function getUsedAndMaxCredits(
  max = 0,
  available = 0,
): { max: number; used: number } {
  return { max: max, used: max - available };
}
