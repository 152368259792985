import React from "react";
import {
  BlueDataGrid,
  useTranslation,
  useSession,
  useBrand,
} from "@lumar/shared";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { useAccountUsers } from "./useAccountUsers";
import { useTeamPageColumns } from "./useTeamPageColumns";
import { UserCounter } from "./UserCounter";
import { AddUser } from "./add-user/AddUser";
import { Redirect, useParams } from "react-router-dom";
import { RoleCode } from "../graphql";
import { Routes } from "../_common/routing/routes";
import { SearchField } from "./SearchField";

export function TeamPage(): JSX.Element {
  const { t } = useTranslation("teamPage");
  const session = useSession();
  const { accountId } = useParams<{ accountId: string }>();
  const brand = useBrand();

  const accountUsers = useAccountUsers();
  const { error, loading, users } = accountUsers;
  const columns = useTeamPageColumns(accountUsers);

  const [search, setSearch] = React.useState("");
  const rows = users.filter(
    (user) =>
      user.email.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
      user.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );

  if (
    !session.hasSufficientRole(RoleCode.Admin) ||
    !brand.featureAvailability.teamsPage
  ) {
    return <Redirect exact to={Routes.UserSettings.getUrl({ accountId })} />;
  }

  return (
    <>
      <TopNavigation title={t("title")} />
      <div data-testid="team-page-grid">
        <BlueDataGrid
          name="team-page-grid"
          rows={rows}
          totalRowCount={users.length}
          columns={columns}
          components={{
            ToolbarLeft: [SearchField, UserCounter],
            ToolbarRight: AddUser,
          }}
          componentsProps={{
            toolbar: {
              disabled: Boolean(error),
              toolbarRight: { accountUsers },
              toolbarLeft: { accountUsers, search, setSearch },
            },
          }}
          loading={loading}
          error={error}
          pageSize={10}
          autoRowHeight
          rowHeight={64}
        ></BlueDataGrid>
      </div>
    </>
  );
}
