import React from "react";
import { SearchSolid, TextField, XSolid, useTranslation } from "@lumar/shared";
import { IconButton, makeStyles } from "@material-ui/core";
import { debounce, DebouncedFunc } from "lodash";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: 290,
    marginRight: theme.spacing(1),
  },
  icon: {
    color: theme.palette.grey[500],
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  search?: string;
  setSearch?: (value: string) => void;
}

export function SearchField({ search, setSearch }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("teamPage");

  const [innerValue, setInnserValue] = React.useState(search);
  const functionRef = React.useRef<DebouncedFunc<() => void> | undefined>(
    undefined,
  );

  function setValue(value: string): void {
    setInnserValue(value);

    if (value === "") {
      functionRef.current?.cancel();
      setSearch?.(value);
    } else {
      functionRef.current?.cancel();
      functionRef.current = debounce(() => setSearch?.(value), 300);
      functionRef.current();
    }
  }

  return (
    <TextField
      value={innerValue}
      onChange={(e) => setValue(e.target.value)}
      placeholder={t("searchPlaceholder")}
      className={classes.textField}
      InputProps={{
        startAdornment: <SearchSolid className={classes.icon} />,
        endAdornment: Boolean(innerValue) && (
          <IconButton
            onClick={() => setValue("")}
            size="small"
            aria-label={t("clearButtonLabel")}
          >
            <XSolid />
          </IconButton>
        ),
      }}
      data-testid="user-search-field"
    />
  );
}
