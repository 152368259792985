import React from "react";
import {
  Button,
  fieldToSelect,
  fieldToTextField,
  Select,
  TextField,
  Typography,
  useTranslation,
} from "@lumar/shared";
import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Field, FieldProps, Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { ConnectedApps, SplunkAccount } from "./data/types";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 490,
  },
  title: {
    display: "block",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2.5),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
}));

interface SplunkAccountDialogProps {
  account?: SplunkAccount;
  addAccount: ConnectedApps["addSplunkAccount"];
  updateAccount: ConnectedApps["updateSplunkAccount"];
  onClose: () => void;
}

interface FormValues {
  url: string;
  username: string;
  password: string;
  staticIp?: string;
}

export function SplunkAccountDialog({
  account,
  addAccount,
  updateAccount,
  onClose,
}: SplunkAccountDialogProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");

  const formikRef = React.useRef<FormikProps<FormValues> | null>(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    formikRef.current?.resetForm();
    setOpen(Boolean(account));
  }, [account]);

  const [saving, setSaving] = React.useState(false);

  const isNew = !account?.id?.length;

  const staticIpOptions = [
    {
      value: undefined,
      label: t("splunk.dynamic"),
    },
  ];

  const validationSchema = Yup.object().shape({
    url: Yup.string().required(t("required")),
    username: Yup.string().required(t("required")),
    password: Yup.string().required(t("required")),
  });

  async function handleSave(submitForm: () => Promise<boolean>): Promise<void> {
    if (saving) return;

    setSaving(true);
    const result = await submitForm();
    setSaving(false);
    if (result) setOpen(false);
  }

  return (
    <Formik
      initialValues={{
        url: account?.url ?? "",
        username: account?.username ?? "",
        password: "",
        staticIp: account?.staticIp,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={({ url, username, password }) => {
        if (isNew) return addAccount(url, username, password);
        return updateAccount(account.id, url, username, password);
      }}
      innerRef={formikRef}
    >
      {({ submitForm }) => (
        <Dialog
          open={open}
          onClose={() => !saving && setOpen(false)}
          TransitionProps={{ onExited: onClose }}
          classes={{ paper: classes.dialog }}
        >
          <DialogContent>
            <Typography variant="h2SemiBold" className={classes.title}>
              {isNew ? t("splunk.addAccount") : t("splunk.updateAccount")}
            </Typography>
            <Field name="url">
              {(props: FieldProps<string>) => (
                <TextField
                  {...fieldToTextField(props)}
                  label={t("splunk.hostname")}
                  placeholder={t("splunk.hostnamePlaceholder")}
                  className={classes.field}
                  data-testid="splunk-edit-hostname"
                />
              )}
            </Field>
            <Field name="username">
              {(props: FieldProps<string>) => (
                <TextField
                  {...fieldToTextField(props)}
                  label={t("splunk.username")}
                  placeholder={t("splunk.usernamePlaceholder")}
                  className={classes.field}
                  data-testid="splunk-edit-username"
                />
              )}
            </Field>
            <Field name="password">
              {(props: FieldProps<string>) => (
                <TextField
                  {...fieldToTextField(props)}
                  type="password"
                  label={t("splunk.password")}
                  placeholder={t("splunk.passwordPlaceholder")}
                  className={classes.field}
                  data-testid="splunk-edit-password"
                />
              )}
            </Field>
            <Field name="staticIp">
              {(props: FieldProps<string>) => (
                <Select
                  {...fieldToSelect(props)}
                  label={t("splunk.staticIp")}
                  fullWidth
                  displayEmpty
                  className={classes.field}
                  data-testid="splunk-edit-static-ip"
                >
                  {staticIpOptions.map((option, idx) => (
                    <MenuItem key={idx} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Field>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              disabled={saving}
              variant="outlined"
              size="large"
            >
              {t("cancelButton")}
            </Button>
            <Button
              onClick={() => handleSave(submitForm)}
              loading={saving}
              variant="contained"
              color="primary"
              size="large"
              data-testid="splunk-edit-save"
            >
              {isNew ? t("addButton") : t("updateButton")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
