import { Alert, useSession, useTranslation } from "@lumar/shared";
import { CircularProgress } from "@material-ui/core";
import { Redirect, useParams } from "react-router-dom";
import { RoleCode } from "../graphql";
import { Routes } from "../_common/routing/routes";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { AccountSubscription } from "./AccountSubscription";
import { useSubscriptionQuery } from "./useSubscriptionQuery";

export function Subscription(): JSX.Element {
  const { accountId } = useParams<{ accountId: string }>();
  const { t } = useTranslation("subscription");
  const { hasSufficientRole } = useSession();
  const { data, loading, errorMessage } = useSubscriptionQuery();

  if (!hasSufficientRole(RoleCode.Editor)) {
    return <Redirect exact to={Routes.UserSettings.getUrl({ accountId })} />;
  }

  // Note: `errorMessage`, and the `ready` variable guarantee
  // availability of data. TS is unable to return a type and assertion at the time of writing this code.
  // Relevant link: https://github.com/microsoft/TypeScript/issues/40562
  // - Michal
  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  return (
    <>
      <TopNavigation title={t("pageTitle")} />
      {loading ? <CircularProgress aria-busy="true" /> : null}
      {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}
      {!loading && !errorMessage ? (
        <AccountSubscription account={data!.getAccount!} />
      ) : null}
    </>
  );
}
