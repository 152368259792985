import React from "react";
import { Typography, useNumberFormatter } from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

const useStyles = makeStyles((theme) => ({
  cell: {
    display: "flex",
    width: "100%",
    justifyContent: "end",
  },
  credit: {
    color: theme.palette.grey[700],
  },
}));

export function CreditsCell(params: GridRenderCellParams): JSX.Element {
  const classes = useStyles();
  const formatNumber = useNumberFormatter();
  return (
    <div className={classes.cell}>
      <Typography variant="subtitle2SemiBold" className={classes.credit}>
        {formatNumber(params.value as number)}
      </Typography>
    </div>
  );
}
