import { useTranslation } from "@lumar/shared";

export enum JOB_TITLES_ENUM {
  TechnicalSeo = "Technical SEO",
  SeoGeneralist = "SEO Generalist",
  ProductManagement = "Product Management",
  DigitalMarketer = "Digital Marketer",
  MarketingLeadership = "Leadership - Marketing",
  TechnicalLeadership = "Leadership - Technical",
  ContentMarketer = "Content Marketer",
  UxDesign = "UX Design",
  Engineer = "Engineer/Dev",
  AccessibilitySpecialist = "Accessibility Specialist",
  QAEngineer = "QA Engineer",
}

export function useJobTitles(): { label: string; value: JOB_TITLES_ENUM }[] {
  const { t } = useTranslation("userDetails");

  return [
    {
      label: t("jobTitles.technicalSeo"),
      value: JOB_TITLES_ENUM.TechnicalSeo,
    },
    {
      label: t("jobTitles.generalistSEO"),
      value: JOB_TITLES_ENUM.SeoGeneralist,
    },
    {
      label: t("jobTitles.productManagement"),
      value: JOB_TITLES_ENUM.ProductManagement,
    },
    {
      label: t("jobTitles.digitalMarketer"),
      value: JOB_TITLES_ENUM.DigitalMarketer,
    },
    {
      label: t("jobTitles.marketingLeadership"),
      value: JOB_TITLES_ENUM.MarketingLeadership,
    },
    {
      label: t("jobTitles.technicalLeadership"),
      value: JOB_TITLES_ENUM.TechnicalLeadership,
    },
    {
      label: t("jobTitles.contentMarketer"),
      value: JOB_TITLES_ENUM.ContentMarketer,
    },
    {
      label: t("jobTitles.uxDesign"),
      value: JOB_TITLES_ENUM.UxDesign,
    },
    {
      label: t("jobTitles.engineer"),
      value: JOB_TITLES_ENUM.Engineer,
    },
    {
      label: t("jobTitles.accessibilitySpecialist"),
      value: JOB_TITLES_ENUM.AccessibilitySpecialist,
    },
    {
      label: t("jobTitles.qaEngineer"),
      value: JOB_TITLES_ENUM.QAEngineer,
    },
  ];
}
