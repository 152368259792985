import {
  Button,
  ExclamationCircleSolid,
  Select,
  TextField,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { Box, InputAdornment, makeStyles, MenuItem } from "@material-ui/core";

import { useUserDetailsForm } from "./useUserDetailsForm";
import { useJobTitles } from "./useJobTitles";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    maxWidth: 500,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  errorsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  errorMessage: {
    marginBottom: theme.spacing(1),
  },
  content: {
    display: "flex",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    width: 472,
    padding: theme.spacing(1, 1, 3, 1),
  },
  description: {
    marginTop: theme.spacing(1),
  },
  icon: {
    fontSize: "48px",
    marginTop: theme.spacing(1),
    color: theme.palette.green[500],
  },
}));

type Props = {
  onSuccess: () => void;
};

export const UserDetailsForm = ({ onSuccess }: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("userDetails");

  const jobTitles = useJobTitles();

  const {
    formik: {
      handleSubmit,
      handleChange,
      values,
      errors,
      isSubmitting,
      isValid,
    },
  } = useUserDetailsForm(jobTitles, onSuccess);

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <TextField
        required
        label={t("form.labels.firstName")}
        name="firstName"
        onChange={handleChange}
        className={classes.input}
        value={values.firstName}
        error={!!errors.firstName}
        InputProps={{
          endAdornment: !!errors.firstName && (
            <InputAdornment position="end">
              <ExclamationCircleSolid fontSize="small" color="error" />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        required
        label={t("form.labels.lastName")}
        name="lastName"
        onChange={handleChange}
        className={classes.input}
        value={values.lastName}
        error={!!errors.lastName}
        InputProps={{
          endAdornment: !!errors.lastName && (
            <InputAdornment position="end">
              <ExclamationCircleSolid fontSize="small" color="error" />
            </InputAdornment>
          ),
        }}
      />
      <Select
        label={t("form.labels.jobTitle")}
        name="jobTitle"
        onChange={handleChange}
        className={classes.input}
        value={values.jobTitle}
        error={!!errors.jobTitle}
        placeholder={t("form.placeholders.jobFunction")}
      >
        {jobTitles.map((jobTitle: { value: string; label: string }) => (
          <MenuItem key={jobTitle.value} value={jobTitle.label}>
            {jobTitle.label}
          </MenuItem>
        ))}
      </Select>
      <Box className={classes.errorsContainer}>
        {Object.keys(errors).map((field) => {
          // Due to limitation on Yup schema type infering, is not possible
          // to use field here as a index signature as in this case it's considered
          // a generic string but Typescript spect it to be within limited options
          // due to the keys in the validation schema - Alex S.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const error = errors?.[field] as string;
          return error ? (
            <Typography variant="caption" color="error">
              *{error}
            </Typography>
          ) : null;
        })}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
        >
          {t("form.submit")}
        </Button>
      </Box>
    </form>
  );
};
