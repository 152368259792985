import { Snackbar, useTranslation } from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useGetConnectedAppsQuery } from "../../graphql";
import { ConnectedApps } from "./types";
import { useAdobeAccounts } from "./useAdobeAccounts";
import { useGoogleAccounts } from "./useGoogleAccounts";
import { useLogzioAccounts } from "./useLogzioAccounts";
import { useSplunkAccounts } from "./useSplunkAccounts";

export function useConnectedApps(): ConnectedApps {
  const { t } = useTranslation("connectedApps");
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading } = useGetConnectedAppsQuery({
    fetchPolicy: "cache-first",
    onError: () =>
      enqueueSnackbar(<Snackbar variant="error" title={t("errorLoad")} />),
  });

  const googleAccounts = useGoogleAccounts(data, enqueueSnackbar, t);
  const logzioAccounts = useLogzioAccounts(data, enqueueSnackbar, t);
  const splunkAccounts = useSplunkAccounts(data, enqueueSnackbar, t);
  const adobeAccounts = useAdobeAccounts(data, enqueueSnackbar, t);

  return {
    loading,
    ...googleAccounts,
    ...logzioAccounts,
    ...splunkAccounts,
    ...adobeAccounts,
  };
}
