import { Typography } from "@lumar/shared";
import { Box } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

export function EmailCell(props: GridRenderCellParams): JSX.Element {
  return (
    <Box display="flex" data-testid="email-cell">
      <Typography>{props.value}</Typography>
    </Box>
  );
}
