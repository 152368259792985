import {
  useTranslation,
  InformationCircleOutlined,
  UsageLimitIndicator,
  Typography,
  Warning,
  Chip,
  AddonAvailability,
} from "@lumar/shared";
import { useTheme, Tooltip } from "@material-ui/core";
import { CreditType } from "../subscription-data/types";
import { LimitsList } from "./FeatureLists";
import { SubscriptionSection } from "./SubscriptionSection";
import React from "react";
import { omit } from "lodash";

export function CreditTypeSubscriptionSection(props: CreditType): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation("subscription");

  if (props.availabilityType === AddonAvailability.None) {
    return (
      <SubscriptionSection
        headContent={
          <>
            <Warning htmlColor={theme.palette.yellow[500]} />
            <Typography component="div" variant="subtitle2Bold">
              {props.name}
            </Typography>
            <Typography component="div" variant="captionMedium">
              {t("creditNotAvailable")}
            </Typography>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography component="div" variant="subtitle2SemiBold">
                {t("creditNotIncluded")}
              </Typography>
              <Tooltip
                title={props.description}
                placement="right"
                style={{
                  verticalAlign: "middle",
                  marginLeft: 12.5,
                  color: theme.palette.grey[500],
                }}
              >
                <InfoIcon />
              </Tooltip>
            </div>
          </>
        }
      />
    );
  }

  return (
    <SubscriptionSection
      headContent={
        <>
          <Typography component="div" variant="subtitle2Bold">
            {props.name}
          </Typography>
          {props.availabilityType === AddonAvailability.Free ? (
            <Chip label={t("complimentaryCredits")} color="yellow" />
          ) : null}
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            <UsageLimitIndicator
              usageCount={props.used}
              usageLimit={props.max}
              unit={"credits"}
            />
            <Tooltip
              title={props.description}
              placement="right"
              style={{
                verticalAlign: "middle",
                marginLeft: 12.5,
                color: theme.palette.grey[500],
              }}
            >
              <InfoIcon />
            </Tooltip>
          </div>
        </>
      }
      mainContent={
        <LimitsList
          limits={[
            {
              code: props.code,
              description: props.limitDescription,
              name: props.limitName,
              submitCode: props.submitCode,
              value: props.max,
              unit: "",
            },
          ]}
        />
      }
    />
  );
}

const InfoIcon = React.forwardRef<
  SVGSVGElement,
  Parameters<typeof InformationCircleOutlined>[0]
>(function InfoIcon(props, ref): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const title = (props as any).title;
  const titleAccess = typeof title === "string" ? title : undefined;

  return (
    <InformationCircleOutlined
      ref={ref}
      fontSize="small"
      tabIndex={0}
      titleAccess={titleAccess}
      {...omit(props, "title")}
    />
  );
});
