import { useTranslation } from "@lumar/shared";
import { useMemo } from "react";
import {
  GetCreditUsageDataQuery,
  CreditAllocationType,
  CreditReportType,
} from "../graphql";
import { TFunction } from "i18next";

export interface CreditUsageRows {
  id: string;
  crawlId: string;
  startedAt: string;
  finishedAt: string;
  domainName: string;
  projectName: string;
  username: string;
  creditsUsed: number;
  creditAllocationType: CreditAllocationType;
  isDeleted: boolean;
}

const getCreditType = (
  type: CreditReportType,
  t: TFunction<"creditUsage">,
): string => {
  switch (type) {
    case CreditReportType.Crawl:
      return t("crawl");
    case CreditReportType.SinglePageRequester:
      return t("singlePageRequester");
  }
};

export function useCreditUsageRows(
  data?: GetCreditUsageDataQuery,
): CreditUsageRows[] {
  const { t } = useTranslation("common");
  const { t: tCreditUsage } = useTranslation("creditUsage");
  const rows = useMemo(
    () =>
      data?.getAccount?.creditReports?.edges.map((creditReport) => ({
        id: creditReport.cursor,
        crawlId: creditReport.node.crawlId ?? "",
        startedAt: creditReport.node.createdAt
          ? t("dateTime", { value: new Date(creditReport.node.createdAt) })
          : "",
        finishedAt: creditReport.node.finishedAt
          ? t("dateTime", { value: new Date(creditReport.node.finishedAt) })
          : "",
        domainName: creditReport.node.project?.primaryDomain ?? "",
        projectName: creditReport.node.project?.name ?? "",
        username:
          creditReport.node.crawl?.createdByUser?.email ||
          creditReport.node.crawl?.createdByUser?.id.toString() ||
          "",
        creditsUsed: creditReport.node.creditsUsed ?? 0,
        isDeleted:
          creditReport.node.crawl === null &&
          creditReport.node.type === CreditReportType.Crawl,
        creditType: getCreditType(creditReport.node.type, tCreditUsage),
        creditAllocationType: creditReport.node.creditAllocationType,
        customMetricContainerCosts:
          creditReport.node.customMetricContainerCosts ?? [],
      })) || [],
    [data, t, tCreditUsage],
  );

  return rows;
}
