import {
  Button,
  TextField,
  ToggleIconButton,
  TrashSolid,
  Typography,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { Box, makeStyles } from "@material-ui/core";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { GetCallbackQuery, UpdateAccountMutationFn } from "../graphql";
import * as Yup from "yup";
import React from "react";

const useStyles = makeStyles((theme) => ({
  headersStep: {
    marginTop: theme.spacing(4),
  },
  button: {
    alignSelf: "start",
    marginTop: theme.spacing(2),
  },
  headerContainer: {
    display: "flex",
    alignItems: "end",
    maxWidth: 400,
    marginTop: theme.spacing(3),
  },
  headerTextField: {
    marginRight: theme.spacing(1.5),
  },
  headerErrorLabel: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  data: GetCallbackQuery | undefined;
  updateAccount: UpdateAccountMutationFn;
  canEdit: boolean;
};

type FormValues = {
  headers: { key: string; value: string }[];
};

export function ApiCallbackHeadersForm({
  data,
  updateAccount,
  canEdit,
}: Props): JSX.Element {
  const classes = useStyles();
  const context = useSession();
  const { t } = useTranslation("apiAccess");

  const callbackHeaders = data?.getAccount?.apiCallbackHeaders;

  const validationSchema = Yup.object({
    headers: Yup.array().of(
      Yup.object({
        key: Yup.string()
          .matches(/^[a-zA-Z0-9-]+$/, t("apiCallback.headerValidationError"))
          .required(t("apiCallback.headerKeyRequired")),
        value: Yup.string().required(t("apiCallback.headerValueRequired")),
      }),
    ),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      headers:
        callbackHeaders?.map((header) => ({
          key: header.key,
          value: header.value,
        })) ?? [],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      updateAccount({
        variables: {
          id: context.account.id,
          apiCallbackHeaders: values.headers,
        },
      });
    },
  });

  return (
    <>
      <Typography variant="body2" className={classes.headersStep}>
        {t("apiCallback.step2")}
      </Typography>
      <FormikProvider value={formik}>
        <FieldArray
          name="headers"
          render={(helpers) => (
            <>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                className={classes.button}
                data-testid="create-new-header"
                disabled={!canEdit}
                onClick={() =>
                  helpers.insert(formik.values.headers.length + 1, {
                    key: "",
                    value: "",
                  })
                }
              >
                {t("apiCallback.createHeader")}
              </Button>
              {formik.values.headers.map((header, index) => {
                return (
                  <React.Fragment key={index}>
                    <Box key={index} className={classes.headerContainer}>
                      <TextField
                        label={t("apiCallback.keyLabel")}
                        placeholder={t("apiCallback.keyPlaceholder")}
                        name={`headers.${index}.key`}
                        className={classes.headerTextField}
                        onChange={formik.handleChange}
                        value={header.key}
                        disabled={!canEdit}
                        error={
                          formik.touched.headers?.[index] &&
                          formik.errors.headers?.[index]
                            ? true
                            : false
                        }
                        onBlur={() => {
                          if (
                            formik.values.headers[index]?.key &&
                            formik.values.headers[index]?.value
                          ) {
                            formik.handleSubmit();
                          }
                        }}
                      />
                      <TextField
                        label={t("apiCallback.valueLabel")}
                        placeholder={t("apiCallback.valuePlaceholder")}
                        name={`headers.${index}.value`}
                        className={classes.headerTextField}
                        onChange={formik.handleChange}
                        value={header.value}
                        disabled={!canEdit}
                        error={
                          formik.touched.headers?.[index] &&
                          formik.errors.headers?.[index]
                            ? true
                            : false
                        }
                        onBlur={() => {
                          if (
                            formik.values.headers[index]?.key &&
                            formik.values.headers[index]?.value
                          ) {
                            formik.handleSubmit();
                          }
                        }}
                      />
                      <ToggleIconButton
                        size="large"
                        variant="outlined"
                        colorVariant="red"
                        disabled={!canEdit}
                        data-testid={`delete-header-${index}`}
                        data-label="remove header"
                        aria-label="remove header"
                        onClick={() => {
                          helpers.remove(index);
                          formik.handleSubmit();
                        }}
                      >
                        <TrashSolid />
                      </ToggleIconButton>
                    </Box>
                    {formik.touched.headers?.[index] &&
                    formik.errors.headers?.[index] ? (
                      <Typography
                        variant="caption"
                        color="error"
                        className={classes.headerErrorLabel}
                      >
                        {
                          (
                            formik.errors.headers?.[index] as {
                              key: string;
                              value: string;
                            }
                          ).key
                        }
                      </Typography>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </>
          )}
        />
      </FormikProvider>
    </>
  );
}
