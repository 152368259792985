import {
  BlueDataGrid,
  Breadcrumbs,
  Button,
  getRawAccountId,
  PlusOutlined,
  SearchIcon,
  TextField,
  Typography,
  useTranslation,
  useUrlServerPagination,
  XOutlined,
} from "@lumar/shared";
import { TFunction } from "i18next";
import { InputAdornment, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import {
  AccountOrderField,
  OrderDirection,
  useGetAccountsQuery,
} from "../../graphql";
import { Routes } from "../../_common/routing/routes";
import { NavigationBar } from "../NavigationBar";
import { useAdminGuard } from "../useAdminGuard";
import { accountsDataGridColumns } from "./accountsDataGridColumns";
import { CreateAccountDialog } from "./create-account/CreateAccountDialog";
import { DeactivateAccountDialog } from "./deactivate-account/DeactivateAccountDialog";
import { UpdateProjectsDialog } from "./update-projects/UpdateProjectsDialog";

const PAGE_SIZE = 10;

export const AdminPanelAccounts = (): JSX.Element => {
  useAdminGuard();

  const [createAccountOpened, setCreateAccountOpened] =
    useState<boolean>(false);
  const [deactivateAccountId, setDeactivateAccountId] = useState<
    string | undefined
  >(undefined);
  const [updateProjectsAccountId, setUpdateProjectsAccountId] = useState<
    string | undefined
  >(undefined);

  const { t } = useTranslation("adminPanel");
  const classes = useStyles();

  const { pageInfo, ...pagination } = useUrlServerPagination(
    PAGE_SIZE,
    AccountOrderField.CreatedAt,
    OrderDirection.Desc,
  );

  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const { data, loading } = useGetAccountsQuery({
    variables: {
      ...pageInfo,
      ...(debouncedSearchValue.length
        ? { filter: { name: { contains: debouncedSearchValue } } }
        : {}),
    },
  });

  return (
    <div>
      <NavigationBar />
      <section className={classes.container}>
        <Breadcrumbs
          breadcrumbItems={[
            { label: t("breadcrumbs.admin"), link: Routes.AdminPanel.ROUTE },
            { label: t("breadcrumbs.accounts"), link: Routes.AdminPanel.ROUTE },
          ]}
          className={classes.breadcrumbs}
        />
        <Typography variant="h1Bold" className={classes.title}>
          {t("title")}
        </Typography>
        <BlueDataGrid
          loading={loading}
          components={{
            ToolbarLeft,
            ToolbarRight,
          }}
          componentsProps={{
            toolbarRight: {
              t,
              onCreateAccount: () => setCreateAccountOpened(true),
            },
            toolbarLeft: { t, onSearchValueChange: setDebouncedSearchValue },
          }}
          rows={
            data?.getAccounts.edges.map((edge) => {
              const ssoId = edge.node.accountSsoClients?.nodes
                .map((n) => n.ssoClientAccountId)
                .join(", ");

              return {
                ...edge.node,
                id: getRawAccountId(edge.node.id),
                ssoId: ssoId?.length ? ssoId : "-",
                projects:
                  edge.node.subscription?.plan.settings.find(
                    (setting) => setting.code === "projects",
                  )?.limit ?? "-",
              };
            }) ?? []
          }
          columns={accountsDataGridColumns({
            t,
            onDeactivateAccount: (id) => setDeactivateAccountId(id),
            onUpdateProjects: (id) => setUpdateProjectsAccountId(id),
          })}
          pagination
          rowCount={data?.getAccounts.totalCount ?? 0}
          pageSize={PAGE_SIZE}
          sortingOrder={["desc", "asc", null]}
          paginationMode="server"
          {...pagination}
        />
      </section>
      <CreateAccountDialog
        open={createAccountOpened}
        onClose={() => setCreateAccountOpened(false)}
      />
      <DeactivateAccountDialog
        accountId={deactivateAccountId}
        open={!!deactivateAccountId}
        onClose={() => setDeactivateAccountId(undefined)}
      />
      <UpdateProjectsDialog
        accountId={updateProjectsAccountId}
        open={!!updateProjectsAccountId}
        onClose={() => setUpdateProjectsAccountId(undefined)}
      />
    </div>
  );
};

const ToolbarLeft = (props: {
  t: TFunction<"adminPanel">;
  onSearchValueChange: (value: string) => void;
}): JSX.Element => {
  const classes = useStyles();
  const [search, setSearch] = useState("");

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      props.onSearchValueChange(search);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [props, search]);

  return (
    <TextField
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      className={classes.input}
      placeholder={props.t("searchByName")}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: search ? (
          <InputAdornment
            className={classes.clear}
            onClick={() => setSearch("")}
            position="start"
            data-testid="search-remove-icon"
          >
            <XOutlined />
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

function ToolbarRight(props: {
  t: TFunction<"adminPanel">;
  onCreateAccount: () => void;
}): JSX.Element {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<PlusOutlined />}
      onClick={props.onCreateAccount}
    >
      {props.t("addAccount")}
    </Button>
  );
}

const useStyles = makeStyles(() => ({
  container: { padding: 24 },
  title: { marginBottom: 16 },
  breadcrumbs: { marginBottom: 16 },
  input: {
    width: 290,
  },
  clear: {
    cursor: "pointer",
    minHeight: 8,
  },
}));
