import {
  DestructiveMenuActionConfirmation,
  DotsHorizontalOutlined,
  RefreshSolid,
  ToggleIconButton,
  TrashOutlined,
  Typography,
  getRawUserId,
  useTranslation,
} from "@lumar/shared";
import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import React from "react";
import { useState } from "react";
import { AccountUsers } from "../useAccountUsers";

const useStyles = makeStyles((theme) => ({
  menu: {
    border: 0,
    width: 238,
    paddingLeft: 8,
    paddingRight: 8,
    boxShadow:
      "0px -1px 4px rgba(0, 0, 0, 0.35), 0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    color: theme.palette.grey[700],
  },
  removeIcon: {
    color: theme.palette.red[600],
    fontSize: theme.typography.pxToRem(20),
  },
  text: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[700],
  },
  resendIcon: {
    color: theme.palette.grey[400],
    fontSize: theme.typography.pxToRem(19),
  },
}));

export function ActionsCell({
  ...props
}: GridRenderCellParams & { accountUsers: AccountUsers }): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("teamPage");

  const { resendInvite, deleteUser } = props.accountUsers;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [isConfirmingDeletion, setIsConfirmingDeletion] = React.useState(false);

  const status = props.row.status;

  return (
    <>
      <ToggleIconButton
        size="large"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        data-testid="team-actions-btn"
        aria-label="open user actions menu"
      >
        <DotsHorizontalOutlined />
      </ToggleIconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        classes={{ paper: classes.menu }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {status === null && (
          <MenuItem onClick={() => resendInvite(props.row.id)}>
            <RefreshSolid className={classes.resendIcon} />
            <Typography variant="body2" className={classes.text}>
              {t("resendEmail")}
            </Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setIsConfirmingDeletion(true);
          }}
          data-testid="action-menu-delete"
        >
          <TrashOutlined className={classes.removeIcon} />
          <Typography variant="body2" className={classes.text}>
            {t("delete")}
          </Typography>
        </MenuItem>
      </Menu>
      {isConfirmingDeletion ? (
        <DestructiveMenuActionConfirmation
          title={t("destructiveMenu.title")}
          description={t("destructiveMenu.description")}
          onConfirm={() => {
            deleteUser(getRawUserId(props.row.id));
            setIsConfirmingDeletion(false);
          }}
          onCancel={() => {
            setAnchorEl(null);
            setIsConfirmingDeletion(false);
          }}
          anchorEl={anchorEl}
        />
      ) : null}
    </>
  );
}
