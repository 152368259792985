import { generatePath } from "react-router-dom";
import queryString, { StringifyOptions, UrlObject } from "query-string";

function createURL(object: UrlObject, options?: StringifyOptions): string {
  return queryString.stringifyUrl(object, options);
}

const ROUTE_MATCHER_PREFIX = `/accounts/:accountId([1-9]\\d*)`;

export const Routes = {
  Home: {
    ROUTE: "/",
  },
  Login: {
    ROUTE: "/login",
  },
  ResetPassword: {
    ROUTE: "/reset-password",
  },
  ConfirmEmail: {
    ROUTE: "/confirm",
  },
  Account: {
    ROUTE: ROUTE_MATCHER_PREFIX,
    getUrl({ accountId }: { accountId: string }): string {
      const url = generatePath(Routes.Account.ROUTE, { accountId });
      return createURL({ url, query: getInheritableSearchParams() });
    },
  },
  UserSettings: {
    ROUTE: ROUTE_MATCHER_PREFIX + "/user",
    getUrl({ accountId }: { accountId: string }): string {
      const url = generatePath(Routes.UserSettings.ROUTE, { accountId });
      return createURL({ url, query: getInheritableSearchParams() });
    },
  },
  ConnectedApps: {
    ROUTE: ROUTE_MATCHER_PREFIX + "/connected-apps",
    getUrl({ accountId }: { accountId: string }): string {
      const url = generatePath(Routes.ConnectedApps.ROUTE, { accountId });
      return createURL({ url, query: getInheritableSearchParams() });
    },
  },
  ApiAccess: {
    ROUTE: ROUTE_MATCHER_PREFIX + "/api-access",
    getUrl({ accountId }: { accountId: string }): string {
      const url = generatePath(Routes.ApiAccess.ROUTE, { accountId });
      return createURL({ url, query: getInheritableSearchParams() });
    },
  },
  Team: {
    ROUTE: ROUTE_MATCHER_PREFIX + "/team",
    getUrl({ accountId }: { accountId: string }): string {
      const url = generatePath(Routes.Team.ROUTE, { accountId });
      return createURL({ url, query: getInheritableSearchParams() });
    },
  },
  Subscription: {
    ROUTE: ROUTE_MATCHER_PREFIX + "/subscription",
    getUrl({
      accountId,
      requestAddon,
    }: {
      accountId: string;
      requestAddon?: string;
    }): string {
      const url = generatePath(Routes.Subscription.ROUTE, { accountId });
      return createURL({
        url,
        query: { requestAddon, ...getInheritableSearchParams() },
      });
    },
  },
  CreditUsage: {
    ROUTE: ROUTE_MATCHER_PREFIX + "/credit-usage",
    getUrl({ accountId }: { accountId: string }): string {
      const url = generatePath(Routes.CreditUsage.ROUTE, { accountId });
      return createURL({ url, query: getInheritableSearchParams() });
    },
  },
  AdminPanel: {
    ROUTE: "/admin",
  },
  AdminPanelAccounts: {
    ROUTE: "/admin/accounts",
  },
  AdminPanelCreditAllocations: {
    ROUTE: `/admin/accounts/:accountId([1-9]\\d*)/credits`,
    getUrl({ accountId }: { accountId: string }): string {
      const url = generatePath(Routes.AdminPanelCreditAllocations.ROUTE, {
        accountId,
      });
      return createURL({ url });
    },
  },
};

function getInheritableSearchParams(): Record<string, string> {
  const searchParams = new URLSearchParams(window.location.search);
  const referral = searchParams.get("referral");
  return referral ? { referral } : {};
}
