import { LumarLogo } from "@lumar/shared";
import { Box, makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    background: "white",
  },
  topPanel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    height: 210,
    // FIXME: Should be a mixin instead because this is not a shared component.
    background: `url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 1024" fill="none" preserveAspectRatio="none"><path d="M0 0H1440V1024H0V0Z" fill="url(%23paint0_radial_11783_53349)" /><defs><radialGradient id="paint0_radial_11783_53349" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1440 34.9097) rotate(174.271) scale(1771.7 15427.9)" ><stop stop-color="%23D2B8FF" /><stop offset="0.581598" stop-color="%2306025E" /><stop offset="0.951396" stop-color="%23020034" /></radialGradient></defs></svg>')`,
    ...theme.overrides?.OnboardingContainer?.topPanel,
  },
  topPanelSvg: {
    position: "absolute",
    inset: 0,
    minWidth: "100%",
    maxWidth: "100%",
    height: 210,
  },
  logo: {
    width: 200,
    height: 34.51,
    color: "white",
    marginTop: -48,
    zIndex: 1,
  },
  container: {
    maxWidth: 480,
    margin: "0 auto",
  },
  paper: {
    backgroundColor: "white",
    minHeight: 200,
    borderRadius: 8,
    padding: 32,
    marginTop: -56,
    boxShadow: "0px 20px 25px -5px #0000001A",
    position: "relative",
    zIndex: 1,
  },
}));

type OnboardingContainerProps = {
  children: React.ReactNode;
};

export function OnboardingContainer(
  props: OnboardingContainerProps,
): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const LogoComponent = theme.props?.Logo?.LogoComponent || LumarLogo;

  return (
    <Box className={classes.root}>
      <Box className={classes.topPanel}>
        <LogoComponent className={classes.logo} />
      </Box>
      <main className={classes.container}>
        <Box className={classes.paper}>{props.children}</Box>
      </main>
    </Box>
  );
}
