import { Snackbar, useTranslation } from "@lumar/shared";
import { FormikProps, useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import {
  useConfirmUserEmailMutation,
  useCreateUserPasswordMutation,
} from "../graphql";

import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { Routes } from "../_common/routing/routes";

type FormValues = {
  password: string;
  confirmPassword: string;
};

type ConfirmEmailReturnType = {
  formik: FormikProps<FormValues>;
  success: boolean;
};

export function useConfirmEmailForm(): ConfirmEmailReturnType {
  const { t } = useTranslation("confirmEmail");
  const [success, setSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { search } = useLocation();
  const history = useHistory();
  const [token, setToken] = useState<string | undefined>();
  // This param cannot be "token" because we have a logic on the gql-client
  // that gets all "token" params in URLs to use it directly as an authorization token
  // in the headers. In this case, this call is unauthorized and the token is only used
  // to confirm the email passing it into the mutation, then that mutation
  // is the one that actually return us an authenticated token of the session that can
  // be used for the next authenticated requests - Alex S.
  const emailToken = new URLSearchParams(search).get("emailToken");

  const validationSchema: Yup.Schema<FormValues> = Yup.object({
    password: Yup.string()
      .max(255, t("form.validation.passwordMaxLength"))
      .matches(/(.*[A-Z].*)/, t("form.validation.passwordUppercase"))
      .matches(/(.*\d.*)/, t("form.validation.passwordNumber"))
      .min(8, t("form.validation.passwordLength"))
      .required(t("form.validation.passwordRequired")),
    confirmPassword: Yup.string()
      .max(255, t("form.validation.confirmPasswordMaxLength"))
      .oneOf([Yup.ref("password")], t("form.validation.passwordMatch"))
      .required(t("form.validation.confirmPasswordRequired")),
  });

  const [confirmUserEmail] = useConfirmUserEmailMutation({
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
    onCompleted: (data) => {
      const token = data.confirmEmail.session.token;
      setToken(token);
    },
  });

  const [createPassword, { data }] = useCreateUserPasswordMutation({
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
    onCompleted: (data) => {
      if (data.updatePassword.passwordUpdated) {
        setSuccess(true);
      }
    },
  });

  const passwordUpdateErrors = data?.updatePassword.passwordValidationErrors;

  useEffect(() => {
    if (passwordUpdateErrors && passwordUpdateErrors.length > 0) {
      enqueueSnackbar(
        <Snackbar variant="error" title={passwordUpdateErrors[0]} />,
      );
    }
  }, [passwordUpdateErrors, enqueueSnackbar]);

  useLayoutEffect(() => {
    if (!emailToken) {
      history.replace(Routes.Login.ROUTE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik<FormValues>({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values) => {
      try {
        if (!token) {
          const { data } = await confirmUserEmail({
            variables: { token: emailToken ?? "" },
          });

          if (!data?.confirmEmail.session.token) {
            return;
          }

          await createPassword({
            context: {
              headers: {
                "X-Auth-Token": data.confirmEmail.session.token,
              },
            },
            variables: {
              password: values.password,
            },
          });
        } else {
          await createPassword({
            context: {
              headers: {
                "X-Auth-Token": token,
              },
            },
            variables: {
              password: values.password,
            },
          });
        }
      } catch {}
    },
  });

  return { formik, success };
}
