import { Snackbar, ApolloCache } from "@lumar/shared";
import { TFunction } from "i18next";
import { SnackbarProvider } from "notistack";
import {
  GetConnectedAppsDocument,
  GetConnectedAppsQuery,
  RemoveGoogleConnectionMutation,
  useAddGoogleConnectionMutation,
  useRemoveGoogleConnectionMutation,
} from "../../graphql";
import { ConnectedApps, GoogleAccount } from "./types";

type GoogleAccounts = Pick<
  ConnectedApps,
  "googleAccounts" | "addGoogleAccount" | "removeGoogleAccount"
>;

export function useGoogleAccounts(
  data: GetConnectedAppsQuery | undefined,
  enqueueSnackbar: SnackbarProvider["enqueueSnackbar"],
  t: TFunction<"connectedApps">,
): GoogleAccounts {
  const [addGoogleConnection] = useAddGoogleConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("google.errorAdd")} />,
      ),
  });

  const [removeGoogleConnection] = useRemoveGoogleConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("google.errorRemove")} />,
      ),
  });

  async function addGoogleAccount(): Promise<boolean> {
    const response = await addGoogleConnection({
      variables: {
        redirectUrl: window.location.href,
      },
    });

    const googleLink = response.data?.createGoogleConnection.authUrl;
    if (!googleLink) return false;

    const link = document.createElement("a");
    link.setAttribute("href", googleLink);
    link.click();
    link.remove();
    return true;
  }

  async function removeGoogleAccount(
    googleConnectionId: string,
  ): Promise<boolean> {
    const result = await removeGoogleConnection({
      variables: { googleConnectionId },
      optimisticResponse: {
        deleteGoogleConnection: {
          googleConnection: {
            id: googleConnectionId,
          },
        },
      },
      update: (cache, { data }) => removeGoogleAccountFromCache(cache, data),
    });

    return Boolean(result.data?.deleteGoogleConnection.googleConnection.id);
  }

  return {
    googleAccounts: getGoogleAccounts(data),
    addGoogleAccount,
    removeGoogleAccount,
  };
}

const GOOGLE_ANALYTICS_SCOPE =
  "https://www.googleapis.com/auth/analytics.readonly";
const GOOGLE_SEARCH_CONSOLE_SCOPE =
  "https://www.googleapis.com/auth/webmasters.readonly";

export function getGoogleAccounts(
  data: GetConnectedAppsQuery | undefined,
): GoogleAccount[] {
  return (
    data?.me?.googleConnections?.nodes.map((x) => ({
      id: x.id,
      name: x.name,
      isValid: x.isWorking ?? false,
      isAnalyticsAvailable: Boolean(
        x.scopes.find((s) => s.name === GOOGLE_ANALYTICS_SCOPE),
      ),
      isSearchConsoleAvailable: Boolean(
        x.scopes.find((s) => s.name === GOOGLE_SEARCH_CONSOLE_SCOPE),
      ),
    })) ?? []
  );
}

function removeGoogleAccountFromCache(
  cache: ApolloCache<RemoveGoogleConnectionMutation>,
  data: RemoveGoogleConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        googleConnections: {
          ...cachedData.me.googleConnections,
          nodes: cachedData.me.googleConnections.nodes.filter(
            (x) => x.id !== data?.deleteGoogleConnection.googleConnection.id,
          ),
        },
      },
    },
  });
}
