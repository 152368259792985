/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from "@lumar/shared";
import { useCallback } from "react";

export function useSubmitSubscriptionEditForm(): (
  requestedChanges: Record<string, any>,
) => Promise<void> {
  const { t } = useTranslation("subscription");

  return useCallback(
    async function submitForm(
      requestedChanges: Record<string, any>,
    ): Promise<void> {
      const response = await fetch(
        "https://forms.hubspot.com/uploads/form/v2/7698075/a10317fa-e154-4bbb-bcdd-53b4b3df7922",
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          method: "POST",
          body: new URLSearchParams(requestedChanges).toString(),
        },
      );

      if (!response.ok) {
        throw t("couldntSendTheRequest", {
          code: response.status,
        });
      }
    },
    [t],
  );
}
