import {
  BlueDataGrid,
  Breadcrumbs,
  Button,
  PlusOutlined,
  Typography,
  useTranslation,
  useUrlServerPagination,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import {
  CreditAllocationOrderField,
  OrderDirection,
  useGetCreditAllocationsQuery,
} from "../../graphql";
import { Routes } from "../../_common/routing/routes";
import { NavigationBar } from "../NavigationBar";
import { useAdminGuard } from "../useAdminGuard";
import { CreateCreditAllocationDialog } from "./create-credit-allocation/CreateCreditAllocationDialog";
import { creditAllocationsDataGridColumns } from "./creditAllocationsDataGridColumns";
import {
  CreditAllocationDefaultValues,
  UpdateCreditAllocationDialog,
} from "./update-credit-allocation/UpdateCreditAllocationDialog";

const PAGE_SIZE = 10;

export function AdminPanelCreditAllocations(): JSX.Element {
  useAdminGuard();
  const classes = useStyles();
  const { t } = useTranslation("adminPanel");

  const [createCreditAllocationOpened, setCreateCreditAllocationOpened] =
    useState<boolean>(false);
  const [
    updateCreditAllocationDefaultValues,
    setUpdateCreditAllocationDefaultValues,
  ] = useState<CreditAllocationDefaultValues | undefined>(undefined);

  const { accountId } = useParams<{ accountId: string }>();

  const { pageInfo, ...pagination } = useUrlServerPagination(
    PAGE_SIZE,
    CreditAllocationOrderField.StartDate,
    OrderDirection.Desc,
  );

  const { data, loading } = useGetCreditAllocationsQuery({
    variables: { accountId, ...pageInfo },
  });

  if (!accountId) {
    return <Redirect to={Routes.AdminPanelAccounts.ROUTE} />;
  }

  return (
    <div>
      <NavigationBar />
      <section className={classes.container}>
        <Breadcrumbs
          breadcrumbItems={[
            {
              label: t("breadcrumbs.admin"),
              link: Routes.AdminPanel.ROUTE,
            },
            {
              label: t("breadcrumbs.accounts"),
              link: Routes.AdminPanelAccounts.ROUTE,
            },
            { label: accountId },
            {
              label: t("breadcrumbs.credits"),
              link: Routes.AdminPanelCreditAllocations.getUrl({
                accountId,
              }),
            },
          ]}
          className={classes.breadcrumbs}
        />

        <Typography variant="h1Bold" className={classes.title}>
          {t("title")}
        </Typography>
        <BlueDataGrid
          loading={loading}
          components={{
            ToolbarRight: () => (
              <Button
                variant="contained"
                color="primary"
                startIcon={<PlusOutlined />}
                onClick={() => setCreateCreditAllocationOpened(true)}
              >
                {t("addCredits")}
              </Button>
            ),
          }}
          rows={
            data?.getAccount?.creditAllocations?.edges.map((edge) => ({
              id: edge.node.id,
              startDate: edge.node.startDate,
              endDate: edge.node.endDate,
              creditsUsed: edge.node.creditsUsed,
              creditsAllocated: edge.node.creditsAllocated,
            })) ?? []
          }
          columns={creditAllocationsDataGridColumns({
            t,
            onUpdateCreditAllocation: (values) => {
              setUpdateCreditAllocationDefaultValues(values);
            },
          })}
          pagination
          rowCount={data?.getAccount?.creditAllocations?.totalCount ?? 0}
          pageSize={PAGE_SIZE}
          sortingOrder={["desc", "asc", null]}
          paginationMode="server"
          {...pagination}
        />
      </section>
      <CreateCreditAllocationDialog
        open={createCreditAllocationOpened}
        onClose={() => setCreateCreditAllocationOpened(false)}
      />
      <UpdateCreditAllocationDialog
        creditAllocation={updateCreditAllocationDefaultValues}
        open={!!updateCreditAllocationDefaultValues}
        onClose={() => setUpdateCreditAllocationDefaultValues(undefined)}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  banner: { backgroundColor: theme.palette.primary.main, padding: 16 },
  bannerLink: { color: theme.palette.common.white },
  container: { padding: 24 },
  title: { marginBottom: 16 },
  breadcrumbs: { marginBottom: 16 },
}));
