import { NestedNavigationMenu } from "@lumar/shared";
import { AccountInfo } from "./AccountInfo";
import { useNavigationSections } from "./useNavigationSections";

export function AccountNavContent(): JSX.Element {
  const config = useNavigationSections();
  return (
    <>
      <AccountInfo />
      <nav>
        <NestedNavigationMenu sections={config} />
      </nav>
    </>
  );
}
