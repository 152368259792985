import React from "react";
import { useTranslation, ExternalLinkSolid } from "@lumar/shared";
import { useHistory } from "react-router-dom";

import { ConnectedAppsContainer } from "./ConnectedAppsContainer";
import ZapierLogo from "./logo/Zapier.png";

export function Zapier(): JSX.Element {
  const { t } = useTranslation("connectedApps");
  const history = useHistory();

  return (
    <ConnectedAppsContainer
      logo={ZapierLogo}
      logoAlt="Zapier logo"
      title={t("zapier.title")}
      addon
      addonMessage={t("zapier.description")}
      addonLabel={t("zapier.viewTemplates")}
      onRequest={() => {
        const params = new URLSearchParams();
        params.set("view", "zapierTemplates");
        history.push({ search: params.toString() });
      }}
      requestIcon={ExternalLinkSolid}
      data-testid="connected-apps-zapier"
    />
  );
}
