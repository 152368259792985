import { Snackbar, useSession, useTranslation } from "@lumar/shared";
import { FormikProps, useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useUpdateUserDetailsMutation } from "../graphql";

type FormValues = {
  firstName: string;
  lastName: string;
  jobTitle: string;
};

type UserDetailsReturnType = {
  formik: FormikProps<FormValues>;
};

export function useUserDetailsForm(
  jobTitles: { value: string; label: string }[],
  onSuccess: () => void,
): UserDetailsReturnType {
  const session = useSession();

  const { t } = useTranslation("userDetails");

  const { enqueueSnackbar } = useSnackbar();

  const validationSchema: Yup.Schema<FormValues> = Yup.object({
    firstName: Yup.string()
      .max(255, t("form.validation.firstNameMaxLength"))
      .required(t("form.validation.firstNameRequired")),
    lastName: Yup.string()
      .max(255, t("form.validation.lastNameMaxLength"))
      .required(t("form.validation.lastNameRequired")),
    jobTitle: Yup.string()
      .oneOf(jobTitles.map((jobTitle) => jobTitle.label))
      .required(t("form.validation.jobTitleRequired")),
  });

  const [updateUserDetails] = useUpdateUserDetailsMutation({
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
    onCompleted: () => {
      onSuccess();
    },
    refetchQueries: ["Session"],
  });

  const formik = useFormik<FormValues>({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      firstName: session?.firstName ?? "",
      lastName: session?.lastName ?? "",
      jobTitle: session?.jobTitle ?? jobTitles[0].label,
    },
    onSubmit: async (values) => {
      await updateUserDetails({
        variables: {
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: values.jobTitle,
        },
      });
    },
  });

  return { formik };
}
