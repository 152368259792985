import { Dialog, Snackbar, TextField, useTranslation } from "@lumar/shared";
import { CircularProgress, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { useEffect } from "react";
import * as Yup from "yup";
import {
  useAccountSubscriptionPlanLazyQuery,
  useUpdateAccountPlanMutation,
} from "../../../graphql";

type Props = {
  accountId?: string;
  open: boolean;
  onClose: () => void;
};

export const UpdateProjectsDialog = (props: Props): JSX.Element => {
  if (props.open && !props.accountId) {
    throw new Error("Account id is required");
  }

  const classes = useStyles();
  const { t } = useTranslation("adminPanel");

  const validationSchema = Yup.object({
    projects: Yup.number()
      .required(t("updateProjects.errors.mandatory"))
      .integer(t("updateProjects.errors.integer"))
      .min(1, t("updateProjects.errors.minProjects"))
      .max(100, t("updateProjects.errors.maxProjects")),
  });

  const onCloseDialog = (): void => {
    formik.resetForm();
    props.onClose();
  };

  const [fetchSubscriptionPlan, { loading, data }] =
    useAccountSubscriptionPlanLazyQuery({
      variables: {
        accountId: props.accountId,
      },
      onError: (error) => {
        enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
      },
    });

  const [updateAccountPlan] = useUpdateAccountPlanMutation({
    onCompleted: () => {
      enqueueSnackbar(
        <Snackbar variant="success" title={t("updateProjects.success")} />,
      );
      onCloseDialog();
    },
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
  });

  const plan = data?.getAccount?.subscription?.plan;
  const projects =
    plan?.settings.find((setting) => setting.code === "projects")?.limit ?? 0;

  const formik = useFormik({
    initialValues: { projects },
    onSubmit: async (values: Yup.InferType<typeof validationSchema>) => {
      if (!plan) {
        enqueueSnackbar(
          <Snackbar
            variant="error"
            title={t("updateProjects.errors.defaultSettings")}
          />,
        );
        return onCloseDialog();
      }

      const defaultSettings = plan.settings
        .filter((settings) => settings.code !== "projects")
        .map((settings) => ({
          code: settings.code,
          ...(settings.limit ? { limit: settings.limit } : {}),
        }));

      await updateAccountPlan({
        variables: {
          accountId: props.accountId,
          planCode: plan.code,
          planSettings: [
            ...defaultSettings,
            {
              code: "projects",
              limit: values.projects,
            },
          ],
        },
      });
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
  });

  useEffect(() => {
    if (props.accountId) {
      fetchSubscriptionPlan({ variables: { accountId: props.accountId } });
    }
  }, [props.accountId, fetchSubscriptionPlan]);

  return (
    <Dialog
      open={props.open}
      onCloseViaIcon={onCloseDialog}
      title={t("updateProjects.title")}
      buttons={[
        {
          variant: "outlined",
          color: "default",
          title: t("updateProjects.cancel"),
          onClick: onCloseDialog,
        },
        {
          disabled: !formik.isValid || formik.isSubmitting,
          variant: "contained",
          color: "primary",
          title: t("updateProjects.confirm"),
          onClick: () => formik.handleSubmit(),
        },
      ]}
    >
      <div className={classes.container}>
        <form
          onSubmit={formik.handleSubmit}
          className={clsx(
            classes.form,
            loading ? classes.formInvisible : undefined,
          )}
        >
          <div className={classes.inputGroup}>
            <TextField
              type="number"
              value={formik.values.projects}
              label={t("updateProjects.projects")}
              name="projects"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <span className={classes.helperText}>Max value is 100</span>
          </div>
        </form>
        {loading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : null}
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 12,
    position: "relative",
  },
  loader: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
  },
  form: {
    minWidth: 240,
    display: "grid",
    gridGap: 16,
  },
  formInvisible: {
    visibility: "hidden",
  },
  helperText: {
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.54)",
  },
}));
