/* eslint-disable fp/no-mutating-methods */
import {
  ArrowNarrowLeftSolid,
  BatteryHighOutlined,
  CreditCardOutlined,
  insertIf,
  LightningBoltOutlined,
  NestedNavigationMenuParentItem,
  NestedNavigationMenuSection,
  OfficeBuildingOutlined,
  PuzzleOutlined,
  useBrand,
  UserGroupOutlined,
  UserOutlined,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { useParams } from "react-router-dom";
import { Routes } from "../_common/routing/routes";
import { useEnteredFromApp } from "../_common/routing/useEnteredFromApp";
import { History } from "history";
import { RoleCode } from "../graphql";
import { PermissionCode } from "@lumar/shared/dist/graphql";

export function useNavigationSections(): NestedNavigationMenuSection[] {
  const { t } = useTranslation("sidebar");
  const { hasSufficientRole, hasPermission } = useSession();
  const brand = useBrand();
  const routeParams = useParams<{ accountId: string }>();
  const goBackToAppSection = useGoBackToAppSection();

  const config: NestedNavigationMenuSection[] = [
    goBackToAppSection,
    ...insertIf(
      hasPermission(PermissionCode.SsoClient) &&
        brand.featureAvailability.adminPanel,
      {
        id: "admin-section",
        showDivider: true,
        items: [
          {
            id: "admin-panel",
            name: t("adminPanel"),
            href: Routes.AdminPanel.ROUTE,
            icon: UserOutlined,
            selected: isItemSelected,
          },
        ],
      },
    ),
    ...insertIf(
      (brand.featureAvailability.myProfilePage &&
        brand.featureAvailability.apiAccessPage) ||
        hasSufficientRole(RoleCode.Editor),
      {
        id: "user-section",
        name: "User",
        items: [
          ...insertIf(brand.featureAvailability.myProfilePage, {
            id: "my-profile",
            name: t("myProfile"),
            href: Routes.UserSettings.getUrl(routeParams),
            icon: UserOutlined,
            selected: isItemSelected,
          }),
          ...insertIf(hasSufficientRole(RoleCode.Editor), {
            id: "connected-apps",
            name: t("connectedApps"),
            href: Routes.ConnectedApps.getUrl(routeParams),
            icon: PuzzleOutlined,
            selected: isItemSelected,
          }),
          ...insertIf(brand.featureAvailability.apiAccessPage, {
            id: "api-access",
            name: t("apiAccess"),
            href: Routes.ApiAccess.getUrl(routeParams),
            icon: LightningBoltOutlined,
            selected: isItemSelected,
          }),
        ],
      },
    ),
    {
      id: "organization-section",
      name: "Organization",
      items: [
        {
          id: "account",
          name: t("account"),
          href: Routes.Account.getUrl(routeParams),
          icon: OfficeBuildingOutlined,
          selected: isItemSelected,
        },
        ...insertIf(
          brand.featureAvailability.teamsPage &&
            hasSufficientRole(RoleCode.Admin),
          {
            id: "team",
            name: t("team"),
            href: Routes.Team.getUrl(routeParams),
            icon: UserGroupOutlined,
            selected: isItemSelected,
          },
        ),
        ...insertIf(hasSufficientRole(RoleCode.Admin), {
          id: "credit-usage",
          name: t("creditUsage"),
          href: Routes.CreditUsage.getUrl(routeParams),
          icon: BatteryHighOutlined,
          selected: isItemSelected,
        }),
        ...insertIf(hasSufficientRole(RoleCode.Editor), {
          id: "subscription",
          name: t("subscription"),
          href: Routes.Subscription.getUrl(routeParams),
          icon: CreditCardOutlined,
          selected: isItemSelected,
        }),
      ],
    },
  ];

  return config;
}

function isItemSelected(
  item: NestedNavigationMenuParentItem,
  history: History,
): boolean {
  const hrefUrl = new URL(item.href || "", window.location.origin);
  return hrefUrl.pathname === history.location.pathname;
}

function useGoBackToAppSection(): NestedNavigationMenuSection {
  const enteredFrom = useEnteredFromApp();
  const { t } = useTranslation("sidebar");

  if (!enteredFrom) {
    return { id: "go-back-to-section", items: [] };
  }

  const appNameToLocalizedName: Record<
    "analyze" | "protect" | "monitor" | "impact",
    string
  > = {
    analyze: t("goBackToAnalyze"),
    monitor: t("goBackToMonitor"),
    protect: t("goBackToProtect"),
    impact: t("goBackToImpact"),
  };

  return {
    id: "go-back-to-section",
    showDivider: true,
    items: [
      {
        id: `go-back-to-${enteredFrom.app}`,
        name: appNameToLocalizedName[enteredFrom.app],
        href: enteredFrom.href,
        icon: ArrowNarrowLeftSolid,
      },
    ],
  };
}
