import {
  AccountMenuItem,
  AccountMenuItems,
  BatteryHighOutlined,
  CreditCardOutlined,
  getRawAccountId,
  insertIf,
  LightningBoltOutlined,
  LocaleOutlined,
  LogoutOutlined,
  OfficeBuildingOutlined,
  PuzzleOutlined,
  supportedLanguages,
  useAccountSelectionSubMenu,
  useAdminAccountSearch,
  useBrand,
  UserArrowRightOutlined,
  UserGroupOutlined,
  UserOutlined,
  useSession,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { RoleCode } from "../../../graphql";
import { Routes } from "../../routing/routes";

const useStyles = makeStyles((theme) => ({
  icon: {
    height: "20px",
  },
  logout: {
    color: theme.palette.red[500],
  },
}));

interface NavigationMenuItem extends AccountMenuItem {
  minimumRole?: RoleCode;
  availableForSharedLinkUser?: boolean;
}

type NavigationMenuItems = {
  name?: string;
  items: (NavigationMenuItem | undefined)[];
}[];

export function useNavigationMenuItems(): AccountMenuItems {
  const { t, i18n } = useTranslation("navigation");
  const classes = useStyles();
  const brand = useBrand();

  const { accountId } = useParams<{ accountId: string }>();

  const session = useSession();

  const history = useHistory();
  const languageLabel = supportedLanguages.find(
    (lng) => lng.code === i18n.language,
  )?.name;

  // eslint-disable-next-line fp/no-mutating-methods
  const availableAccounts = session.allRelationships.sort((a, b) =>
    a.account.name.localeCompare(b.account.name),
  );
  const shouldShowAccountSwitcher =
    session.isDeepCrawlAdmin ||
    Boolean(
      availableAccounts.find(
        ({ account }) => getRawAccountId(account.id) !== accountId,
      ),
    );

  function handleAccountSwitch(id: string): void {
    if (id === accountId) return;
    history.push(
      window.location.pathname.replace(/\/accounts\/\d+/, "/accounts/" + id),
    );
  }

  const accountsSubMenu = useAccountSelectionSubMenu({
    accounts: availableAccounts.map((x) => ({
      id: getRawAccountId(x.account.id),
      name: x.account.name,
    })),
    selectedAccountId: accountId,
    onAccountSelected: handleAccountSwitch,
    isDeepCrawlAdmin: session.isDeepCrawlAdmin,
    searchPlaceholder: t("accountSearch"),
    noResultMessage: t("noAccountFound"),
    formatValue: (id) => t("id", { accountId: id }),
    adminAccountSearch: useAdminAccountSearch(),
    dataAttributes: {
      "data-testid": "account-switcher-option",
    },
  });

  const languageSubMenu: AccountMenuItems = [
    {
      items: supportedLanguages.map((lng) => ({
        name: lng.name,
        checked: i18n.language === lng.code,
        onClick: () => {
          i18n.changeLanguage(lng.code);
        },
      })),
    },
  ];

  const menuItemsGroups: NavigationMenuItems = [
    {
      items: [
        shouldShowAccountSwitcher
          ? {
              name: t("switchAccount"),
              icon: <UserArrowRightOutlined className={classes.icon} />,
              minimumRole: RoleCode.Viewer,
              dataAttributes: { "data-testid": "account-switcher" },
              subMenu: accountsSubMenu,
            }
          : undefined,
      ],
    },
    {
      name: t("userSettings"),
      items: [
        ...insertIf(brand.featureAvailability.myProfilePage, {
          name: t("user"),
          icon: <UserOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-user" },
          link: Routes.UserSettings.getUrl({ accountId }),
          minimumRole: RoleCode.Viewer,
        }),
        {
          name: t("language"),
          icon: <LocaleOutlined className={classes.icon} />,
          value: languageLabel,
          dataAttributes: { "data-pendo": "c-headernav-accounts-language" },
          minimumRole: RoleCode.Viewer,
          subMenu: languageSubMenu,
        },
        {
          name: t("connectedApps"),
          icon: <PuzzleOutlined className={classes.icon} />,
          dataAttributes: {
            "data-pendo": "c-headernav-accounts-connectedapps",
          },
          link: Routes.ConnectedApps.getUrl({ accountId }),
          minimumRole: RoleCode.Editor,
        },
        ...insertIf(brand.featureAvailability.apiAccessPage, {
          name: t("apiAccess"),
          icon: <LightningBoltOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-api" },
          link: Routes.ApiAccess.getUrl({ accountId }),
          minimumRole: RoleCode.Viewer,
        }),
      ],
    },
    {
      name: t("accountSettings"),
      items: [
        {
          name: t("account"),
          icon: <OfficeBuildingOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-account" },
          link: Routes.Account.getUrl({ accountId }),
          minimumRole: RoleCode.Viewer,
        },
        ...insertIf(brand.featureAvailability.teamsPage, {
          name: t("team"),
          icon: <UserGroupOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-org" },
          link: Routes.Team.getUrl({ accountId }),
          minimumRole: RoleCode.Admin,
        }),
        {
          name: t("subscription"),
          icon: <CreditCardOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-subscription" },
          link: Routes.Subscription.getUrl({ accountId }),
          minimumRole: RoleCode.Admin,
        },
        {
          name: t("creditUsage"),
          icon: <BatteryHighOutlined className={classes.icon} />,
          dataAttributes: { "data-pendo": "c-headernav-accounts-credit" },
          link: Routes.CreditUsage.getUrl({ accountId }),
          minimumRole: RoleCode.Admin,
        },
      ],
    },
    {
      items: [
        {
          name: t("logOut"),
          icon: (
            <LogoutOutlined className={clsx(classes.icon, classes.logout)} />
          ),
          dataAttributes: {
            "data-testid": "logout-link",
            "data-pendo": "c-headernav-accounts-logout",
          },
          onClick: () => {
            session.invalidateSession();
          },
          minimumRole: RoleCode.Viewer,
          availableForSharedLinkUser: false,
        },
      ],
    },
  ];

  function filterByRole(
    item: NavigationMenuItem | undefined,
  ): item is NavigationMenuItem {
    if (item === undefined) return false;
    return session.isUsingSharedLink
      ? (item.availableForSharedLinkUser ?? false)
      : session.hasSufficientRole(item.minimumRole);
  }

  return menuItemsGroups.reduce((result, group) => {
    const items = group.items.filter(filterByRole);
    return items.length ? [...result, { ...group, items }] : result;
  }, [] as AccountMenuItems);
}
