import React from "react";
import { RouteProps, Route } from "react-router-dom";
import { AppLayout } from "@lumar/shared";
import { AccountNavContent } from "../../sidebar/AccountNavContent";
import { UserDetailsWrapper } from "../../user-details/UserDetailsWrapper";

interface Props extends Omit<RouteProps, "render" | "children" | "component"> {
  children: React.ReactNode;
}

export function AppRoute({ children, ...routeProps }: Props): JSX.Element {
  return (
    <Route {...routeProps}>
      <UserDetailsWrapper>
        <AppLayout app="accounts" sidebarContentComponent={AccountNavContent}>
          {children}
        </AppLayout>
      </UserDetailsWrapper>
    </Route>
  );
}
