import React from "react";
/**
 * Fixes the issue mentioned here: https://github.com/mui/material-ui/issues/19450
 */
export function RootAriaLabelMuiBugFix(props) {
    const root = document.querySelector(props.rootSelector);
    React.useEffect(() => {
        if (!root) {
            console.error("RootAriaLabelMuiBugFix couldn't find the element.");
            return;
        }
        const observer = new MutationObserver(() => {
            if (root.getAttribute("aria-hidden")) {
                root.removeAttribute("aria-hidden");
            }
        });
        observer.observe(root, {
            attributeFilter: ["aria-hidden"],
        });
        return () => {
            observer.disconnect();
        };
    }, [root]);
    return null;
}
