import React from "react";
import { useTranslation, Chip, Typography } from "@lumar/shared";

import AdobeLogo from "./logo/Adobe.png";
import { AdobeJwtAccount } from "./data/types";
import {
  AccountStatus,
  Cell,
  ConnectedAppsContainer,
  EditButton,
  RemoveButton,
} from "./ConnectedAppsContainer";
import { AdobeAccountDialog } from "./AdobeAccountDialog";
import { ConnectedAppsContext } from "./ConnectedApps";
import { makeStyles, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  secondaryLabel: {
    color: theme.palette.grey[500],
  },
}));

export function AdobeAccounts(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");

  const {
    adobeAccounts,
    addAdobeAccount,
    updateAdobeAccount,
    removeAdobeAccount,
    deleteAdobeAccount,
  } = React.useContext(ConnectedAppsContext);

  const [account, setAccount] = React.useState<AdobeJwtAccount | undefined>(
    undefined,
  );

  return (
    <>
      <ConnectedAppsContainer
        logo={AdobeLogo}
        logoAlt="Adobe logo"
        title={t("adobe.title")}
        noAccountMessage={t("adobe.noAccountMessage")}
        addon="adobe-analytics"
        addonMessage={t("adobe.description")}
        buttonLabel={t("adobe.addAccount")}
        onClick={() =>
          setAccount({
            type: "modern",
            id: "",
            clientSecret: "",
            clientId: "",
            orgId: "",
            privateKey: "",
            technicalAccountId: "",
            isValid: true,
          })
        }
        accounts={adobeAccounts}
        renderRow={(account) => {
          if (account.type === "deprecated") {
            return (
              <>
                <Cell>
                  <Typography variant="captionSemiBold">
                    {account.username}
                  </Typography>
                  <br />
                  <Chip label="deprecated" color="red" />
                </Cell>
                <Cell>
                  <Typography
                    variant="captionMedium"
                    className={classes.secondaryLabel}
                  >
                    {account.secret}
                  </Typography>
                </Cell>
                <Cell width="12%">
                  <AccountStatus isValid={account.isValid} />
                </Cell>
                <Cell width="80px">
                  <Tooltip title="You can't edit deprecated connections.">
                    <div style={{ display: "inline-block" }}>
                      <EditButton disabled />
                    </div>
                  </Tooltip>
                  <RemoveButton
                    title={t("adobe.removeTitle")}
                    description={t("adobe.removeDescription")}
                    onRemove={() => removeAdobeAccount(account.id)}
                  />
                </Cell>
              </>
            );
          }

          return (
            <>
              <Cell>
                <Typography variant="captionSemiBold" component="div">
                  {account.clientId}
                </Typography>
                <Typography variant="captionSemiBold" component="div">
                  {account.technicalAccountId}
                </Typography>
                <Typography variant="captionSemiBold" component="div">
                  {account.orgId}
                </Typography>
              </Cell>
              <Cell>
                <Typography
                  variant="captionMedium"
                  className={classes.secondaryLabel}
                  component="div"
                >
                  {account.clientSecret}
                </Typography>
                <Typography
                  variant="captionMedium"
                  className={classes.secondaryLabel}
                  component="div"
                >
                  {account.privateKey}
                </Typography>
              </Cell>
              <Cell width="12%">
                <AccountStatus isValid={account.isValid} />
              </Cell>
              <Cell width="80px">
                <EditButton onEdit={() => setAccount(account)} />
                <RemoveButton
                  title={t("adobe.removeTitle")}
                  description={t("adobe.removeDescription")}
                  onRemove={() => deleteAdobeAccount(account.id)}
                />
              </Cell>
            </>
          );
        }}
        data-testid="connected-apps-adobe"
      />
      <AdobeAccountDialog
        account={account}
        addAccount={addAdobeAccount}
        updateAccount={updateAdobeAccount}
        onClose={() => setAccount(undefined)}
      />
    </>
  );
}
