import {
  DatePicker,
  Dialog,
  Snackbar,
  TextField,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router";
import * as Yup from "yup";
import { useCreateCreditAllocationForSsoAccountMutation } from "../../../graphql";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CreateCreditAllocationDialog = (props: Props): JSX.Element => {
  const { accountId } = useParams<{ accountId: string | undefined }>();

  if (!accountId) {
    throw new Error("Account id is required");
  }

  const classes = useStyles();

  const { t } = useTranslation("adminPanel");

  const validationSchema = Yup.object({
    accountId: Yup.string().required(
      t("createCreditAllocation.errors.mandatory"),
    ),
    creditsAllocated: Yup.number()
      .min(1, t("createCreditAllocation.errors.positiveInteger"))
      .required(t("createCreditAllocation.errors.mandatory")),
    startDate: Yup.date().required(
      t("createCreditAllocation.errors.mandatory"),
    ),
    endDate: Yup.date()
      .min(
        Yup.ref("startDate"),
        t("createCreditAllocation.errors.endDateAfterStartDate"),
      )
      .required(t("createCreditAllocation.errors.mandatory")),
  });

  const onCloseDialog = (): void => {
    formik.resetForm();
    props.onClose();
  };

  const [createCreditAllocation] =
    useCreateCreditAllocationForSsoAccountMutation({
      onCompleted: () => {
        enqueueSnackbar(
          <Snackbar
            variant="success"
            title={t("createCreditAllocation.success")}
          />,
        );
        onCloseDialog();
      },
      onError: (error) => {
        enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
      },
      refetchQueries: ["GetCreditAllocations"],
    });

  const formik = useFormik({
    initialValues: {
      accountId,
      creditsAllocated: 0,
      startDate: new Date(),
      endDate: new Date(),
    },
    onSubmit: async (values: Yup.InferType<typeof validationSchema>) => {
      await createCreditAllocation({
        variables: {
          accountId: values.accountId,
          creditsAllocated: values.creditsAllocated,
          startDate: values.startDate.toISOString(),
          endDate: values.endDate.toISOString(),
        },
      });
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema,
  });

  return (
    <Dialog
      open={props.open}
      onCloseViaIcon={onCloseDialog}
      title={t("createCreditAllocation.title")}
      buttons={[
        {
          variant: "outlined",
          color: "default",
          title: t("createCreditAllocation.cancel"),
          onClick: onCloseDialog,
        },
        {
          disabled: !formik.isValid || formik.isSubmitting,
          variant: "contained",
          color: "primary",
          title: t("createCreditAllocation.create"),
          onClick: () => formik.handleSubmit(),
        },
      ]}
    >
      <div className={classes.container}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            type="number"
            className={classes.creditsInput}
            value={formik.values.creditsAllocated}
            label={t("createCreditAllocation.creditsAllocated")}
            name="creditsAllocated"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <DatePicker
            fullWidth
            autoOk
            value={formik.values.startDate}
            onChange={(date: Date | null) => {
              if (date) {
                formik.setFieldValue("startDate", date);
              }
            }}
            iconPlacement="end"
            format="dd-MM-yyyy"
            label={t("createCreditAllocation.startDate")}
            className={classes.dateInput}
          />
          <DatePicker
            autoOk
            fullWidth
            value={formik.values.endDate}
            onChange={(date: Date | null) => {
              if (date) {
                formik.setFieldValue("endDate", date);
              }
            }}
            iconPlacement="end"
            format="dd-MM-yyyy"
            label={t("createCreditAllocation.endDate")}
            className={classes.dateInput}
          />
        </form>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 12,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  creditsInput: {
    minWidth: 300,
  },
  dateInput: {
    marginTop: 16,
  },
}));
