import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useURLSearchParams } from "./useURLSearchParams";

export interface EnteredFromApp {
  app: "analyze" | "protect" | "monitor" | "impact";
  href: string;
}

export function useEnteredFromApp(): EnteredFromApp | undefined {
  const history = useHistory();
  const searchParams = useURLSearchParams();
  const referral = searchParams.get("referral");
  const preferredLanguage = localStorage.getItem("i18nextLng");

  const validUrl = isValidUrl(referral);

  useEffect(() => {
    if (!validUrl && referral) {
      searchParams.delete("referral");
      history.replace(history.location.pathname, {
        search: searchParams.toString(),
      });
    }
  }, [validUrl, history, referral, searchParams]);

  if (!validUrl) {
    return;
  }

  if (referral.includes("analyze")) {
    const analyzeUrl = new URL(referral);
    analyzeUrl.searchParams.append("lng", preferredLanguage ?? "en");
    return { app: "analyze", href: analyzeUrl.toString() };
  }

  if (referral.includes("protect")) {
    return { app: "protect", href: referral };
  }

  if (referral.includes("monitor")) {
    return { app: "monitor", href: referral };
  }

  if (referral.includes("impact")) {
    return { app: "impact", href: referral };
  }
}

function isValidUrl(url: string | null): url is string {
  if (!url) {
    return false;
  }

  try {
    new URL(url);
  } catch {
    return false;
  }

  return true;
}
