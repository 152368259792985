import { ApolloError, useSession } from "@lumar/shared";
import { useGetUserSettingsDataQuery } from "../graphql";
import { getFormValues } from "./getFormValues";
import { SettingsFormValues } from "./types";

interface UserSettingsFormValuesResult {
  formValues: SettingsFormValues;
  loading: boolean;
  error: ApolloError | undefined;
}

export function useUserSettingsFormValues(): UserSettingsFormValuesResult {
  const { id: userId, isDeepCrawlAdminEnabled } = useSession();

  const { data, loading, error } = useGetUserSettingsDataQuery({
    fetchPolicy: "cache-first",
    variables: { userId },
  });

  return {
    formValues: getFormValues(data, isDeepCrawlAdminEnabled),
    loading: loading,
    error: error,
  };
}
