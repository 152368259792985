import { ConnectionFilter, FilterOrRuleFormValue } from "@lumar/shared";

export function formatFilters(
  filters: FilterOrRuleFormValue[],
): ConnectionFilter {
  return {
    _or: filters.map((or) => {
      return {
        _and: or._and.map((and) => ({
          [and.metricCode]: { [and.predicateKey]: and.predicateValue },
        })),
      };
    }),
  };
}
