import { Chip, useTranslation } from "@lumar/shared";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { TFunction } from "i18next";

export function AuthCell(props: GridRenderCellParams): JSX.Element {
  const { t } = useTranslation("teamPage");
  const ssoEnabled = props.value as boolean;

  return (
    <div>
      <Chip color="primary" label={getAuthType(ssoEnabled, t)} />
    </div>
  );
}

function getAuthType(ssoEnabled: boolean, t: TFunction<"teamPage">): string {
  if (ssoEnabled) {
    return t("authType.sso");
  }
  return t("authType.basic");
}
