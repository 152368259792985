import {
  DatePicker,
  Dialog,
  Snackbar,
  TextField,
  useTranslation,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import * as Yup from "yup";
import { useUpdateSsoCreditAllocationMutation } from "../../../graphql";

export type CreditAllocationDefaultValues = {
  id: string;
  startDate: string;
  endDate: string;
  creditsUsed: number;
  creditsAllocated: number;
};

type Props = {
  creditAllocation?: CreditAllocationDefaultValues;
  open: boolean;
  onClose: () => void;
};

export const UpdateCreditAllocationDialog = (props: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("adminPanel");

  const validationSchema = Yup.object({
    creditAllocationId: Yup.string().required(
      t("updateCreditAllocation.errors.mandatory"),
    ),
    creditsAllocated: Yup.number()
      .min(1, t("updateCreditAllocation.errors.positiveInteger"))
      .min(
        props.creditAllocation?.creditsUsed ?? 1,
        t("updateCreditAllocation.errors.minCreditsAllocated"),
      )
      .required(t("updateCreditAllocation.errors.mandatory")),

    startDate: Yup.date().required(),
    endDate: Yup.date()
      .min(
        Yup.ref("startDate"),
        t("updateCreditAllocation.errors.endDateAfterStartDate"),
      )
      .required(t("updateCreditAllocation.errors.mandatory")),
  });

  const onCloseDialog = (): void => {
    formik.resetForm();
    props.onClose();
  };

  const [updateCreditAllocation] = useUpdateSsoCreditAllocationMutation({
    onCompleted: () => {
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t("updateCreditAllocation.success")}
        />,
      );
      onCloseDialog();
    },
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
    refetchQueries: ["GetCreditAllocations"],
  });

  const formik = useFormik({
    initialValues: props.creditAllocation
      ? {
          creditAllocationId: props.creditAllocation.id,
          creditsAllocated: props.creditAllocation.creditsAllocated,
          startDate: new Date(props.creditAllocation.startDate),
          endDate: new Date(props.creditAllocation.endDate),
        }
      : {
          creditAllocationId: "",
          creditsAllocated: 0,
          startDate: new Date(),
          endDate: new Date(),
        },
    onSubmit: async (values: Yup.InferType<typeof validationSchema>) => {
      await updateCreditAllocation({
        variables: {
          creditAllocationId: values.creditAllocationId,
          creditsAllocated: values.creditsAllocated,
          endDate: values.endDate.toISOString(),
        },
      });
    },
    enableReinitialize: props.open,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema,
  });

  return (
    <Dialog
      open={props.open}
      onCloseViaIcon={onCloseDialog}
      title={t("updateCreditAllocation.title")}
      buttons={[
        {
          variant: "outlined",
          color: "default",
          title: t("updateCreditAllocation.cancel"),
          onClick: onCloseDialog,
        },
        {
          disabled: !formik.isValid || formik.isSubmitting,
          variant: "contained",
          color: "primary",
          title: t("updateCreditAllocation.update"),
          onClick: () => formik.handleSubmit(),
        },
      ]}
    >
      <div className={classes.container}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            type="number"
            className={classes.creditsInput}
            value={formik.values.creditsAllocated}
            label={t("updateCreditAllocation.creditsAllocated")}
            name="creditsAllocated"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <DatePicker
            fullWidth
            autoOk
            disabled
            value={formik.values.startDate}
            onChange={(date: Date | null) => {
              if (date) {
                formik.setFieldValue("startDate", date);
              }
            }}
            iconPlacement="end"
            format="dd-MM-yyyy"
            label={t("updateCreditAllocation.startDate")}
            className={classes.dateInput}
          />
          <DatePicker
            autoOk
            fullWidth
            value={formik.values.endDate}
            onChange={(date: Date | null) => {
              if (date) {
                formik.setFieldValue("endDate", date);
              }
            }}
            iconPlacement="end"
            format="dd-MM-yyyy"
            label={t("updateCreditAllocation.endDate")}
            className={classes.dateInput}
          />
        </form>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 12,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  creditsInput: {
    minWidth: 300,
  },
  dateInput: {
    marginTop: 16,
  },
}));
