import React from "react";
import {
  Alert,
  Button,
  ToggleIconButton,
  TrashSolid,
  Typography,
  useTranslation,
} from "@lumar/shared";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { format } from "date-fns";
import { useGetUserKeysQuery } from "../graphql";

import { ja as jaLocale } from "date-fns/locale/ja";
import { enUS as enLocale } from "date-fns/locale/en-US";

const useStyles = makeStyles((theme) => ({
  divider: { marginTop: theme.spacing(1), marginBottom: theme.spacing(3) },
  button: {
    alignSelf: "start",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(3),
  },
  container: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: 6,
    marginBottom: theme.spacing(2),
  },
  header: {
    padding: theme.spacing(1.5, 2),
  },
  keyContainer: {
    backgroundColor: "rgba(209, 213, 219, 0.4)",
    borderRadius: 6,
    maxHeight: 600,
    overflow: "auto",
  },
  key: {
    padding: theme.spacing(0.5, 2),
    flexWrap: "nowrap",
  },
  deleteColumn: {
    width: 40,
  },
  noKeysAlert: {
    marginBottom: theme.spacing(3),
  },
  keyId: {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    marginRight: 4,
  },
}));

type Props = {
  onCreateKey: () => void;
  onDeleteKey: (userKeyId: string) => void;
};

export const ApiKeys = ({ onCreateKey, onDeleteKey }: Props): JSX.Element => {
  const classes = useStyles();
  const { i18n, t } = useTranslation("apiAccess");

  const { data, loading, fetchMore } = useGetUserKeysQuery({
    fetchPolicy: "cache-first",
  });

  const pageInfo = data?.me.userKeys.pageInfo;
  React.useEffect(() => {
    if (pageInfo?.hasNextPage) {
      fetchMore({ variables: { cursor: pageInfo.endCursor } });
    }
  }, [pageInfo, fetchMore]);

  const userKeys = data?.me?.userKeys?.edges.map((x) => x.node) ?? [];

  return (
    <>
      <Typography variant="h6SemiBold">{t("apiKeys.title")}</Typography>
      <Divider orientation="horizontal" className={classes.divider} />

      {loading ? (
        <>
          <Box className={classes.loadingContainer}>
            <CircularProgress aria-label="Loading api keys" />
          </Box>
          <Divider orientation="horizontal" className={classes.divider} />
        </>
      ) : userKeys.length > 0 ? (
        <>
          <Box className={classes.container}>
            <Grid container className={classes.header}>
              <Grid item xs={4}>
                <Typography variant="body1">{t("apiKeys.keyId")}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1">{t("apiKeys.keyName")}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1">
                  {t("apiKeys.keyCreated")}
                </Typography>
              </Grid>
              <Grid item className={classes.deleteColumn} />
            </Grid>
            <Box className={classes.keyContainer}>
              {userKeys?.map((userKey, index) => (
                <Grid
                  key={userKey.id}
                  container
                  alignItems="center"
                  className={classes.key}
                >
                  <Grid item xs={4}>
                    <Typography variant="body2" className={classes.keyId}>
                      {getRawId(userKey.id)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ wordBreak: "break-all" }}>
                    <Typography variant="body2">{userKey.name}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body2">
                      {format(new Date(userKey.createdAt), "d MMM yyyy", {
                        locale: i18n.language === "ja" ? jaLocale : enLocale,
                      })}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.deleteColumn}>
                    <ToggleIconButton
                      size="medium"
                      variant="outlined"
                      colorVariant="red"
                      data-testid={`delete-key-${index}`}
                      aria-label="Delete API key"
                      onClick={() => {
                        onDeleteKey(userKey.id);
                      }}
                    >
                      <TrashSolid />
                    </ToggleIconButton>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Box>
          <Divider orientation="horizontal" className={classes.divider} />
        </>
      ) : (
        <Alert severity="info" className={classes.noKeysAlert}>
          {t("apiKeys.emptyKeys")}
        </Alert>
      )}
      <Button
        variant="contained"
        color="primary"
        size="medium"
        className={classes.button}
        onClick={onCreateKey}
        data-testid="generate-api-key"
      >
        {t("apiKeys.generate")}
      </Button>
    </>
  );
};

function getRawId(id: string): string {
  const intId = parseInt(id);

  if (!isNaN(intId)) {
    return id;
  }

  return atob(id.replace(/=/g, "")).replace("N007UserKey", "");
}
