import React from "react";
import {
  Button,
  fieldToTextField,
  InfoOutlined,
  TextField,
  Typography,
  useTranslation,
} from "@lumar/shared";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Field, FieldProps, Formik, FormikProps } from "formik";
import * as Yup from "yup";

import { ConnectedApps, AdobeJwtAccount } from "./data/types";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 490,
  },
  title: {
    display: "block",
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2.5),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
}));

interface AdobeAccountDialogProps {
  account?: AdobeJwtAccount;
  addAccount: ConnectedApps["addAdobeAccount"];
  updateAccount: ConnectedApps["updateAdobeAccount"];
  onClose: () => void;
}

interface FormValues {
  clientId: string;
  clientSecret: string;
  orgId: string;
  privateKey: string;
  technicalAccountId: string;
}

export function AdobeAccountDialog({
  account,
  addAccount,
  updateAccount,
  onClose,
}: AdobeAccountDialogProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");

  const formikRef = React.useRef<FormikProps<FormValues> | null>(null);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    formikRef.current?.resetForm();
    setOpen(Boolean(account));
  }, [account]);

  const [saving, setSaving] = React.useState(false);

  const isNew = !account?.id?.length;

  const validationSchema = Yup.object().shape({
    clientId: Yup.string().required(t("required")),
    clientSecret: Yup.string().required(t("required")),
    orgId: Yup.string().required(t("required")),
    privateKey: Yup.string().required(t("required")),
    technicalAccountId: Yup.string().required(t("required")),
  });

  async function handleSave(submitForm: () => Promise<boolean>): Promise<void> {
    if (saving) return;

    setSaving(true);
    const result = await submitForm();
    setSaving(false);
    if (result) setOpen(false);
  }

  return (
    <Formik
      initialValues={{
        clientId: account?.clientId ?? "",
        orgId: account?.orgId ?? "",
        technicalAccountId: account?.technicalAccountId ?? "",
        clientSecret: "",
        privateKey: "",
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={({
        clientId,
        clientSecret,
        orgId,
        privateKey,
        technicalAccountId,
      }) => {
        if (isNew) {
          return addAccount(
            clientId,
            clientSecret,
            orgId,
            privateKey,
            technicalAccountId,
          );
        }
        return updateAccount(
          account.id,
          clientId,
          clientSecret,
          orgId,
          privateKey,
          technicalAccountId,
        );
      }}
      innerRef={formikRef}
    >
      {({ submitForm }) => (
        <Dialog
          open={open}
          onClose={() => !saving && setOpen(false)}
          TransitionProps={{ onExited: onClose }}
          classes={{ paper: classes.dialog }}
        >
          <DialogContent>
            <Typography variant="h2SemiBold" className={classes.title}>
              {isNew ? t("adobe.addAccount") : t("adobe.updateAccount")}
            </Typography>
            <Field name="clientId">
              {(props: FieldProps<string>) => (
                <TextField
                  id="client-id"
                  {...fieldToTextField(props)}
                  label={t("adobe.clientId")}
                  className={classes.field}
                  data-testid="adobe-edit-client-id"
                />
              )}
            </Field>
            <Field name="orgId">
              {(props: FieldProps<string>) => (
                <TextField
                  id="org-id"
                  {...fieldToTextField(props)}
                  label={t("adobe.orgId")}
                  className={classes.field}
                  data-testid="adobe-edit-org-id"
                />
              )}
            </Field>
            <Field name="technicalAccountId">
              {(props: FieldProps<string>) => (
                <TextField
                  {...fieldToTextField(props)}
                  id="technical-account-id"
                  label={t("adobe.technicalAccountId")}
                  className={classes.field}
                  data-testid="adobe-edit-technical-account-id"
                />
              )}
            </Field>
            <Field name="privateKey">
              {(props: FieldProps<string>) => (
                <TextField
                  {...fieldToTextField(props)}
                  multiline
                  minRows={10}
                  id="private-key"
                  label={
                    <>
                      {t("adobe.privateKey")}{" "}
                      <Tooltip
                        title={
                          <Typography
                            variant="caption"
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {t("adobe.privateKeyTooltip")}
                          </Typography>
                        }
                        placement="right"
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        <InfoOutlined color="inherit" fontSize="inherit" />
                      </Tooltip>
                    </>
                  }
                  className={classes.field}
                  data-testid="adobe-edit-private-key"
                />
              )}
            </Field>
            <Field name="clientSecret">
              {(props: FieldProps<string>) => (
                <TextField
                  {...fieldToTextField(props)}
                  id="client-secret"
                  label={t("adobe.clientSecret")}
                  className={classes.field}
                  data-testid="adobe-edit-secret"
                />
              )}
            </Field>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              disabled={saving}
              variant="outlined"
              size="large"
            >
              {t("cancelButton")}
            </Button>
            <Button
              onClick={() => handleSave(submitForm)}
              loading={saving}
              variant="contained"
              color="primary"
              size="large"
              data-testid="adobe-edit-save"
            >
              {isNew ? t("addButton") : t("updateButton")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
}
