import React, { useState } from "react";
import {
  BlueDataGrid,
  useUrlServerPagination,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { Box } from "@material-ui/core";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { useCreditUsageData } from "./useCreditUsageData";
import { useCreditUsageRows } from "./useCreditUsageRows";
import { DateFilters } from "./filters/DateFilters";
import { useCreditUsageColumns } from "./useCreditUsageColumns";
import { useSearchParam } from "../_common/routing/useSearchParam";
import { CreditReportOrderField, OrderDirection, RoleCode } from "../graphql";
import { CreditReportExport } from "./credit-export/CreditReportExport";
import { Redirect, useParams } from "react-router-dom";
import { Routes } from "../_common/routing/routes";
import { formatFilters } from "./filters/credit-filter/formatFilters";
import { FiltersButton } from "./filters/credit-filter/FiltersButton";
import { subDays } from "date-fns";

export function CreditUsage(): JSX.Element {
  const { t } = useTranslation("creditUsage");
  const session = useSession();
  const { accountId } = useParams<{ accountId: string }>();
  const [from, setFrom] = useState(subDays(new Date(), 30));
  const [to, setTo] = useState(new Date());
  const { pageInfo, ...pagination } = useUrlServerPagination(
    14,
    CreditReportOrderField.CreatedAt,
    OrderDirection.Desc,
  );

  const URLFilters = useSearchParam("filter");
  const parsedFilters = URLFilters && JSON.parse(unescape(atob(URLFilters)));
  const formattedFilters = parsedFilters
    ? formatFilters(parsedFilters.filters)
    : { _or: [] };

  const columns = useCreditUsageColumns();
  const { data, loading, error } = useCreditUsageData(
    formattedFilters,
    from,
    to,
    pageInfo,
  );
  const rows = useCreditUsageRows(data);

  const totalCount = data?.getAccount?.creditReports?.totalCount ?? 0;
  const totalRowCount = data?.getAccount?.totalCreditReports?.totalCount ?? 0;

  if (!session.hasSufficientRole(RoleCode.Admin)) {
    return <Redirect exact to={Routes.UserSettings.getUrl({ accountId })} />;
  }

  return (
    <>
      <TopNavigation title={t("title")} />
      <Box data-testid="credit-usage-table">
        <BlueDataGrid
          name="credit-usage"
          columns={columns}
          rows={rows}
          rowCount={totalCount}
          totalRowCount={totalRowCount}
          loading={loading}
          error={error}
          pageSize={14}
          autoRowHeight
          rowHeight={52}
          paginationMode="server"
          components={{
            ToolbarRight: [DateFilters, CreditReportExport],
            ToolbarLeft: FiltersButton,
          }}
          componentsProps={{
            toolbar: {
              toolbarRight: {
                filter: formattedFilters,
                from: from,
                setFrom: setFrom,
                to: to,
                setTo: setTo,
              },
            },
          }}
          {...pagination}
        ></BlueDataGrid>
      </Box>
    </>
  );
}
