import { AddonAvailability, useTranslation } from "@lumar/shared";
import { CreditType } from "./types";

export function useDefaultCredits(): CreditType[] {
  const { t } = useTranslation("subscription");
  return [
    {
      code: "seo-credits",
      submitCode: "seo_credits_limit",
      name: t("seoCredits"),
      description: t("seoCreditsDescription"),
      limitName: t("credits"),
      limitDescription: t("seoCreditsLimitDescription"),
      max: 0,
      used: 0,
      availabilityType: AddonAvailability.None,
    },
    {
      code: "a11y-credits",
      submitCode: "accessibility_credits_limit",
      name: t("a11yCredits"),
      description: t("a11yCreditsDescription"),
      limitName: t("credits"),
      limitDescription: t("a11yCreditsLimitDescription"),
      max: 0,
      used: 0,
      availabilityType: AddonAvailability.None,
    },
    {
      code: "site-speed-credits",
      submitCode: "site_speed_credits_limit",
      name: t("siteSpeedCredits"),
      description: t("siteSpeedCreditsDescription"),
      limitName: t("credits"),
      limitDescription: t("siteSpeedCreditsLimitDescription"),
      max: 0,
      used: 0,
      availabilityType: AddonAvailability.None,
    },
  ];
}
