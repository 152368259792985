import React from "react";
import { AppBar, makeStyles } from "@material-ui/core";
import { AccountMenu } from "./account-menu/AccountMenu";
import { PageTitle } from "./PageTitle";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "unset",
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
  },
  title: {
    marginTop: theme.spacing(4.25),
    marginRight: theme.spacing(2),
    overflow: "hidden",
  },
  divider: {
    background: theme.palette.grey[300],
    height: 1,
    marginBottom: theme.spacing(3),
  },
}));

interface TopNavigationProps {
  title: string;
  hideDivider?: boolean;
  onBackButtonClick?: () => void;
}

export function TopNavigation({
  title,
  onBackButtonClick,
  hideDivider,
}: TopNavigationProps): JSX.Element {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} position="relative" elevation={0}>
      <div className={classes.content}>
        <div className={classes.title}>
          <PageTitle title={title} onBackButtonClick={onBackButtonClick} />
        </div>
        <div>
          <AccountMenu />
        </div>
      </div>
      {hideDivider ? null : <div className={classes.divider} />}
    </AppBar>
  );
}
