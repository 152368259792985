import { MenuItem, Select, makeStyles } from "@material-ui/core";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { RoleCode } from "../../graphql";
import { AccountUsers } from "../useAccountUsers";

const useStyles = makeStyles((theme) => ({
  select: {
    boxShadow: "none",
    height: 32,
    minWidth: 97,
    background: "none",
    "& fieldset": {
      borderStyle: "none",
    },
    '& [aria-expanded="true"]': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  selectRoot: {
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  selectedMenuItem: {
    background: "none",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  focusVisible: {
    backgroundColor: "transparent",
  },
  menuItemPaper: {
    width: theme.spacing(12.5),
  },
  iconOpen: {
    display: "initial",
  },
  selectDisabled: {
    boxShadow: "none",
    "& svg": {
      display: "block",
    },
  },
  selectFocus: {
    "&:focus": {
      background: "none",
      backgroundColor: "transparent",
    },
  },
  cell: {
    display: "flex",
    alignItems: "center",
  },
}));

export function RoleCell({
  ...props
}: GridRenderCellParams & {
  accountUsers: AccountUsers;
}): JSX.Element {
  const classes = useStyles();

  const role = props.row.role;
  const userId = props.row.id;
  const { updateUserRole, roles } = props.accountUsers;

  return (
    <div className={classes.cell} data-testid="role-cell">
      <Select
        value={role}
        variant="outlined"
        className={classes.select}
        MenuProps={{
          classes: { paper: classes.menuItemPaper },
          getContentAnchorEl: null,
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
        }}
        classes={{
          root: classes.selectRoot,
          iconOpen: classes.iconOpen,
          outlined: classes.selectFocus,
        }}
        onChange={(e) => updateUserRole(userId, e.target.value as RoleCode)}
        data-testid="role-cell-select"
      >
        {roles.map((role, i) => (
          <MenuItem
            key={i}
            value={role.value}
            classes={{
              selected: classes.selectedMenuItem,
            }}
            focusVisibleClassName={classes.focusVisible}
            data-testid="role-cell-menu-item"
          >
            {role.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
