import { NarrowMetric, useTranslation } from "@lumar/shared";
import {
  ConnectionPredicate,
  ConnectionPredicateMetadata,
  MetricType,
} from "../../../graphql";

function useConnectionPredicates(): Pick<
  ConnectionPredicateMetadata,
  "code" | "name"
>[] {
  const { t } = useTranslation("creditUsage");

  const connectionPredicates = [
    {
      code: ConnectionPredicate.BeginsWith,
      name: t("filters.startsWith"),
    },
    {
      code: ConnectionPredicate.Contains,
      name: t("filters.contains"),
    },
    {
      code: ConnectionPredicate.EndsWith,
      name: t("filters.endsWith"),
    },
    {
      code: ConnectionPredicate.Eq,
      name: t("filters.equals"),
    },
    {
      code: ConnectionPredicate.Ge,
      name: t("filters.greaterThanOrEqualTo"),
    },
    {
      code: ConnectionPredicate.Gt,
      name: t("filters.greaterThan"),
    },
    {
      code: ConnectionPredicate.Le,
      name: t("filters.lessThanOrEqualTo"),
    },
    {
      code: ConnectionPredicate.Lt,
      name: t("filters.lessThan"),
    },
    {
      code: ConnectionPredicate.Ne,
      name: t("filters.notEquals"),
    },
    {
      code: ConnectionPredicate.NotContains,
      name: t("filters.notContains"),
    },
  ];

  return connectionPredicates;
}

export function useCreditFilters(): NarrowMetric[] {
  const { t } = useTranslation("creditUsage");
  const connectionPredicates = useConnectionPredicates();

  const idPredicateCodes = [ConnectionPredicate.Eq, ConnectionPredicate.Ne];

  const numberPredicateCodes = [
    ConnectionPredicate.Eq,
    ConnectionPredicate.Ne,
    ConnectionPredicate.Lt,
    ConnectionPredicate.Le,
    ConnectionPredicate.Gt,
    ConnectionPredicate.Ge,
  ];

  const idPredicates = connectionPredicates
    .filter((predicate) => idPredicateCodes.includes(predicate.code))
    .map((connectionPredicate) => ({
      ...connectionPredicate,
      type: MetricType["Integer"],
    }));

  const numberPredicates = connectionPredicates
    .filter((predicate) => numberPredicateCodes.includes(predicate.code))
    .map((connectionPredicate) => ({
      ...connectionPredicate,
      type: MetricType["Integer"],
    }));

  return [
    {
      code: "crawlId",
      name: t("filters.crawlId"),
      connectionPredicates: idPredicates,
      description: t("filters.crawlIdDescription"),
    },
    {
      code: "creditsUsed",
      name: t("filters.credits"),
      connectionPredicates: numberPredicates,
      description: t("filters.creditsDescription"),
    },
    {
      code: "projectId",
      name: t("filters.projectId"),
      connectionPredicates: idPredicates,
      description: t("filters.projectIdDescription"),
    },
  ];
}
