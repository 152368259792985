import DateFnsUtils from "@date-io/date-fns";
import {
  AuthPage,
  AuthProvider,
  ChurnZeroProvider,
  getRawAccountId,
  GQLClientProvider,
  SessionProvider,
  SnackbarProvider,
  useDocumentTitle,
  useTranslation,
} from "@lumar/shared";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Translation } from "react-i18next";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { AccountPage } from "./account/AccountPage";
import { AdminPanelAccounts } from "./admin-panel/accounts/AccountsPanel";
import { AdminPanel } from "./admin-panel/AdminPanel";
import { AdminPanelCreditAllocations } from "./admin-panel/credit-allocations/CreditAllocationsPanel";
import { ApiAccess } from "./api-access/ApiAccess";
import { ConfirmEmailPage } from "./confirm-email/ConfirmEmail";
import { ConnectedApps } from "./connected-apps/ConnectedApps";
import { CreditUsage } from "./credit-usage/CreditUsage";
import { NotFound } from "./not-found/NotFound";
import { Subscription } from "./subscription/Subscription";
import { TeamPage } from "./team-page/TeamPage";
import { UserSettings } from "./user-settings/UserSettings";
import { TagsController } from "./_common/tags-controller/TagsController";
import { ApiAccessRedirect } from "./_common/routing/ApiAccessRedirect";
import { AppRoute } from "./_common/routing/AppRoute";
import { Routes } from "./_common/routing/routes";

export const App = (): JSX.Element => {
  const { t } = useTranslation("navigation");

  useDocumentTitle(t("title"));

  return (
    <BrowserRouter>
      <Translation ns="errors">
        {(t) => (
          <AuthProvider app="accounts">
            <GQLClientProvider
              name="accounts"
              version={process.env.REACT_APP_BUILD_HASH}
            >
              <SessionProvider
                publicRoutes={[Routes.ConfirmEmail.ROUTE]}
                onSessionError={{
                  message: t("sessionError"),
                  actions: [
                    {
                      name: t("reload"),
                      onClick: () => location.reload(),
                    },
                  ],
                }}
              >
                <ChurnZeroProvider>
                  <>
                    <TagsController />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <SnackbarProvider>
                        <Switch>
                          <Redirect
                            exact
                            from="//*"
                            to={{
                              pathname: "/*",
                              search: window.location.search,
                            }}
                          />
                          <Route exact path="/">
                            <Redirect to={Routes.Login.ROUTE} />
                          </Route>
                          <Route exact path={Routes.Login.ROUTE}>
                            <AuthPage
                              onSession={(session, history) => {
                                if (session) {
                                  history.replace(
                                    Routes.UserSettings.getUrl({
                                      accountId: getRawAccountId(
                                        session.account.id,
                                      ),
                                    }),
                                  );
                                }
                              }}
                            />
                          </Route>
                          <Route exact path={Routes.ConfirmEmail.ROUTE}>
                            <ConfirmEmailPage />
                          </Route>
                          <AppRoute exact path={Routes.UserSettings.ROUTE}>
                            <UserSettings />
                          </AppRoute>
                          <AppRoute exact path={Routes.Account.ROUTE}>
                            <AccountPage />
                          </AppRoute>
                          <AppRoute exact path={Routes.CreditUsage.ROUTE}>
                            <CreditUsage />
                          </AppRoute>
                          <AppRoute exact path={Routes.ApiAccess.ROUTE}>
                            <ApiAccess />
                          </AppRoute>
                          <AppRoute exact path={Routes.Team.ROUTE}>
                            <TeamPage />
                          </AppRoute>
                          <AppRoute exact path={Routes.ConnectedApps.ROUTE}>
                            <ConnectedApps />
                          </AppRoute>
                          <AppRoute exact path={Routes.Subscription.ROUTE}>
                            <Subscription />
                          </AppRoute>
                          <Route exact path="/api-access">
                            <ApiAccessRedirect />
                          </Route>
                          <Route exact path={Routes.AdminPanel.ROUTE}>
                            <AdminPanel />
                          </Route>
                          <Route exact path={Routes.AdminPanelAccounts.ROUTE}>
                            <AdminPanelAccounts />
                          </Route>
                          <Route
                            exact
                            path={Routes.AdminPanelCreditAllocations.ROUTE}
                          >
                            <AdminPanelCreditAllocations />
                          </Route>
                          <Route>
                            <NotFound />
                          </Route>
                        </Switch>
                      </SnackbarProvider>
                    </MuiPickersUtilsProvider>
                  </>
                </ChurnZeroProvider>
              </SessionProvider>
            </GQLClientProvider>
          </AuthProvider>
        )}
      </Translation>
    </BrowserRouter>
  );
};
