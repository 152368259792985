import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { PlusCircleSolid } from "../../../icons";
import { Button } from "../../buttons/Button";
import { useTranslation } from "../../../i18n";
const useStyles = makeStyles((theme) => ({
    orSeparator: {
        height: theme.spacing(2),
        width: "35px",
        borderRight: `1px solid ${theme.palette.grey["200"]}`,
    },
    addOrStatement: {
        minWidth: "70px",
        height: "35px",
        borderColor: theme.palette.grey["200"],
    },
}));
export function AddOrStatementButton(props) {
    const styles = useStyles();
    const { t } = useTranslation("connectionFiltering");
    return (_jsxs(Box, { paddingTop: props.isLastOrStatement ? 2 : 0, paddingBottom: props.isLastOrStatement ? 2 : 0, paddingLeft: 3, children: [!props.isLastOrStatement && _jsx("div", { className: styles.orSeparator }), _jsx(Button, { "data-testid": "add-or-statement", "data-pendo": props["data-pendo"], startIcon: _jsx(PlusCircleSolid, {}), variant: "outlined", size: "small", disabled: !props.isLastOrStatement, className: styles.addOrStatement, onClick: props.onAddOrStatement, children: t("orStatement") }), !props.isLastOrStatement && _jsx("div", { className: styles.orSeparator })] }));
}
