import {
  Button,
  Dialog,
  LinkSolid,
  Snackbar,
  TextField,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 500,
    padding: theme.spacing(1, 1, 3, 1),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: 1200,
  },
  buttonIcon: {
    fontSize: 16,
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  onClose: () => void;
  apiSecret?: string;
};

export const ApiKeySecretDialog = ({
  onClose,
  apiSecret,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("apiAccess");
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog
      open={!!apiSecret}
      title={t("apiSecretDialog.title")}
      anchorOrigin={{ vertical: "center", horizontal: "center" }}
      onClose={onClose}
      buttons={[
        {
          variant: "contained",
          color: "primary",
          title: t("apiSecretDialog.done"),
          onClick: () => onClose(),
          "data-testid": "done-api-key",
        },
      ]}
      classes={{ backdrop: classes.backdrop }}
    >
      <Box className={classes.content}>
        <Typography>{t("apiSecretDialog.description")}</Typography>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs>
            <TextField
              label={t("apiSecretDialog.keyLabel")}
              value={apiSecret}
              className={classes.textField}
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                if (apiSecret) {
                  navigator.clipboard.writeText(apiSecret);
                  enqueueSnackbar(
                    <Snackbar
                      variant="success"
                      title={t("apiSecretDialog.snackbarSuccess")}
                    />,
                  );
                }
              }}
              size="large"
            >
              <LinkSolid className={classes.buttonIcon} />
              <span>{t("apiSecretDialog.copy")}</span>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};
