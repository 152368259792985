import { BlueDataGridProps } from "@lumar/shared";
import { TFunction } from "i18next";
import { ActionsCell } from "./cells/ActionsCell";
import { DateCell } from "./cells/DateCell";
import { NumberCell } from "./cells/NumberCell";
import { CreditAllocationDefaultValues } from "./update-credit-allocation/UpdateCreditAllocationDialog";

export const creditAllocationsDataGridColumns = (args: {
  t: TFunction<"adminPanel">;
  onUpdateCreditAllocation: (
    creditAllocationDefaultValues: CreditAllocationDefaultValues,
  ) => void;
}): BlueDataGridProps["columns"] => [
  {
    field: "id",
    headerName: args.t("creditAllocationsColumn.id"),
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    hide: true,
  },
  {
    field: "startDate",
    headerName: args.t("creditAllocationsColumn.startDate"),
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (props) => <DateCell {...props} value={props.row.startDate} />,
  },
  {
    field: "endDate",
    headerName: args.t("creditAllocationsColumn.endDate"),
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (props) => <DateCell {...props} value={props.row.endDate} />,
  },
  {
    field: "creditsUsed",
    headerName: args.t("creditAllocationsColumn.creditsUsed"),
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (props) => (
      <NumberCell {...props} value={props.row.creditsUsed} />
    ),
  },
  {
    field: "creditsAllocated",
    headerName: args.t("creditAllocationsColumn.creditsAllocated"),
    flex: 1,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (props) => (
      <NumberCell {...props} value={props.row.creditsAllocated} />
    ),
  },
  {
    field: "manage",
    headerName: args.t("creditAllocationsColumn.manage"),
    sortable: false,
    width: 100,
    disableColumnMenu: true,
    renderCell: (props) => (
      <ActionsCell
        {...props}
        onUpdateCreditAllocation={args.onUpdateCreditAllocation}
      />
    ),
  },
];
