import { useTranslation } from "@lumar/shared";
import { Service } from "./types";

export function useDefaultServices(): Service[] {
  const { t } = useTranslation("subscription");
  return [
    {
      code: "email_support",
      description: t("services.emailSupport.description"),
      name: t("services.emailSupport.name"),
      value: t("no"),
    },
    {
      code: "phone_support",
      description: t("services.phoneSupport.description"),
      name: t("services.phoneSupport.name"),
      value: t("no"),
    },
    {
      code: "account-manager",
      description: t("services.accountManagers.description"),
      name: t("services.accountManagers.name"),
      value: t("no"),
    },
  ];
}
