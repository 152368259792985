import { Snackbar, ApolloCache } from "@lumar/shared";
import { TFunction } from "i18next";
import { SnackbarProvider } from "notistack";
import {
  AddSplunkConnectionMutation,
  GetConnectedAppsDocument,
  GetConnectedAppsQuery,
  RemoveSplunkConnectionMutation,
  SplunkProxyCode,
  useAddSplunkConnectionMutation,
  useRemoveSplunkConnectionMutation,
  useUpdateSplunkConnectionMutation,
} from "../../graphql";
import { ConnectedApps, SplunkAccount } from "./types";

type AdobeAccounts = Pick<
  ConnectedApps,
  | "splunkAccounts"
  | "addSplunkAccount"
  | "updateSplunkAccount"
  | "removeSplunkAccount"
>;

export function useSplunkAccounts(
  data: GetConnectedAppsQuery | undefined,
  enqueueSnackbar: SnackbarProvider["enqueueSnackbar"],
  t: TFunction<"connectedApps">,
): AdobeAccounts {
  const [addSplunkConnection] = useAddSplunkConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("splunk.errorAdd")} />,
      ),
  });

  const [updateSplunkConnection] = useUpdateSplunkConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("splunk.errorUpdate")} />,
      ),
  });

  const [removeSplunkConnection] = useRemoveSplunkConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("splunk.errorRemove")} />,
      ),
  });

  async function addSplunkAccount(
    url: string,
    username: string,
    password: string,
    staticIp?: SplunkProxyCode,
  ): Promise<boolean> {
    const response = await addSplunkConnection({
      variables: {
        url,
        username,
        password,
        proxyCode: staticIp ?? null,
      },
      update: (cache, { data }) => addSplunkConnectionToCache(cache, data),
    });

    return Boolean(response.data?.createSplunkConnection.splunkConnection.id);
  }

  async function updateSplunkAccount(
    splunkConnectionId: string,
    url: string,
    username: string,
    password: string,
    staticIp?: SplunkProxyCode,
  ): Promise<boolean> {
    const response = await updateSplunkConnection({
      variables: {
        splunkConnectionId,
        url,
        username,
        password,
        proxyCode: staticIp ?? null,
      },
    });

    return Boolean(response.data?.updateSplunkConnection.splunkConnection.id);
  }

  async function removeSplunkAccount(
    splunkConnectionId: string,
  ): Promise<boolean> {
    const response = await removeSplunkConnection({
      variables: {
        splunkConnectionId,
      },
      optimisticResponse: {
        deleteSplunkConnection: {
          splunkConnection: {
            id: splunkConnectionId,
          },
        },
      },
      update: (cache, { data }) => removeSplunkConnectionFromCache(cache, data),
    });

    return Boolean(response.data?.deleteSplunkConnection.splunkConnection.id);
  }

  return {
    splunkAccounts: getSplunkConnections(data),
    addSplunkAccount,
    updateSplunkAccount,
    removeSplunkAccount,
  };
}

export function getSplunkConnections(
  data: GetConnectedAppsQuery | undefined,
): SplunkAccount[] {
  return (
    data?.me?.splunkConnections?.nodes.map((x) => ({
      id: x.id,
      url: x.url,
      username: x.username,
      staticIp: x.proxyCode ?? undefined,
      isValid: x.isWorking,
    })) ?? []
  );
}

function addSplunkConnectionToCache(
  cache: ApolloCache<AddSplunkConnectionMutation>,
  data: AddSplunkConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        splunkConnections: {
          ...cachedData.me.splunkConnections,
          nodes: [
            ...cachedData.me.splunkConnections.nodes,
            data?.createSplunkConnection.splunkConnection,
          ],
        },
      },
    },
  });
}

function removeSplunkConnectionFromCache(
  cache: ApolloCache<AddSplunkConnectionMutation>,
  data: RemoveSplunkConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  const removeId = data?.deleteSplunkConnection.splunkConnection.id;
  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        splunkConnections: {
          ...cachedData.me.splunkConnections,
          nodes: cachedData.me.splunkConnections.nodes.filter(
            (x) => x.id !== removeId,
          ),
        },
      },
    },
  });
}
