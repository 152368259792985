import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { getDefaultFormOrFilter } from "../helpers";
import { OrStatementBlock } from "./OrStatementBlock";
import { AddOrStatementButton } from "./AddOrStatementButton";
export function FilterOrRuleFieldContainer(props) {
    const isOneOrBlockLeft = props.value.length === 1;
    const { replace, remove, metrics, onLastRemainingRuleDeleted } = props;
    const onLastRemainingRuleDeletedInOrBlock = useCallback((index) => {
        if (isOneOrBlockLeft) {
            replace(index, getDefaultFormOrFilter(metrics));
            if (onLastRemainingRuleDeleted) {
                onLastRemainingRuleDeleted();
            }
        }
        else {
            remove(index);
        }
    }, [replace, remove, metrics, isOneOrBlockLeft, onLastRemainingRuleDeleted]);
    return (_jsx(_Fragment, { children: props.value.map((value, index) => {
            const isLast = index === props.value.length - 1;
            return (_jsxs(React.Fragment, { children: [_jsx(OrStatementBlock, { name: props.name, index: index, metrics: props.metrics, value: value, setFieldValue: props.setFieldValue, setFieldTouched: props.setFieldTouched, onLastRemainingRuleDeletedInOrBlock: onLastRemainingRuleDeletedInOrBlock, allowDeletingLastEmptyRule: !isOneOrBlockLeft || props.allowDeletingLastEmptyRule, pendoPrefix: props.pendoPrefix }), _jsx(AddOrStatementButton, { isLastOrStatement: isLast, onAddOrStatement: () => 
                        // eslint-disable-next-line fp/no-mutating-methods
                        props.push(getDefaultFormOrFilter(props.metrics)), "data-pendo": props.pendoPrefix ? `${props.pendoPrefix}-filter-or` : undefined })] }, value.id));
        }) }));
}
