import {
  Dialog,
  Snackbar,
  TextField,
  Typography,
  useTranslation,
} from "@lumar/shared";
import { Box, makeStyles } from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { GetUserKeysDocument, useCreateUserKeyMutation } from "../graphql";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  content: {
    width: 500,
    padding: theme.spacing(1, 1, 3, 1),
  },
  textField: {
    marginTop: theme.spacing(2),
    maxWidth: 400,
  },
  backdrop: {
    zIndex: 1200,
  },
}));

type Props = {
  open: boolean;
  onClose: () => void;
  onApiKeyCreated: (apiSecret?: string) => void;
};

type FormValues = {
  name: string;
};

export const CreateApiKeyDialog = ({
  open,
  onClose,
  onApiKeyCreated,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("apiAccess");
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Yup.object({
    name: Yup.string().required(t("createApiKeyDialog.nameValidation")),
  });

  const [createUserKey] = useCreateUserKeyMutation({
    refetchQueries: [GetUserKeysDocument],
    onError: (error) => {
      enqueueSnackbar(<Snackbar variant="error" title={error.message} />);
    },
  });

  const formik = useFormik<FormValues>({
    initialValues: { name: "" },
    validationSchema,
    onSubmit: async (values) => {
      const { data } = await createUserKey({
        variables: { name: values.name },
      });
      onClose();
      onApiKeyCreated(data?.createUserKey?.secret ?? undefined);
      formik.resetForm();
    },
  });

  return (
    <Dialog
      open={open}
      title={t("createApiKeyDialog.title")}
      onClose={onClose}
      buttons={[
        {
          variant: "outlined",
          title: t("createApiKeyDialog.cancel"),
          onClick: onClose,
          "data-testid": "cancel-create-api-key",
        },
        {
          variant: "contained",
          color: "primary",
          title: t("createApiKeyDialog.create"),
          onClick: () => formik.handleSubmit(),
          "data-testid": "create-api-key",
        },
      ]}
      classes={{ backdrop: classes.backdrop }}
    >
      <Box className={classes.content}>
        <Typography>{t("createApiKeyDialog.description")}</Typography>
        <TextField
          label={t("createApiKeyDialog.nameLabel")}
          inputProps={{ "aria-label": "name" }}
          name="name"
          className={classes.textField}
          value={formik.values.name}
          onChange={formik.handleChange}
          error={!!formik.errors.name}
          helperText={formik.errors.name ? formik.errors.name : ""}
        />
      </Box>
    </Dialog>
  );
};
