import React from "react";
import { isString } from "lodash";
import { IconButton, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { PencilSolid, TrashSolid, Typography } from "@lumar/shared";
import Dropzone from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.grey[300],
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  rootActive: {
    cursor: "pointer",
  },
  rootDrag: {
    borderColor: "transparent",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #1D4ED8",
  },
  rootDragInvalid: {
    borderColor: "transparent",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05), 0px 0px 0px 2px #DC2626;",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    padding: theme.spacing(1.25, 1.25, 2.75, 1.25),
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  hoverOverlay: {
    background: "#FFFFFFB0",
    color: theme.palette.grey[700],
    fontWeight: 600,
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  placeholder: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.spacing(2.75),
    "& svg": {
      width: 145,
      height: 145,
      fill: "none",
      stroke: theme.palette.grey[300],
      opacity: 0.6,
    },
  },
  actions: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    height: "100%",
    alignItems: "end",
    justifyContent: "end",
    padding: theme.spacing(1.25),
  },
  button: {
    zIndex: 2,
  },
}));

interface ImageInputProps {
  value?: string | File;
  onChange?: (file: File | undefined) => void;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  alt?: string;
}

export function ImageInput({
  value,
  onChange,
  disabled,
  placeholder,
  className,
  alt,
  ...props
}: ImageInputProps): JSX.Element {
  const classes = useStyles();

  const [hover, setHover] = React.useState(false);

  function getImage(): React.ReactNode {
    if (isString(value)) {
      return <img src={value} className={classes.image} alt={alt} />;
    }

    if (value) {
      return (
        <img
          src={URL.createObjectURL(value)}
          className={classes.image}
          alt={alt}
        />
      );
    }

    return (
      <div className={classes.placeholder}>
        <svg viewBox="0 0 145 145">
          <path
            d="M114.799 126.875V30.8749C114.799 24.2475 109.426 18.8749 102.799 18.8749H42.7988C36.1714 18.8749 30.7988 24.2475 30.7988 30.8749V126.875M114.799 126.875L126.799 126.875M114.799 126.875H84.7988M30.7988 126.875L18.7988 126.875M30.7988 126.875H60.7988M54.7988 42.8748H60.7988M54.7988 66.8748H60.7988M84.7988 42.8748H90.7988M84.7988 66.8748H90.7988M60.7988 126.875V96.8748C60.7988 93.5611 63.4851 90.8748 66.7988 90.8748H78.7988C82.1125 90.8748 84.7988 93.5611 84.7988 96.8748V126.875M60.7988 126.875H84.7988"
            strokeWidth="9.58"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  return (
    <Dropzone
      accept={{
        "image/*": [],
      }}
      onDrop={(acceptedFiles) =>
        acceptedFiles[0] && onChange?.(acceptedFiles[0])
      }
      disabled={disabled}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <div
          {...props}
          {...getRootProps()}
          className={clsx(className, classes.root, {
            [classes.rootActive]: !disabled,
            [classes.rootDrag]: isDragActive,
            [classes.rootDragInvalid]: isDragReject,
          })}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {getImage()}

          {!disabled && (hover || isDragActive) && (
            <div className={clsx(classes.overlay, classes.hoverOverlay)}>
              <Typography>{placeholder}</Typography>
            </div>
          )}

          <input {...getInputProps()} />

          {!disabled && (
            <div className={classes.actions}>
              {Boolean(value) && hover && (
                <IconButton
                  aria-label="delete account logo"
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange?.(undefined);
                  }}
                  className={classes.button}
                  size="small"
                  data-testid="account-logo-remove"
                >
                  <TrashSolid />
                </IconButton>
              )}
              {(!value || hover) && (
                <IconButton
                  aria-label="change account logo"
                  className={classes.button}
                  size="small"
                >
                  <PencilSolid />
                </IconButton>
              )}
            </div>
          )}
        </div>
      )}
    </Dropzone>
  );
}
