import {
  Button,
  fieldToSelect,
  fieldToTextField,
  LockOpenSolid,
  Select,
  Snackbar,
  TextField,
  ApolloError,
  useAuth,
  useSession,
  useTranslation,
  useBrand,
} from "@lumar/shared";
import { isBlacklisted } from "@deepcrawl/business-email-validator";
import { CircularProgress, makeStyles, MenuItem } from "@material-ui/core";
import { Field, FieldProps, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import * as Yup from "yup";
import { useUpdateUserSettingsMutation } from "../graphql";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { SettingsFormValues } from "./types";
import { useUserSettingsFormValues } from "./useUserSettingsFormValues";
import isEmail from "validator/lib/isEmail";
import { useJobTitles } from "../user-details/useJobTitles";
import { Routes } from "../_common/routing/routes";
import { Redirect, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  subheader: { fontWeight: 600, color: theme.palette.grey[900] },
  formDiv: {
    backgroundColor: "white",
    padding: theme.spacing(3, 2, 2, 2),
    borderRadius: 6,
    borderColor: theme.palette.grey[300],
    borderWidth: 0.5,
    borderStyle: "solid",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.06)",
    maxWidth: 640,
  },
  innerDiv: {
    maxWidth: 591,
    marginLeft: "auto",
    marginRight: "auto",
  },
  divider: { marginTop: 6, marginBottom: 20 },
  label: {
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey[900],
    [theme.breakpoints.down(1030)]: {
      marginBottom: -theme.spacing(1),
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3),
  },
  lockIcon: { color: "white", fontSize: 50 },
  submitBtn: {
    marginLeft: theme.spacing(1),
  },
  input: {
    "& > [class*='MuiInputBase-focused']": {
      boxShadow: "none",
      borderColor: `${theme.palette.grey[300]} !important`,
    },
  },
}));

export function UserSettings(): JSX.Element {
  const { resetPassword } = useAuth();
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation(["userSettings", "userDetails"]);
  const { enqueueSnackbar } = useSnackbar();
  const { isDeepCrawlAdmin, setDeepCrawlAdminEnabled } = useSession();

  const { formValues, loading } = useUserSettingsFormValues();

  const [updateUserSettings, { loading: saving }] =
    useUpdateUserSettingsMutation({
      onCompleted: (data) => {
        if (data)
          enqueueSnackbar(
            <Snackbar
              variant="success"
              title={t("userSettings:saveSuccess")}
            />,
          );
      },
    });

  const jobTitles = useJobTitles();

  async function onSubmit({
    firstName,
    lastName,
    email,
    jobTitle,
    adminMode,
  }: SettingsFormValues): Promise<void> {
    try {
      await updateUserSettings({
        variables: { email, firstName, lastName, jobTitle },
      });
      if (adminMode !== formValues.adminMode)
        await setDeepCrawlAdminEnabled(adminMode);
    } catch (error) {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("userSettings:saveError", {
            message: (error as ApolloError)?.message,
          })}
        />,
      );
    }
  }

  const requestPasswordReset = async (email: string): Promise<void> => {
    try {
      setResetPasswordLoading(true);
      await resetPassword(email);
      enqueueSnackbar(
        <Snackbar
          variant="success"
          title={t("userSettings:passEmailRequestSuccess")}
        />,
      );
    } catch {
      enqueueSnackbar(
        <Snackbar
          variant="error"
          title={t("userSettings:passEmailRequestError")}
        />,
      );
    } finally {
      setResetPasswordLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(
      255,
      t("userSettings:validation.firstNameMaxChar"),
    ),
    lastName: Yup.string().max(
      255,
      t("userSettings:validation.lastNameMaxChar"),
    ),
    email: Yup.string()
      .required(t("userSettings:validation.required"))
      .test(
        "email",
        t("userSettings:validation.invalidEmail"),
        (email) => !email || isEmail(email),
      )
      .test(
        "isBusinessEmail",
        t("userSettings:validation.invalidBusinessEmail"),
        (value) => value !== undefined && !isBlacklisted(value),
      ),
  });

  const { accountId } = useParams<{ accountId: string }>();
  const brand = useBrand();

  if (!brand.featureAvailability.myProfilePage) {
    return <Redirect to={Routes.Account.getUrl({ accountId })} />;
  }

  if (loading) {
    return (
      <>
        <TopNavigation title={t("userSettings:title")} />
        <CircularProgress aria-label="Loading user settings" />
      </>
    );
  }

  return (
    <>
      <TopNavigation title={t("userSettings:title")} />
      <div className={classes.formDiv}>
        <div className={classes.innerDiv}>
          <Formik
            initialValues={formValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form data-testid="user-settings-form">
              <div className={classes.formContainer}>
                <Field name="email">
                  {(props: FieldProps<string>) => (
                    <TextField
                      {...fieldToTextField(props)}
                      label={t("userSettings:email")}
                      id="email"
                    />
                  )}
                </Field>
                <Field name="firstName">
                  {(props: FieldProps<string>) => (
                    <TextField
                      {...fieldToTextField(props)}
                      label={t("userSettings:firstName")}
                      id="first-name"
                    />
                  )}
                </Field>
                <Field name="lastName">
                  {(props: FieldProps<string>) => (
                    <TextField
                      {...fieldToTextField(props)}
                      label={t("userSettings:lastName")}
                      id="last-name"
                    />
                  )}
                </Field>
                <Field name="jobTitle">
                  {(props: FieldProps<string>) => (
                    <Select
                      {...fieldToSelect(props)}
                      displayEmpty
                      inputProps={{ id: "job-title" }}
                      label={t("userSettings:jobFunction")}
                      renderValue={(value) => {
                        if (!value) {
                          return (
                            <>{t("userSettings:placeholders.jobFunction")}</>
                          );
                        }
                        return <>{value}</>;
                      }}
                    >
                      {jobTitles.map((option, i) => (
                        <MenuItem key={i} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Field>
                {isDeepCrawlAdmin && (
                  <Field name="adminMode">
                    {(props: FieldProps<boolean>) => (
                      <Select
                        value={props.field.value ? "enabled" : "disabled"}
                        onChange={(e) =>
                          props.form.setFieldValue(
                            props.field.name,
                            e.target.value === "enabled",
                          )
                        }
                        inputProps={{ id: "admin-mode" }}
                        label={t("userSettings:adminMode")}
                        disabled={props.form.isSubmitting}
                        variant="outlined"
                      >
                        <MenuItem value="enabled">
                          {t("userSettings:enabled")}
                        </MenuItem>
                        <MenuItem value="disabled">
                          {t("userSettings:disabled")}
                        </MenuItem>
                      </Select>
                    )}
                  </Field>
                )}
              </div>
              <div className={classes.buttonsContainer}>
                <Button
                  loading={resetPasswordLoading}
                  disabled={loading}
                  variant="contained"
                  color="secondary"
                  data-testid="user-settings-reset-password-button"
                  size="medium"
                  onClick={() => requestPasswordReset(formValues.email)}
                >
                  {t("userSettings:resetPassword")}
                </Button>
                <Button
                  type="submit"
                  loading={saving}
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  data-testid="user-settings-submit-button"
                  startIcon={<LockOpenSolid className={classes.lockIcon} />}
                  className={classes.submitBtn}
                  size="medium"
                >
                  {t("userSettings:save")}
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
