import { useTranslation } from "@lumar/shared";
import { AddonIntegration } from "./types";

export function useDefaultApps(): AddonIntegration[] {
  const { t } = useTranslation("subscription");
  return [
    {
      code: "analyze-app",
      name: t("apps.analyze.name"),
      description: t("apps.analyze.description"),
      included: false,
      submitCode: "analyze_app_requested",
    },
    {
      code: "monitor-app",
      name: t("apps.monitor.name"),
      description: t("apps.monitor.description"),
      included: false,
      submitCode: "monitor_app_requested",
    },
    {
      code: "protect-app",
      name: t("apps.protect.name"),
      description: t("apps.protect.description"),
      included: false,
      submitCode: "protect_app_requested",
    },
    {
      code: "impact-app",
      name: t("apps.impact.name"),
      description: t("apps.impact.description"),
      included: false,
      submitCode: "impact_app_requested",
    },
  ];
}
