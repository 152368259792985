import { useEffect } from "react";

import {
  useTranslation,
  ArrowNarrowLeftSolid,
  Typography,
  Button,
} from "@lumar/shared";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(23),
    lineHeight: theme.typography.pxToRem(35),
    color: theme.palette.grey[900],
    fontWeight: 600,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(0, 0.5),
    zIndex: 1,
    position: "relative",
    pointerEvents: "none",
  },
  icon: {
    fontSize: theme.typography.pxToRem(23),
  },
  container: {
    position: "relative",
  },
  backButton: {
    position: "absolute",
    inset: 0,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.grey[200],
    },
    "&$focusVisible": {
      boxShadow: "none",
      background: theme.palette.grey[200],
    },
  },
  focusVisible: {},
}));

interface PageTitleProps {
  title: string;
  onBackButtonClick?: () => void;
}

export function PageTitle({
  title,
  onBackButtonClick,
}: PageTitleProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("connectedApps");

  if (onBackButtonClick)
    return (
      <div className={classes.container}>
        <Button
          onClick={onBackButtonClick}
          classes={{
            root: classes.backButton,
            focusVisible: classes.focusVisible,
          }}
          aria-label={t("zapier.backButtonLabel")}
          data-testid="top-nav-page-title-back-button"
        />
        <Typography className={clsx(classes.title)} component="h1">
          <ArrowNarrowLeftSolid className={classes.icon} />
          {title}
        </Typography>
      </div>
    );

  return (
    <Typography
      className={classes.title}
      component="h1"
      data-testid="top-nav-page-title"
    >
      {title}
    </Typography>
  );
}

export function useDefaultAppTitle(): void {
  const { t } = useTranslation("navigation");
  const defaultTitle = t("title");

  useEffect(() => {
    // eslint-disable-next-line fp/no-mutation
    document.title = defaultTitle;
  }, [defaultTitle]);
}
