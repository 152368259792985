import {
  Typography,
  useBrand,
  useSession,
  useTranslation,
} from "@lumar/shared";
import { Box, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { UserDetailsForm } from "./UserDetailsForm";
import { OnboardingContainer } from "../_common/onboarding-container/OnboardingContainer";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[900],
    fontSize: theme.typography.pxToRem(23),
    fontWeight: 600,
    letterSpacing: "-0.025rem",
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(4),
  },
}));

type Props = {
  children: JSX.Element;
};

export const UserDetailsWrapper = ({ children }: Props): JSX.Element => {
  const classes = useStyles();
  const brand = useBrand();
  const { t } = useTranslation("userDetails");
  const { firstName, lastName, jobTitle } = useSession();
  const [completed, setCompleted] = useState(false);

  if ((firstName && lastName && jobTitle) || completed) {
    return children;
  }

  if (!brand.featureAvailability.onboarding) {
    return children;
  }

  return (
    <OnboardingContainer>
      <Box display="flex" flexDirection="column">
        <Typography variant="h1" className={classes.title}>
          {t("title")}
        </Typography>
        <Typography className={classes.subtitle}>{t("subtitle")}</Typography>
        <UserDetailsForm onSuccess={() => setCompleted(true)} />
      </Box>
    </OnboardingContainer>
  );
};
