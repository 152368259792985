/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from "yup";
import { getFilterRuleArrayValidationSchema } from "../filter-rule-field-array/getFilterRuleArrayValidationSchema";
import { useTranslation } from "../../../i18n";
import { useCallback } from "react";
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export function useFilterOrRuleArrayValidationSchema() {
    const { t } = useTranslation("connectionFiltering");
    const getFilterOrRuleArrayValidationSchema = useCallback((metrics) => {
        return Yup.array()
            .of(Yup.object()
            .shape({
            id: Yup.string().required(),
            _and: getFilterRuleArrayValidationSchema(metrics, t),
        })
            .required())
            .required();
    }, [t]);
    return getFilterOrRuleArrayValidationSchema;
}
