import { makeStyles } from "@material-ui/core";
import { ReactNode } from "react";

interface Props {
  title: string;
  description: string;
  rightSideHeadContent?: ReactNode;
  children: ReactNode;
  "data-testid"?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.common.white,
    borderRadius: 8,
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    marginBottom: 100,
    maxWidth: 1280,
  },
  head: {
    padding: 24,
  },
  content: {
    padding: 24,
    borderRadius: 6,
    background: theme.palette.grey[100],
    border: "1px solid",
    borderColor: theme.palette.grey[300],
    margin: "-1px",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
  },
  description: {
    maxWidth: 340,
    fontSize: 13,
    fontWeight: 400,
    color: theme.palette.grey[700],
  },
}));

export function SubscriptionViewContent(props: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container} data-testid={props["data-testid"]}>
      <div className={classes.head}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <div className={classes.title}>{props.title}</div>
            <div className={classes.description}>{props.description}</div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            {props.rightSideHeadContent || null}
          </div>
        </div>
      </div>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
}
