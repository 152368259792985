import { Button, ClipboardText, useTranslation } from "@lumar/shared";
import { Box, Link, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { ApiCallback } from "./ApiCallback";
import { ApiKeys } from "./ApiKeys";
import { ApiKeySecretDialog } from "./ApiKeySecretDialog";
import { CreateApiKeyDialog } from "./CreateApiKeyDialog";
import { DeleteApiKeyConfirmDialog } from "./DeleteApiKeyConfirmDialog";
import { useBrand } from "@lumar/shared";
import { Redirect } from "react-router";
import { Routes } from "../_common/routing/routes";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 818,
    marginTop: -theme.spacing(1),
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  apiLink: {
    textDecoration: "none",
  },
  apiLinkLabel: {
    lineHeight: "15px",
  },
  apiLinkIcon: {
    fontSize: 14,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(0.5),
  },
  divider: { marginTop: theme.spacing(1), marginBottom: theme.spacing(3) },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: theme.spacing(4),
    borderRadius: 6,
    borderColor: theme.palette.grey[300],
    borderWidth: 0.5,
    borderStyle: "solid",
    overflowX: "auto",
  },
  spacer: {
    height: theme.spacing(3),
  },
}));

export const ApiAccess = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation("apiAccess");

  const [createKeyOpened, setCreateKeyOpened] = useState<boolean>(false);
  const [apiSecret, setApiSecret] = useState<string | undefined>();
  const [userKeyToDelete, setUserKeyToDelete] = useState<string | undefined>();

  const onApiKeyCreated = (apiSecret?: string): void => {
    setApiSecret(apiSecret);
  };

  const brand = useBrand();

  if (!brand.featureAvailability.apiAccessPage) {
    return <Redirect to={Routes.Home.ROUTE} />;
  }

  return (
    <>
      <TopNavigation title={t("topNavigationTitle")} />
      <Box className={classes.container}>
        <Box className={classes.titleWrapper}>
          <Link
            href="https://graph-docs.deepcrawl.com/"
            rel="noreferrer"
            target="_blank"
          >
            <Button variant="contained" color="secondary">
              <ClipboardText className={classes.apiLinkIcon} />
              {t("docsTitle")}
            </Button>
          </Link>
        </Box>
        <Box className={classes.contentBox}>
          <ApiKeys
            onCreateKey={() => setCreateKeyOpened(true)}
            onDeleteKey={(userKeyId) => setUserKeyToDelete(userKeyId)}
          />
        </Box>
        <Box className={classes.spacer} />
        <Box className={classes.contentBox}>
          <ApiCallback />
        </Box>
      </Box>
      <CreateApiKeyDialog
        open={createKeyOpened}
        onApiKeyCreated={onApiKeyCreated}
        onClose={() => setCreateKeyOpened(false)}
      />
      <DeleteApiKeyConfirmDialog
        userKeyId={userKeyToDelete}
        onClose={() => setUserKeyToDelete(undefined)}
      />
      <ApiKeySecretDialog
        onClose={() => setApiSecret(undefined)}
        apiSecret={apiSecret}
      />
    </>
  );
};
