import { Snackbar, ApolloCache } from "@lumar/shared";
import { TFunction } from "i18next";
import { SnackbarProvider } from "notistack";
import {
  AddAdobeConnectionMutation,
  DeleteAdobeConnectionMutation,
  GetConnectedAppsDocument,
  GetConnectedAppsQuery,
  RemoveAdobeConnectionMutation,
  UpdateAdobeConnectionMutation,
  useAddAdobeConnectionMutation,
  useDeleteAdobeConnectionMutation,
  useRemoveAdobeConnectionMutation,
  useUpdateAdobeConnectionMutation,
} from "../../graphql";
import { AdobeAccount, AdobeJwtAccount, ConnectedApps } from "./types";

type AdobeAccounts = Pick<
  ConnectedApps,
  | "adobeAccounts"
  | "addAdobeAccount"
  | "updateAdobeAccount"
  | "removeAdobeAccount"
  | "deleteAdobeAccount"
>;

export function useAdobeAccounts(
  data: GetConnectedAppsQuery | undefined,
  enqueueSnackbar: SnackbarProvider["enqueueSnackbar"],
  t: TFunction<"connectedApps">,
): AdobeAccounts {
  const [addAdobeConnection] = useAddAdobeConnectionMutation({
    onError: () =>
      enqueueSnackbar(<Snackbar variant="error" title={t("adobe.errorAdd")} />),
  });

  const [updateAdobeConnection] = useUpdateAdobeConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("adobe.errorUpdate")} />,
      ),
  });

  // FIXME: Remove after deprecated adobe connections are removed from the api.
  const [removeAdobeConnection] = useRemoveAdobeConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("adobe.errorRemove")} />,
      ),
  });

  const [deleteAdobeConnection] = useDeleteAdobeConnectionMutation({
    onError: () =>
      enqueueSnackbar(
        <Snackbar variant="error" title={t("adobe.errorRemove")} />,
      ),
  });

  async function addAdobeAccount(
    clientId: string,
    clientSecret: string,
    orgId: string,
    privateKey: string,
    technicalAccountId: string,
  ): Promise<boolean> {
    const response = await addAdobeConnection({
      variables: {
        clientId,
        clientSecret,
        orgId,
        privateKey,
        technicalAccountId,
      },
      update: (cache, { data }) => addAdobeConnectionToCache(cache, data),
    });

    return Boolean(
      response.data?.createAdobeJwtConnection.adobeJwtConnection.id,
    );
  }

  async function updateAdobeAccount(
    adobeConnectionId: string,
    clientId: string,
    clientSecret: string,
    orgId: string,
    privateKey: string,
    technicalAccountId: string,
  ): Promise<boolean> {
    const response = await updateAdobeConnection({
      variables: {
        adobeConnectionId,
        clientId,
        clientSecret,
        orgId,
        privateKey,
        technicalAccountId,
      },
      update: (cache, { data }) => updateAdobeConnectionInCache(cache, data),
    });

    return Boolean(
      response.data?.updateAdobeJwtConnection.adobeJwtConnection.id,
    );
  }

  // FIXME: Remove after deprecated adobe connections are removed from the api.
  async function removeAdobeAccount(
    adobeConnectionId: string,
  ): Promise<boolean> {
    const response = await removeAdobeConnection({
      variables: {
        adobeConnectionId,
      },
      optimisticResponse: {
        deleteAdobeConnection: {
          adobeConnection: {
            id: adobeConnectionId,
          },
        },
      },
      update: (cache, { data }) => removeAdobeConnectionFromCache(cache, data),
    });

    return Boolean(response.data?.deleteAdobeConnection.adobeConnection.id);
  }

  async function deleteAdobeAccount(
    adobeConnectionId: string,
  ): Promise<boolean> {
    const response = await deleteAdobeConnection({
      variables: {
        adobeConnectionId,
      },
      optimisticResponse: {
        deleteAdobeJwtConnection: {
          adobeJwtConnection: {
            id: adobeConnectionId,
          },
        },
      },
      update: (cache, { data }) => deleteAdobeConnectionFromCache(cache, data),
    });

    return Boolean(
      response.data?.deleteAdobeJwtConnection.adobeJwtConnection.id,
    );
  }

  return {
    adobeAccounts: [
      ...getAdobeConnections(data),
      ...getAdobeJwtConnections(data),
    ],
    addAdobeAccount,
    updateAdobeAccount,
    removeAdobeAccount,
    deleteAdobeAccount,
  };
}

export function getAdobeConnections(
  data: GetConnectedAppsQuery | undefined,
): AdobeAccount[] {
  return (
    data?.me?.adobeConnections?.nodes.map((x) => ({
      type: "deprecated" as const,
      id: x.id,
      username: x.company ? `${x.username}:${x.company}` : x.username,
      secret: `*****${x.secretLastFragment}`,
      isValid: x.isWorking,
    })) ?? []
  );
}

export function getAdobeJwtConnections(
  data: GetConnectedAppsQuery | undefined,
): AdobeJwtAccount[] {
  return (
    data?.me?.adobeJwtConnections?.nodes.map((x) => ({
      type: "modern" as const,
      id: x.id,
      clientSecret: `*****${x.secretLastFragment}`,
      privateKey: `****`,
      clientId: x.clientId,
      orgId: x.orgId,
      technicalAccountId: x.technicalAccountId,
      isValid: x.isWorking,
    })) ?? []
  );
}

function addAdobeConnectionToCache(
  cache: ApolloCache<AddAdobeConnectionMutation>,
  data: AddAdobeConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        adobeJwtConnections: {
          ...cachedData.me.adobeJwtConnections,
          nodes: [
            ...cachedData.me.adobeJwtConnections.nodes,
            data?.createAdobeJwtConnection.adobeJwtConnection,
          ],
        },
      },
    },
  });
}

function updateAdobeConnectionInCache(
  cache: ApolloCache<UpdateAdobeConnectionMutation>,
  data: UpdateAdobeConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });

  if (!cachedData?.me) return;

  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        adobeJwtConnections: {
          ...cachedData.me.adobeJwtConnections,
          nodes: [
            ...cachedData.me.adobeJwtConnections.nodes.map((node) => {
              if (
                node.id !== data?.updateAdobeJwtConnection.adobeJwtConnection.id
              ) {
                return node;
              }
              return {
                ...node,
                ...data?.updateAdobeJwtConnection.adobeJwtConnection,
              };
            }),
          ],
        },
      },
    },
  });
}

// FIXME: Remove after deprecated adobe connections are removed from the api.
function removeAdobeConnectionFromCache(
  cache: ApolloCache<RemoveAdobeConnectionMutation>,
  data: RemoveAdobeConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  const removedId = data?.deleteAdobeConnection.adobeConnection.id;
  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        adobeConnections: {
          ...cachedData.me.adobeConnections,
          nodes: cachedData.me.adobeConnections.nodes.filter(
            (x) => x.id !== removedId,
          ),
        },
      },
    },
  });
}

function deleteAdobeConnectionFromCache(
  cache: ApolloCache<DeleteAdobeConnectionMutation>,
  data: DeleteAdobeConnectionMutation | null | undefined,
): void {
  const cachedData: GetConnectedAppsQuery | null = cache.readQuery({
    query: GetConnectedAppsDocument,
    variables: {},
  });
  if (!cachedData?.me) return;

  const removedId = data?.deleteAdobeJwtConnection.adobeJwtConnection.id;
  cache.writeQuery({
    query: GetConnectedAppsDocument,
    variables: {},
    data: {
      ...cachedData,
      me: {
        ...cachedData.me,
        adobeJwtConnections: {
          ...cachedData.me.adobeJwtConnections,
          nodes: cachedData.me.adobeJwtConnections.nodes.filter(
            (x) => x.id !== removedId,
          ),
        },
      },
    },
  });
}
